export enum ErrorType {
    error100 = 100,
    error401 = 401,
    error404 = 404,
    error500 = 500,
    error515 = 515,
}

export enum Skin {
    Unbranded = "Unbranded",
    Plum = "Plum",
    MKBS = "MKBS",
    MKPS = "MKPS",
    MKSF = "MKSF",
    MKSPF = "MKSPF",
    BHP = "BHP",
    Worsley = "Worsley",
    Nabgsf = "Nabgsf",
    Unauthorised = "Unauthorised"
}

export enum Gender {
    Male = "Male",
    Female = "Female"
}

export enum RiskProfileMixType {
    Cash = "Cash",
    Conservative = "Conservative",
    Moderate = "Moderate",
    Balanced = "Balanced",
    Growth = "Growth",
    High_Growth = "High_Growth",
    Custom = "Custom"
}

export enum RiskProfileCType {
    Cash = "Cash",
    Conservative = "Conservative",
    Moderate = "Moderate",
    Balanced = "Balanced",
    Growth = "Growth",
    High_Growth = "High Growth",
    Custom = "Custom"
}

export enum Volatility {
    VeryLowRisk = "VeryLowRisk",
    LowToMediumRisk = "LowToMediumRisk",
    MediumRisk = "MediumRisk",
    MediumToHighRisk = "MediumToHighRisk",
    HighRisk = "HighRisk",
    VeryHighRisk = "VeryHighRisk"
}

export enum IncomeType {
    Employment_Income = "Employment_Income",
    Taxable = "Taxable"
}

export enum IncomeOwnerType {
    Member = "Member",
    Partner = "Partner",
    Combined = "Combined"
}

export enum AssetType {
    Fixed_Interest_Domestic = "Fixed_Interest_Domestic",
    Fixed_Interest_International = "Fixed_Interest_International",
    Cash_Domestic = "Cash_Domestic",
    Cash_International = "Cash_International",
    Property_Domestic = "Property_Domestic",
    Property_International = "Property_Domestic",
    Equities_Domestic = "Equities_Domestic",
    Equities_International = "Equities_International",
    Alternative_Assets = "Alternative_Assets",
    Alternative_Defensive = "Alternative_Defensive"
}

export enum ScreenSize {
    xxxSmall = '320.98px',
    xxSmall = '375.98px',
	xSmall = '575.98px',
    pdfViewerChange = '600px',
	small = '767.98px',
	medium = '991.98px',
	large = '1200px',
}

export enum ScreenSizeWidth {
    small = 767.98,
    medium = 991.98
}

export enum ContactType {
    email = 'email',
    none = 'none'
}