import React from 'react';
import { TextLink } from '@mlc/web-ui-toolkit';
import { opensInNewWindow } from '../../../logic/functions/util';

const NewWindowLink = ({children, ...restProps}: any) => (
    <TextLink {...restProps} target="_blank" rel="noopener">
        {children}{opensInNewWindow}
    </TextLink>
);

export default NewWindowLink;