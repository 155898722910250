import { Card, Col, Grid, Heading, LoadingIndicator, Paragraph, Row } from "@mlc/web-ui-toolkit";
import React, { useContext, useEffect, useState } from "react";
import { AppThemeContext } from "../../../context/AppThemeContext";
import { Member } from "../../../logic/domains/memberData.domain";
import { formatCustomerName } from "../../../logic/functions/formatter";
import { logAction } from "../../../logic/functions/logging.function";
import { getSoa } from "../../../logic/functions/soa.function";
import { focusHeading } from "../../../logic/functions/util";
import { HeaderImgCol, KateCard, NavButtonGroup, StyledOptionsList } from "../Common/Common.style";
import DetailsText from "../Common/DetailsText";
import { BackButton, NextButton } from "../Common/NavButtons";
import PdfViewer from "../PdfViewer";
import { PdfViewerGrid } from "../PdfViewer/PdfViewer.style";
import { SoaHeadingCard, SoaImg } from "./SuperReport.style";
import superReportTestIds from "./SuperReport.elements";

type Props = {
    handleNextStep: () => void;
    handlePreviousStep: () => void;
    memberData?: Member;
    setIsNpsOpen: (isOpen: boolean) => void;
    shouldDisplayNps: boolean;
    setForceCloseNps: (forceCloseNps: boolean) => void;
}

//calculator step 7 of 8
const SuperReport = (props: Props) => {
    const content = useContext(AppThemeContext).staticContent.superReport;
    const [loading, setLoading] = useState(true);
    const [soaData, setSoaData] = useState<any>(null);
    useEffect(() => {
        const callSoa = async () => {
            window.scroll({ top: 0, left: 0 });
            let data = await getSoa(props.memberData?.number);
            setSoaData(data);
            setLoading(false);
            focusHeading();
            setTimeout(() => {
                if (props.shouldDisplayNps && sessionStorage.getItem("npsDone") == null) {
                    sessionStorage.setItem("npsDone", "true");
                    props.setIsNpsOpen(true);
                }
            }, 5000);
        }

        callSoa();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <LoadingIndicator isOpen={loading} message="Just a moment..." variant="fullscreen" />
            <KateCard>
                <Grid>
                    <Row>
                        <Col md={10} col={12}>
                            <Card marginTop="30px" marginBottom="30px">
                                <SoaHeadingCard>
                                    <DetailsText fontWeight="semibold" el="h2" fontSize="tiny">Great news {formatCustomerName(props.memberData!.firstName)}, your results are in!</DetailsText>
                                    <SoaImg className="mobile" alt="" src={content.adviceHeaderImg} />
                                </SoaHeadingCard>
                                <Paragraph>Your personalised super report is ready for you to review, it details all the information from the calculator including our advice and how you can implement our recommendations.</Paragraph>
                            </Card>
                        </Col>
                        <HeaderImgCol md={2} col={12} noGutter={true}>
                            <SoaImg alt="" src={content.adviceHeaderImg} />
                        </HeaderImgCol>
                    </Row>
                </Grid>
            </KateCard>
            <PdfViewerGrid>
                <Grid>
                    <Card display="flex" justifyContent="center" marginTop="40px" marginBottom="40px" width="100%">
                        <PdfViewer soaData={soaData} />
                    </Card>
                </Grid>
            </PdfViewerGrid>
            <KateCard paddingTop="20px" marginBottom="30px">
                <Grid>
                    <Row>
                        <Col>
                            <Heading el="h2" fontSize="little">Important information</Heading>
                            <StyledOptionsList options={content.adviceOptions} />
                        </Col>
                    </Row>
                </Grid>
            </KateCard>
            <Grid>
                <Row>
                    <Col>
                        <NavButtonGroup>
                            <NextButton
                                onClick={() => {
                                    logAction("NEXT_7");
                                    props.setForceCloseNps(true);
                                    props.setIsNpsOpen(false);
                                    props.handleNextStep();
                                }}
                                data-id={superReportTestIds.adviceTabNextTab}
                            >
                                Take action
                            </NextButton>
                            <BackButton
                                onClick={() => {
                                    logAction("BACK_7");
                                    props.setForceCloseNps(true);
                                    props.setIsNpsOpen(false);
                                    props.handlePreviousStep();
                                }}
                                data-id={superReportTestIds.adviceTabPreviousTab}
                            />
                        </NavButtonGroup>
                    </Col>
                </Row>
            </Grid>
        </>
    );
};

export default SuperReport;