enum regularContributionsTestIds {
    contributionsInfo = 'contributions-info',
    contributionsLabel = 'contributions-label',
    contributionsInput = 'contributions-input',
    contributionsFrequency = 'contributions-frequency',
    budgetPlanner = 'budget-planner',
    contributionsBreakdown = 'contributions-breakdown',
    taxableIncomeCurrent = 'taxable-income-current',
    taxableIncomeProposed = 'taxable-income-proposed',
    lessVoluntaryCurrent = 'less-voluntary-current',
    lessVoluntaryProposed = 'less-voluntary-proposed',
    lessTaxCurrent = 'less-tax-current',
    lessTaxProposed = 'less-tax-proposed',
    netIncomeCurrent = 'net-income-current',
    netIncomeProposed = 'net-income-proposed',
    lessNonConcessionalCurrent = 'less-non-concessional-current',
    lessNonConcessionalProposed = 'less-non-concessional-proposed',
    takeHomePayCurrent = 'take-home-pay-current',
    takeHomePayProposed = 'take-home-pay-proposed',
    employerContributionsCurrent = 'employer-contributions-current',
    employerContributionsProposed = 'employer-contributions-proposed',
    govCoContributionCurrent = 'gov-co-contribution-current',
    govCoContributionProposed = 'gov-co-contribution-proposed',
    lowIncomeSuperTaxOffsetCurrent = 'low-income-super-tax-offset-current',
    lowIncomeSuperTaxOffsetProposed = 'low-income-super-tax-offset-proposed',
    taxableIncome = 'taxable-income',
    lessVoluntary = 'less-voluntary',
    lessTax = 'less-tax',
    netIncome = 'net-income',
    lessNonConcessional = 'less-non-concessional',
    takeHomePay = 'take-home-pay',
    employerContributions = 'employer-contributions',
    govCoContribution = 'gov-co-contribution',
    lowIncomeSuperTaxOffset = 'low-income-super-tax-offset',
    currentHeader = 'current-header',
    proposedHeader = 'proposed-header',
    termDefinitionsBtn = 'term-definitions-btn',
    termDefintionsModal = 'term-definitions-modal',
    personalContributions = 'personal-contributions',
    salarySacrifice = 'salary-sacrifice',
    superCoContributions = 'super-co-contributions',
    superTypes = 'super-types',
    proposedContributionsWarning = 'proposed-contributions-warning',
    employerPremiumsWarning = 'employer-premiums-warning',
    reducedWarningTHP = 'reduced-warning-THP',
    reducedWarningNCC = 'reduced-warning-NCC',
    warningUpdateDetails = 'warning-update-details',
    currentContributionsInfo = 'current-contributions-info',
    proposedContributionsInfo = 'proposed-contributions-info',
    coffeeTooltip = 'coffee-tooltip',
    biggestBoostAccordion = 'biggest-boost-accordion',
    contributionsTableAccordion = 'contributions-table-accordion',
    contributionsInputFieldWrapper = 'contributions-input-field-wrapper'
}

export default regularContributionsTestIds;