import { post } from './fetch';
//don't make multiple log calls at the same time to avoid conflicts with the saved log object in the backend
//retry if blocked by another call up to 30 seconds, if call goes through end retry loop no matter response (1 retry made upon failure)
//easier to do this for now than figuring out backend configuration since every other api call blocks the front end through loading
export const logAction = async (type: string, bypass: boolean = false) => {
    if (bypass) {
        try {
            await post(`/members/log/${type}`, "", { noErrorRedirect: true, keepAlive: true });
        } catch (e) {
            // doesn't break anything if this fails so don't need to do anything
            try {
                await post(`/members/log/${type}`, "", { noErrorRedirect: true, keepAlive: true });
            } catch (e2) { }
        }
    } else {
        let complete = false;
        let retries = 0;
        while (!complete && retries < 60) {
            if (!(window as any).logInProgress) {
                try {
                    (window as any).logInProgress = true;
                    await post(`/members/log/${type}`, "", { noErrorRedirect: true, keepAlive: true });
                } catch (e) {
                    // doesn't break anything if this fails so don't need to do anything
                    try {
                        await post(`/members/log/${type}`, "", { noErrorRedirect: true, keepAlive: true });
                    } catch (e2) { }
                } finally {
                    complete = true;
                }
            } else {
                await new Promise(resolve => setTimeout(resolve, 500));
                retries++;
            }
        }
        setTimeout(() => (window as any).logInProgress = false, 500);
    }
}