import { get } from './fetch';
import { TargetSetItem, TargetSetResponse } from '../domains/targetSet.domain';
import { errorInResponse, redirectToError } from './util';

export const getTargetSets = async (): Promise<TargetSetItem[]> => {
    const data = await get(`/members/targetSet`) as Promise<TargetSetResponse>;
    if (errorInResponse(await data)) {
        redirectToError(500);
    }
    return (await data).response as TargetSetItem[];
}