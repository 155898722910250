import React from 'react';
import { Grid, Card } from '@mlc/web-ui-toolkit';

type Props = {
    children: any;
    containInGrid?: boolean;
}

const Tab = ({ children, containInGrid = true }: Props) => {
    const El = containInGrid ? Grid : Card

    return (
        <El style={{width: "100%"}}>
            {children}
        </El>
    );
};

export default Tab;