import { Helmet } from 'react-helmet';
import React from 'react';

// analytics
export const DigitalDataHelmet = ({isMlc}: any) => {
    const isProd = window.location.hostname === 'advice.plum.com.au';
    let siteData;
    let ensightenScript;
    if (isMlc) {
        ensightenScript = isProd ? '//nexus.ensighten.com/mlc/secure-prod/Bootstrap.js' : '//nexus.ensighten.com/mlc/secure-dev/Bootstrap.js';
        siteData = {
            pageName: 'mlcsecure:en:investor:onlineforms:personal-super-calculator',
            site: 'mlcsecure',
            language: 'en',
            siteSection: 'investor',
            siteSubSection: 'onlineforms',
            siteSubSectionChild: 'personal-super-calculator',
        }
    } else {
        ensightenScript = isProd ? '//nexus.ensighten.com/mlc/plum-prod/Bootstrap.js' : '//nexus.ensighten.com/mlc/plum-dev/Bootstrap.js';
        siteData = {
            pageName: 'plumsecure:advice:personal-super-calculator',
            site: 'plumsecure',
            language: 'en',
            siteSection: 'advice',
            siteSubSection: 'personal-super-calculator',
        }
    }

    // ensighten data layer object
    const digitalData = {
        page: {
            pageInfo: {
                ...siteData,
                siteType: "responsive",
                server: window.location.hostname,
                referringUrl: document.referrer,
                hash: window.location.hash,
                sysEnv: window.navigator.userAgent,
                customValues: {
                    isFormPage: "true",
                    formName: "Personal Super Calculator"
                }
            }
        },
        components: {},
        event: []
    };

    return (
        <Helmet>
            <script type="text/javascript">
                {`var digitalData = ${JSON.stringify(
                    digitalData,
                    null,
                    '\t'
                )};`}
            </script>
            <script type="text/javascript" src={ensightenScript}/>
        </Helmet>
    );
};