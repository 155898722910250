import { Card, LoadingIndicator, Row, ScreenReaderText, Text } from '@mlc/web-ui-toolkit';
import classnames from 'classnames';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppThemeContext } from '../../../../../context/AppThemeContext';
import { Calculator } from '../../../../../logic/domains/calculator.domain';
import { asAssetAllocation, checkNaN } from '../../../../../logic/functions/formatter';
import { logAction } from '../../../../../logic/functions/logging.function';
import { getRiskProfile } from '../../../../../logic/functions/riskProfile.function';
import { focusFirstError, getProfileTypeName, isModalVideoOpen } from '../../../../../logic/functions/util';
import { commonClasses } from '../../../Common/Common.elements';
import PageIndicator from '../../../Common/PageIndicator';
import { Image } from '../../../Image';
import { CalculatedRiskProfile, RiskProfileButton, RiskProfileButtonsGroup, RiskProfileExtraContentCard, RiskProfileExtraInfo, RiskProfileExtraTitle, RiskProfileExtraTitleCard, RiskProfileInfo, RiskProfileIntro, RiskProfileModal, RiskProfileQuestion, RiskProfileResponseContent, RiskProfileResponseHeading, RiskProfileResult, RiskProfileResultHeader } from '../CalculatorSteps.style';
import * as constants from './StaticContent';
import riskProfileTestIds from './RiskProfile.elements';
import numeral from 'numeral';
import InfoPopup from '../../../Common/InfoPopup';

type QuestionPageProps = {
    config: any;
    formikProps: any;
    pageTouched: boolean;
    useDefault: boolean;
    stopUsingDefault: () => void;
    images: any;
}

// defaults all answers to null for initial and redos of the questionnaire but maintains answers in current questionnaire session
const QuestionPage = ({ config, formikProps, pageTouched, useDefault, stopUsingDefault, images }: QuestionPageProps) => {
    const { values, setFieldValue } = formikProps;
    // only check for null answer after clicking continue
    const error = pageTouched && useDefault;
    // split out so we can force the tooltip to stick to the last word while still remaining within the parent
    let splitQuestion = config.question.split(" ");
    let lastWord = splitQuestion.pop();
    splitQuestion = splitQuestion.join(" ");
    return (
        <Card width="100%">
            <RiskProfileQuestion key={config.id} label={
                <Card>
                    <Text fontWeight="semibold" id={`question-${config.id}`}>
                        {splitQuestion}{' '} 
                        <Text className="last-word">{lastWord}<InfoPopup content={config.tooltipContent} log={config.tooltipLog} absPos={false} /></Text>
                    </Text>
                </Card>
            } id={config.id} error="Please choose an option." isInvalid={error}>
                <RiskProfileButtonsGroup
                    name={config.formField}
                    value={useDefault ? config.defaultValue : values[config.formField].toString()}
                    data-id={config.id}
                    tabIndex={0}
                    className={classnames(error ? commonClasses.validationError : '')}
                    aria-describedby={`error-${config.id}`}
                    aria-invalid={error}
                    aria-labelledby={`question-${config.id}`}
                >
                    {_.map(config.values, ((value, i) => (
                        <RiskProfileButton
                            value={value.value}
                            key={i}
                            className={classnames(!useDefault && value.value === values[config.formField].toString() ? "selectedRPQuestion" : "")}
                            onClick={(e: any) => {
                                stopUsingDefault();
                                setFieldValue(config.formField, parseInt(e.target.value));
                            }}
                            role="radio"
                            aria-invalid={error}
                            aria-describedby={`error-${config.id}`}
                        >   <img alt="" src={images[i]} className={`rp-question-${(config.formField).toString()}`} />
                            {value.label}
                        </RiskProfileButton>
                    )))}
                </RiskProfileButtonsGroup>
            </RiskProfileQuestion>
        </Card>
    );
};

type Props = {
    isOpen: any;
    setOpenFunction: any;
    formikProps: any;
    setUpdatingMember: () => any;
}

const RiskProfileQuestionnaire = (props: Props) => {
    const { isOpen, setOpenFunction, formikProps, setUpdatingMember } = props;
    const values: Calculator = formikProps.values;
    const [useDefault, setUseDefault] = useState([true, true, true, true, true]);
    const [pageNo, setPageNo] = useState(0);
    const [loading, setLoading] = useState(false);
    const [profileType, setProfileType] = useState<any>();
    const [pageTouched, setPageTouched] = useState([false, false, false, false, false]);
    const skinContent: any = useContext(AppThemeContext).staticContent.riskProfile;

    const touchPage = (touchedPage: number) => {
        const newPageTouched = _.cloneDeep(pageTouched);
        newPageTouched[touchedPage] = true;
        setPageTouched(newPageTouched);
    }

    //Sets question 4 content dynamically
    useEffect(() => {
        constants.pages[3].question = constants.superLoss(values.currentSuperBalance, values.firstName);
    }, [values.currentSuperBalance, values.firstName]);

    useEffect(() => {
        // only sets calculator form values after finishing the modal otherwise the values are only local to the modal
        const getProfile = async () => {
            formikProps.setRpLoading(true);
            const questionnaireValues = [];
            const pages = constants.pages;
            for (let i = 0; i < pages.length; i++) {
                questionnaireValues.push((values as any)[pages[i].formField]);
            }

            const data = await getRiskProfile(questionnaireValues);
            setProfileType(data.type);
            formikProps.setValues({
                ...values,
                maintainInvestments: false,
                selectedProfileType: {
                    ...values.selectedProfileType,
                    type: data.type
                },
                suggestedProfileType: data.type
            })
            setUpdatingMember();
        }
        if (pageNo === 5) {
            getProfile();
        }

        const header = document.querySelector(`[data-id=${riskProfileTestIds.questionnaireModal}]`)?.querySelector('h1');
        if (header) {
            setTimeout(() => {
                header.focus();
            }, 100);
        }

        if(pageNo === 5 && header){
            header.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNo]);

    useEffect(() => {
        setLoading(formikProps.rpLoading);
    }, [formikProps.rpLoading]);

    let page = pageNo + 1;

    const backBtn = {
        content: (
            <>
                Back
            </>
        ),
        onClick: () => setPageNo(pageNo - 1),
        buttonType: "secondary",
    }

    const nextBtn = {
        content: (
            <Text data-id={riskProfileTestIds.RPNextButton}>
                Next
            </Text>
        ),
        onClick: () => {
            touchPage(pageNo);
            if (!useDefault[pageNo]) {
                setPageNo(pageNo + 1);
            } else {
                focusFirstError();
            }
        },
        buttonType: "primary",
    }

    const questionnaireButtons = [
        [nextBtn,
            {
                content: "Cancel",
                onClick: () => {
                    setOpenFunction(false);
                    setPageNo(0);
                },
                buttonType: "tertiary"
            }
        ],
        [nextBtn, backBtn],
        [nextBtn, backBtn],
        [nextBtn, backBtn],
        [
            {
                ...nextBtn,
                content: (
                    <Text data-id={riskProfileTestIds.getRPButton}>
                        Find out your risk profile
                    </Text>
                )
            },
            backBtn],
        [
            {
                content: !loading && profileType ? nextBtn.content : "Loading...",
                onClick: () => {
                    logAction("RP_COMPARE");
                    setOpenFunction(false);
                    setPageTouched([false, false, false, false, false]);
                    setUseDefault([true, true, true, true, true]);
                    setPageNo(0);
                },
                buttonType: "primary",
                isLoading: loading
            },
            backBtn,
        ]
    ];

    let content;
    if (pageNo < 5) {
        content = (
            <QuestionPage
                config={constants.pages[pageNo]}
                formikProps={formikProps}
                pageTouched={pageTouched[pageNo]}
                useDefault={useDefault[pageNo]}
                stopUsingDefault={() => {
                    let newDefault = _.cloneDeep(useDefault);
                    newDefault[pageNo] = false;
                    setUseDefault(newDefault);
                }}
                images={skinContent.questionImages[pageNo]}
            />
        )
    }

    if (pageNo === 5) {
        content = (
            <Card width="100%">
                <Card textAlign="left" marginBottom={4}>
                    <Row>
                        <RiskProfileResultHeader fontSize="tiny" fontWeight="semibold">
                            {values!.firstName}, you're a
                        </RiskProfileResultHeader>
                    </Row>
                    <CalculatedRiskProfile
                        fontSize="tiny"
                        fontWeight="semibold"
                        data-id={riskProfileTestIds.questionnaireRiskProfile}
                    >
                        {!loading && profileType ? `${getProfileTypeName(profileType)} investor` : <LoadingIndicator variant="scaled" className="rp-loading" />}
                    </CalculatedRiskProfile>
                    {!loading && profileType && (
                        <Row>
                            <RiskProfileResultHeader>
                                {constants.riskProfileDescription[profileType].description}
                            </RiskProfileResultHeader>
                            <RiskProfileResult>
                                <RiskProfileInfo>
                                    <RiskProfileResponseHeading el="h2">Risk tolerance</RiskProfileResponseHeading>
                                    <RiskProfileResponseContent data-id={riskProfileTestIds.questionnaireRiskTolerance}>{(constants.riskProfileVolatilityValues as any)[values.selectedProfileType.volatility!].tolerance}</RiskProfileResponseContent>
                                </RiskProfileInfo>
                                <RiskProfileInfo>
                                    <RiskProfileResponseHeading el="h2">Minimum investment timeframe</RiskProfileResponseHeading>
                                    <RiskProfileResponseContent data-id={riskProfileTestIds.questionnaireMinimumTime}>{constants.riskProfileDescription[profileType].investmentTimeframe}</RiskProfileResponseContent>
                                </RiskProfileInfo>
                                <RiskProfileInfo>
                                    <RiskProfileResponseHeading el="h2">Expected annual return</RiskProfileResponseHeading>
                                    <RiskProfileResponseContent data-id={riskProfileTestIds.questionnaireAssumedAnnualReturn}>{checkNaN(numeral(values.selectedProfileType.expectedReturn).format('0.00')) ? '0.00' : numeral(values.selectedProfileType.expectedReturn).format('0.00')}%</RiskProfileResponseContent>
                                </RiskProfileInfo>
                                <RiskProfileInfo>
                                    <RiskProfileResponseHeading el="h2">Asset allocation</RiskProfileResponseHeading>
                                    <RiskProfileResponseContent data-id={riskProfileTestIds.questionnaireAllocationGrowth}>{asAssetAllocation(values.selectedProfileType.split.growth)}% Growth</RiskProfileResponseContent>
                                    <RiskProfileResponseContent data-id={riskProfileTestIds.questionnaireAllocationDefensive}>{asAssetAllocation(values.selectedProfileType.split.defensive)}% Defensive</RiskProfileResponseContent>
                                </RiskProfileInfo>
                            </RiskProfileResult>
                        </Row>
                    )}
                </Card>
            </Card>
        )
    }

    return (
        <RiskProfileModal
            isOpen={isOpen}
            align="left"
            title={<>Risk Profiler<ScreenReaderText>, step {page} of 6</ScreenReaderText></>}
            aria={{ label: `Risk Profiler, step ${page} of 6` }}
            onRequestClose={() => {
                if (!isModalVideoOpen()) {
                    if (page === 6) {
                        setPageTouched([false, false, false, false, false]);
                        setUseDefault([true, true, true, true, true]);
                    }
                    setOpenFunction(false);
                    setPageNo(0);
                }
            }}
            footerButtons={questionnaireButtons[pageNo]}
            data-id={riskProfileTestIds.questionnaireModal}
            shouldCloseOnOverlayClick={false}
        >
            {page === 1 &&
                <Row>
                    <RiskProfileIntro>{values!.firstName}, answer these 5 questions to find out what your risk profile is and we'll tell you which investment option could suit you.</RiskProfileIntro><br />
                </Row>
            }
            {page < 6 &&
                <Card display="flex" justifyContent="flex-end" marginBottom="10px">
                    <PageIndicator pageType="Question" currentPage={page} length={5} />
                </Card>
            }
            <Row>
                {content}
            </Row>
            {skinContent.questionExtraContent[pageNo] !== null && (
                <Row>
                    <RiskProfileExtraInfo>
                        <RiskProfileExtraTitleCard>
                            <Image alt="" src={skinContent.infoImg} htmlWidth="40px" />
                            <RiskProfileExtraTitle el="h2" visual="h5">{skinContent.questionExtraContent[pageNo]!.title}</RiskProfileExtraTitle>
                        </RiskProfileExtraTitleCard>
                        <RiskProfileExtraContentCard>{skinContent.questionExtraContent[pageNo]!.content}</RiskProfileExtraContentCard>
                    </RiskProfileExtraInfo>
                </Row>
            )}
        </RiskProfileModal>
    )
}

export default RiskProfileQuestionnaire;
