enum welcomeTabTestIds {
    importantInfoHeader = 'important-info-header',
    getStartedAssumptionsLink = 'get-started-assumptions-link',
    getStartedTermsLink = 'get-started-terms-link',
    getStartedFinancialLink = 'get-started-financial-link',
    getStartedPrivacyLink = 'get-started-privacy-link',
    getStartedStart = 'get-started-start',
    getStartedTermsCheckbox = 'get-started-terms-checkbox',
    getStartedFinancialCheckbox = 'get-started-financial-checkbox',
    noAdviceOnAccordion = 'no-advice-on-accordion',
    noAdviceOnContent = 'no-advice-on-content',
    tAndCAccordion = 't-and-c-accordion',
    assumptionsAccordion = 'assumptions-accordion',
    rightForYouAccordion = 'right-for-you-accordion',
    assumptionsContent = 'assumptions-content',
    tAndCContent = 't-and-c-content',
    rightForYouContent = 'right-for-you-content',
    accordionAssumptionsLink = 'accordion-assumptions-link',
    accordionFinancialLink = 'accordion-financial-link',
    accordionTermsLink = 'accordion-terms-link'
}

export default welcomeTabTestIds;