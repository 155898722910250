import { get, post } from './fetch';
import { SoaResponse } from '../domains/soa.domain';
import { errorInResponse, redirectToError } from './util';
import _ from 'lodash';

//get SOA data from /download here so we can use in both PDF viewer and SOA download button without duplicating calls
export const getSoa = async (memberNumber?: any): Promise<any> => {
    const numberParam = memberNumber ? `?number=${memberNumber}` : ''
    const data = await get(`/members/soa/ready${numberParam}`) as Promise<SoaResponse>;
    const response = await data;
    if (_.isEmpty(response) || (response && response.response === false)) {
        redirectToError(100);
    }
    if (response && errorInResponse(response)) {
        if (response.errorCode === 100) {
            redirectToError(100);
        } else {
            redirectToError(500);
        }
    }

    return await get(`/members/soa/download`, { isBlob: true });
}

export const generateSoa = async (memberNumber?: any) => {
    const numberParam = memberNumber ? `?number=${memberNumber}` : ''
    const data = await post(`/members/soa/request${numberParam}`, "") as Promise<SoaResponse>;
    const response = await data;
    if (response && errorInResponse(response)) {
        if (response.errorCode === 100) {
            redirectToError(100);
        } else {
            redirectToError(500);
        }
    }
}

export const downloadSoa = async (report: any) => {
    try {
		const name = report.filename;
		// @ts-ignore
		if (window.navigator.msSaveOrOpenBlob) { //only really for IE
            // @ts-ignore
			window.navigator.msSaveBlob(report.content, name);
		} else { // for all other browsers
			const a = window.document.createElement('a');
			// @ts-ignore
			a.href = window.URL.createObjectURL(report.content);
			a.download = name;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	} catch (e) {
        // don't interrupt ux with error just because download failed
	}
}