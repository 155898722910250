import { Container, Modal } from '@mlc/web-ui-toolkit';
import React, { useEffect, useState } from 'react';
import './App.scss';
import ThemeOverrider from './components/containers/ThemeOverrider/ThemeOverrider';
import CalculatorUnavailable from './components/presentational/CalculatorUnavailable';
import { toolDate } from './components/presentational/Common/StaticContent';
import Unauthorised from './components/presentational/Unauthorised';
import { AppThemeContext, csrfToken, mksfTheme, mlcTheme, plumTheme } from './context/AppThemeContext';
import ErrorBoundary from './context/ErrorBoundary';
import { DigitalDataHelmet } from './DigitalDataHelmet';
import { ScreenSizeWidth, Skin } from './enums';
import { authorise } from './logic/functions/authorise.function';
import { SKIN } from './logic/functions/fetch';
import { gaInit, gaPageView, getPageName } from './logic/functions/gaTracking';
import { logAction } from './logic/functions/logging.function';
import { getMemberSkin } from './logic/functions/memberSkin.function';

const App: React.FC = () => {
	Modal.setAppElement('#root');
	const [isLoading, setIsLoading] = useState(true);
	const [theme, setTheme] = useState<any>();
	const [error, setError] = useState(false);
	const [unauthorised, setUnauthorised] = useState(false);

	useEffect(() => {
		async function fetchSkin() {
			let skin;
			let favicon = document.getElementById('favicon');
			// get CSRF token for API calls
			let xsrf = await authorise();
			if (!xsrf) {
				favicon?.setAttribute('href', process.env.PUBLIC_URL + '/');
				setError(true);
			} else {
				if (!Object.isFrozen(csrfToken)) {
					csrfToken.value = xsrf;
					Object.freeze(csrfToken);
				}

				// store skin so that API skin calls do not have to be made multiple times for different pages or refreshes
				// skin call will always be made on root url "/" to initiate a new backend session if required
				if (window.location.pathname !== '/' && sessionStorage.getItem(SKIN)) {
					skin = sessionStorage.getItem(SKIN);
				} else {
					skin = await getMemberSkin();
					if (skin) sessionStorage.setItem(SKIN, skin);
					sessionStorage.removeItem("npsDone");
				}
				if (!skin) {
					favicon?.setAttribute('href', process.env.PUBLIC_URL + '/');
					setError(true);
				} else {
					if (skin === Skin.Unauthorised) {
						setUnauthorised(true);
					} else if (skin.toLowerCase() === Skin.MKBS.toLowerCase() || skin.toLowerCase() === Skin.MKPS.toLowerCase()) {
						favicon?.setAttribute('href', process.env.PUBLIC_URL + '/mlc_favicon.ico');
						setTheme(mlcTheme);
					} else if (skin.toLowerCase() === Skin.MKSF.toLowerCase() || skin.toLowerCase() === Skin.MKSPF.toLowerCase()) {
						favicon?.setAttribute('href', process.env.PUBLIC_URL + '/mlc_favicon.ico');
						setTheme(mksfTheme);
					} else if (skin.toLowerCase() === Skin.Unbranded.toLowerCase()) {
						favicon?.setAttribute('href', process.env.PUBLIC_URL + '/plum_favicon.ico');
						setTheme(plumTheme);
					}
				}
			}
			gaInit();
			gaPageView(getPageName());
			// mobile or desktop reporting
			if (window.screen.width > ScreenSizeWidth.medium) {
				logAction('DESKTOP');
			}
			setIsLoading(false);
		}
		//clear in case of refresh while it was set, see logging.function for purpose
		(window as any).logInProgress = false;
		fetchSkin();
	}, []);

	if (isLoading) return null;
	if (unauthorised) return <Unauthorised />
	if (error) return <CalculatorUnavailable />;
	
	logAction(`TOOL_DATE_REPORTING_${toolDate.replace(/ /g, "-")}`);

	return (
		<ErrorBoundary>
			<DigitalDataHelmet isMlc={theme.isMlc} />
			<AppThemeContext.Provider value={theme}>
				<Container themeName={theme.isMlc ? "mlc" : "plum"} data-id="app-container">
					<ThemeOverrider />
				</Container>
			</AppThemeContext.Provider>
		</ErrorBoundary>
	);
};

export default App;
