import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';

import { library as fontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core';
import { fas as fontAwesomeSolidFree } from '@fortawesome/free-solid-svg-icons';
import { fas as fontAwesomeSolidPro } from '@fortawesome/pro-solid-svg-icons';

import App from './App';
import * as serviceWorker from './serviceWorker';
import '@mlc/charlie-web-fonts';
import 'semantic-ui-css/semantic.min.css';
import { createBrowserHistory } from 'history';
//@ts-ignore
import jQuery from 'jquery';


export const history = createBrowserHistory();

fontAwesomeLibrary.add(fontAwesomeSolidFree);
fontAwesomeLibrary.add(fontAwesomeSolidPro);

//@ts-ignore
window.$ = jQuery;
//@ts-ignore
window.jQuery = jQuery;

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
