import React from 'react';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import * as palette from '@mlc/mlc-palette';
import { Text } from '@mlc/web-ui-toolkit';
import { ScreenSize } from '../../../enums';

export const CalculatorHeaderStyle = styled.header`
	* {
		box-sizing: border-box;
	}
	position: relative;

	.Header__logo {
		overflow: hidden;
		@media (min-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
			display: flex;
		}
	}
	.Header__mobile-logo {
		display: flex;
		align-items: center;
	}
	.Header__mobile-icon {
		margin-left: 70px;
	}
	.Header__panel {
		justify-content: space-between;
		align-items: center;
		display: flex;

		& + .Header__panel {
			@media (min-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
				margin-top: 10px;
			}
		}

		@media (min-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
			
		}
	}
	.Header__mobile-header {
		@media (max-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
	.Header__mobile-nav {
		@media (max-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
			
			height: 100%;

			.Header__mobile-header.is-close {
				display: flex;
			}
		}
	}
	&.is-open {
		.Header__mobile-nav {
			visibility: visible;
			right: 0;
			height: 100%;
			transition: right ease-in-out 0.4s;
		}
	}
	.Header__mobile-header-wrapper {
		padding: 0 10px;
	}
	.Header__text-banner {
		@media (max-width: ${ScreenSize.xSmall}) {
			padding-left: 10px;
			padding-right: 10px;

			h1 {
				font-size: 26px;
			}
		}
		background-color: ${themeGet('colors.brandPrimaryLight')};
	}
	.theme-mlc {
		background-color: ${palette.mlcWhite};

		.Header__logo {
			margin-top: 10px;
			margin-bottom: 10px;

			@media (min-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
				margin-top: 20px;
				margin-bottom: 20px;
			}
		}
	}
	.theme-plum {
		background-color: ${themeGet('colors.brandPrimaryLight')};

		.Header__logo {
			margin-bottom: 15px;

			@media (min-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
				margin-bottom: 25px;
			}
		}
	}
`;

export const HeaderButton = styled.button`
	font-family: ${themeGet('fonts.fontFamily')};
	font-weight: ${themeGet('fontWeights.regular')};
	font-size: ${themeGet('fontSizes.default')}px;
	text-decoration: none;
	position: relative;
	color: ${themeGet('colors.mlcText')};
	text-align: center;
	display: inline-flex;
	align-items: center;
	padding: 10px 0 10px 10px;
	border: 0;
	width: auto;
	margin: 5px 0;
	cursor: pointer;
	background-color: ${themeGet('colors.secondaryBackground')};
	&:focus,
	&:hover {
		text-decoration: underline;
		outline: 1px solid transparent;
	}
	@media (min-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
		font-weight: ${themeGet('fontWeights.medium')};
	}
`;

export const HeaderLink = styled.a`
	color: ${themeGet('colors.mlcText')};
	text-decoration: none;
	margin-right: 10px;
	&:hover,
	&:focus {
		text-decoration: underline;
	}
`;

export const HeaderText = ({ ...props }) => (
	<Text color="mlcText" fontSize="disclaimer" {...props} />
);
