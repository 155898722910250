import * as palette from '@mlc/mlc-palette';
import { Card, Modal, SecondaryButton, Svg, Text, TableRow, ToggleButtonGroup, Col, PrimaryButton } from '@mlc/web-ui-toolkit';
import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';
import { ScreenSize } from '../../../enums';
import OptionsList from './OptionsList';

export const InfoButton = styled.button`
    background-color: transparent !important;
    cursor: pointer;
    color: ${themeGet('colors.brandPrimaryLight')};
    border: 1px solid transparent;

    &:hover, &:focus {
        color: ${themeGet('colors.tooltipHover')};
    }
`;

export const InfoPopupCloseButton = styled.button`
    background-color: transparent;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 0;
`;

export const PageIndicatorContainer = styled(Card)`
    ul {
        padding: 0;
        margin: 0;
    }

    li {
        display: inline-block;
        width: 20px;
        height: 12.5px;
        border-radius: 10px;
        border: 3px solid ${themeGet('colors.brandPrimaryLight')};
        margin-right: 10px;
    }

    li.current {
        background-color: ${themeGet('colors.brandPrimaryLight')};
    }

    li:last-child {
        margin-right: 0px;
    }
`
export const ValidationError = styled.div`
    color: ${palette.mlcError};
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 14px;
`;

export const HelpText = styled(Text)`
    line-height: 21px;
    font-size: 14px;
`;

export const HelpCard = styled(Card)`
    @media (max-width: ${ScreenSize.small}) {
        width: 100% !important;
    }
`;

export const NavButtonGroup = styled.div`
    padding-top: 20px;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row-reverse;

    @media (max-width: ${ScreenSize.small}) {
        justify-content: center;
    }
    
    & > * {
        display: inline-block;
        width: auto;
        max-width: 100%;
        margin-bottom: 30px;

        @media (max-width: ${ScreenSize.small}) {
            padding-left: 14px;
            padding-right: 14px;
            flex-grow: 1;
            width: 0;
        }
    }
    
    & > * {
        margin-left: 10px;
    }

    & > :last-child {
        margin-left: 0;
    }
    
    &.riskprofile {
        flex-direction: row;
        margin-left: -20px;
        width: 105%;
        max-width: 105%;

        @media(max-width: ${ScreenSize.small}){
            padding-top: 20px;
        }

        & > * {
            margin-bottom: 10px;
            margin-left: 20px;
        }
        .alignButton {
            margin-left: 10px;
        }
    }
`;

export const BackButtonStyle = styled(SecondaryButton)`
    &:hover,
    &:focus {
        .navArrow {
            color: ${palette.mlcWhite} !important;
        }
    }
`;

export const BackButtonArrow = styled(Svg)`
    color: ${themeGet('colors.brandPrimaryLight')};
`;

export const TooltipLabel = styled.div`
    display: inline;
`;

export const ModalFooterStyle = css`
    div[class*=ModalFooter] {
        display: flex;
        justify-content: center;
        flex-direction: row-reverse;
        max-width: 100%;
        flex-wrap: wrap;

        button {
            @media (max-width: ${ScreenSize.small}) {
                display: block;
                width: 100%;
            }
        }

        .modalFooterLeftButton {
            margin-left: 15px;
            margin-right: 0;

            @media (max-width: ${ScreenSize.small}) {
                display: block;
                width: 100%;
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
        
        .modalFooterRightButton {
            margin-left: 0;
            margin-right: 15px;
            @media (max-width: ${ScreenSize.small}) {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
`;

export const StyledModal = styled(Modal)`
    &__reactModal {
        top: auto;
        @media (max-width: ${ScreenSize.medium}) {
            width: 90vw !important;
            max-width: 90vw !important;
        }

        overflow-x: hidden;
        
        ${props => props.headerSize ? `h1 { font-size: ${props.headerSize} !important; }` : ''}
    }

    ${props => props.wideFooterButton ? `div[class*=ModalFooter] {
        button {
            width: 100%;
        }
    }` : ''}

    ${ModalFooterStyle}
`;

export const WiderModal = styled(Modal)`
    &__reactModal {
        top: auto;
        width: 941px !important;
        max-width: 941px !important;

        @media (max-width: ${ScreenSize.medium}) {
            width: 90vw !important;
            max-width: 90vw !important;
        }

        overflow-x: hidden;
    }

    ${ModalFooterStyle}
`;

export const TableCard = styled(Card)`

    @media (min-width: ${ScreenSize.xxxSmall}) {
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (min-width: ${ScreenSize.xSmall}) {
        padding-left: 30px;
        padding-right: 30px;
    }
`;

export const ModalTableCard = styled(Card)`
    background-color: ${themeGet('colors.detailsBackground')};
    padding-left: 30px;
    padding-right: 30px;
`;

export const PaddedRow = styled(TableRow)`
    &.top-padded {
        td, th {
            padding-top: 20px;
        }
    }

    &.bottom-padded {
        td, th {
            padding-bottom: 20px;
        }
    }
`;

export const TooltipText = styled(Text)`
    font-size: 14px;
    color: ${palette.mlcDarkGrey};
    line-height: 21px;
`;

export const SpeechBubble = styled(Card)`
    position: relative;
	background: ${themeGet('colors.detailsBackground')};
    border-radius: .6em;
    padding: 15px;
    margin-left: 22px;
    width: 100%
    
    ::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
        border: 20px solid transparent;
        border-right-color: ${themeGet('colors.detailsBackground')};
        border-left: 0;
        border-bottom: 0;
        margin-top: 1px;
        margin-left: -15px;
    }
`;

export const SpeedometerContainer = styled(Card)`
    display: flex;
    flex-direction: row;

    @media (max-width: ${ScreenSize.small}) {
        flex-direction: column;
    }
`;

export const SpeedometerContainerSection = styled(Card)`
    @media (min-width: ${ScreenSize.small}) {
        flex-grow: 1;
        width: 0;
    }
`;

export const SpeedometerContainerGraph = styled(Card)`
    @media (min-width: ${ScreenSize.small}) {
        flex-grow: 1;
        width: 0;
        margin-left: 20px;
    }
`;

export const SpeedometerToggleCard = styled(Card)`
    margin-bottom: 0;

    @media (min-width: ${ScreenSize.small}) {
        margin-bottom: 30px;
    }
`;

export const SpeedometerToggleTextCard = styled(Card)`
    margin-bottom: 0;
    width: 90%;
    
    @media (max-width: ${ScreenSize.small}) {
        margin-bottom: 20px;
        width: 100%;
    }
`;

export const SpeedometerToggle = styled(ToggleButtonGroup)`
    margin-top: 10px;
    display: flex;
    justify-content: center;
    div {
        :not(.ToggleButton--isChecked) {
            label {
                background-color: transparent;
            }
        }
    }

    label.ToggleButton__label {
        @media (max-width: ${ScreenSize.xxxSmall}) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
`;

export const SpeedometerLabel = styled.text`
    font-size: 4.5px;

    @media (min-width: ${ScreenSize.medium}) {
        font-size: 4px;
    }

    @media (max-width: ${ScreenSize.medium}) {
        font-size: 5.15px;
    }

    @media (max-width: ${ScreenSize.xSmall}) {
        font-size: 4.5px;
    }

    @media (max-width: ${ScreenSize.xxxSmall}) {
        font-size: 5.15px;
    }
`;

export const SpeedometerLabelValue = styled.tspan`
    font-weight: 700;
    font-size: 5.5px;

    @media (min-width: ${ScreenSize.medium}) {
        font-size: 5px;
    }

    @media (max-width: ${ScreenSize.medium}) {
        font-size: 6.15px;
    }

    @media (max-width: ${ScreenSize.xSmall}) {
        font-size: 5.5px;
    }

    @media (max-width: ${ScreenSize.xxxSmall}) {
        font-size: 6.15px;
    }
`;

export const KateCard = styled(Card)`
    background-color: ${themeGet('colors.detailsBackground')};
    overflow: hidden;

`;

export const HeaderImgCol = styled(Col)`
    display: flex;
    align-items: center;

    @media (max-width: ${ScreenSize.small}) {
        display: none;
    }
`;

export const ResponsivePrimaryButton = styled(PrimaryButton)`
    @media (max-width: ${ScreenSize.small}) {
        width: 100%;
    }
`;

export const ResponsiveSecondaryButton = styled(SecondaryButton)`
    @media (max-width: ${ScreenSize.small}) {
        width: 100%;
    }
`;

export const TrackingText = styled(Text)`
    display: flex;
    margin-top: 20px;
    color: ${themeGet('colors.brandPrimaryDark')};
    font-weight: bold;
    font-size: 20px;
    font-family: ${themeGet('fonts.lightHeading')};
`;

export const StyledOptionsList = styled(OptionsList)`
    margin-top: -5px;

    .options__header {
        font-size: 15px;
        color: #282828;
        line-height: 21px;
        font-weight: bold;
    }

    ul { 
        list-style: disc;
    }

    li {
        padding-top: 5px;
        padding-bottom: 0px;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;

        :first-child {
            padding-top: 10px;
        }

        :last-child {
            padding-bottom: 10px;
        }
    }
`;