import { Card, TextLink, Text } from '@mlc/web-ui-toolkit';
import styled from 'styled-components';
import * as palette from '@mlc/mlc-palette';
import themeGet from '@styled-system/theme-get';
import { ScreenSize } from '../../../../enums';

export const OtherSuperDisclaimerCard = styled(Card)`
    font-size: 14px;
    color: ${palette.mlcDarkGrey};
    margin-bottom: 20px;
`;

export const AboutYouHeader = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    text-align: center;
    img{
        align-items: center;
        padding: 5%;
    }
    .wrapper {
        grid-column-start: 2;
        grid-column-end: 5;        
    }

    @media(max-width: ${ScreenSize.small}){
        grid-template-columns: repeat(1, 1fr);
        img {
            content-visibility: hidden;
            height: 0px;
        }
    }
`;

export const TooltipTextlink = styled(TextLink)`
    font-size: 14px;
    overflow-wrap: break-word;
`;

export const AboutYouInput = styled.div`
    padding-top: 5px;
    display: grid;
    grid-template-columns: repeat(2,250px);
    
    @media (max-width: ${ScreenSize.small}){
        grid-template-columns: 70vw 10vw;
    }
`;

export const AnnuallyText = styled.div`
    padding-top: 10px;
    margin-left: 10px;
`;

export const SuperSizerWrapper = styled.div`
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2,1fr);
    grid-auto-flow: column;

    .yourSuper {
        color : ${themeGet('colors.brandPrimaryDark')};
    }

    .twoLines {
        padding: 0 35%;
        @media (max-width: ${ScreenSize.small}){
            padding: 0 22%;
        }
    }

    span {
        padding: 0px 30%;
        align-self: end;
        @media (max-width: ${ScreenSize.small}){
            padding: 0 12%;
        }
    }

    @media (max-width: $(ScreenSize.small)){
        justify-items: center;
        text-align: center;
        margin-bottom: 20px;
        span {
            width: 115px;
        }
    }
`;

export const WrapTooltipText = styled(Text)`
    @media (max-width: ${ScreenSize.xSmall}){
        margin-right: 3px;
    }
`;