import retirementIncomeImg from '../../../../../assets/images/mkbs/umbrella.png';
import retirementAgeImg from '../../../../../assets/images/mkbs/hourglass.png';
import riskProfileImg from '../../../../../assets/images/mkbs/icon-graph.png';
import regularContributionsImg from '../../../../../assets/images/mkbs/icon-piggybank.png';

const retirementProjection = {

    retirementAgeImg: retirementAgeImg,
    retirementIncomeImg : retirementIncomeImg,
    riskProfileImg : riskProfileImg,
    regularContributionsImg : regularContributionsImg,

}

export default retirementProjection;