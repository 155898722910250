import React from 'react';
import { redirectToError } from '../logic/functions/util';

//https://reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            redirectToError(500);
        }

        return this.props.children;
    }
}

export default ErrorBoundary;