import { get, post } from './fetch';
import { MemberDataResponse, Member, AgePensionEstimateResponse, AgePensionEstimate } from '../domains/memberData.domain';
import { errorInResponse, redirectToError } from './util';
import _ from 'lodash';

export const loadMemberData = async (memberNumber?: any): Promise<Member> => {
    const numberParam = memberNumber ? `?number=${memberNumber}` : ''
    const data = await get(`/members/getMember${numberParam}`) as Promise<MemberDataResponse>;
    if (errorInResponse(await data)) {
        try {
            redirectToError((await data).errorCode);
        } catch {
            redirectToError(500);
        }
    }
    return (await data).response as Member;
}

export const updateMemberData = async (member: Member): Promise<Member> => {
    const data = await post(`/members/updateMember`, member) as Promise<MemberDataResponse>;
    if (errorInResponse(await data)) {
        redirectToError(500);
    }
    return (await data).response as Member;
}

export const getAgePensionEstimate = async (agePensionEstimate: AgePensionEstimate): Promise<AgePensionEstimate> => {
    const data = post(`/members/agePensionEstimate`, agePensionEstimate) as Promise<AgePensionEstimateResponse>;
    return (await data).response as AgePensionEstimate;
}