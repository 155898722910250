import React from "react";
import { Grid, Card, Paragraph, Svg } from '@mlc/web-ui-toolkit';
import { exclamationTriangle } from '@mlc/symbols';

const CalculatorUnavailable = () => {
    return (
        <>
            <Grid>
                <Card marginTop={3} textAlign="center" display="block">
                    <Svg symbol={exclamationTriangle} width="100px"/>
                    <Paragraph>
                        The advice calculator is currently unavailable.
                        <br />We apologise for any inconvenience this may cause.
                    </Paragraph>
                </Card>
            </Grid>
        </>
    )
}

export default CalculatorUnavailable;
