import ReactGA from 'react-ga';

const GA_ACCOUNT_PROD = 'UA-25221494-1';
const GA_ACCOUNT_TEST = 'UA-37632860-1';

export const getPageName = () => window.location.pathname === '/' ? '/' : window.location.pathname.substr(1);

export const gaInit = () => {
    const isProd = window.location.hostname === 'advice.plum.com.au';
    ReactGA.initialize(isProd ? GA_ACCOUNT_PROD : GA_ACCOUNT_TEST, {testMode: process.env.NODE_ENV !== 'production'});
}

export const gaPageView = (page: string) => {
    ReactGA.pageview(page);
}

export const gaEvent = (category: string, action: string, label?: string, value?: number) => {
    ReactGA.event({
        category,
        action,
        label,
        value
    });
}

export const gaDimension = (name: string, value: any) => {
    ReactGA.set({ [name]: value });
}