import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Card, Text, TableCell, Table, Accordion, Heading } from '@mlc/web-ui-toolkit';
import { ScreenSize } from '../../../enums';
import NewWindowLink from '../Common/NewWindowLink';

export const TargetSetOptions = styled.ul`
    li {
        margin-bottom: 10px;
    }

    li:last-child {
        margin-bottom: 0px;
    }
`;

export const ProfileOptionDisplay = styled(Card)`
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    @media (max-width: ${ScreenSize.small}) {
        flex-direction: column;
        margin-bottom: 0px;

        span {
            font-family: ${themeGet('fonts.lightHeading')};
        }
    }
`

export const ProfileDisplaySection = styled(Card)`
    flex: 1 1 0;

    @media (max-width: ${ScreenSize.small}) {
        margin-bottom: 15px;
    }

    @media (min-width: ${ScreenSize.small}) {
        padding-left: 8px;
        padding-right: 8px;

        &.first-profile {
            padding-left: 0;
        }
    }
`;

export const OptionDisplaySection = styled(Card)`
    flex: 2 1 0;

    @media (max-width: ${ScreenSize.small}) {
        flex: 1 1 0;
    }

    @media (min-width: ${ScreenSize.small}) {
        padding-left: 8px;
    }
`;

export const ProfileOptionName = styled(Text)`
    font-weight: 700;

    &.option {
        color: ${themeGet('colors.brandPrimaryDark')};
    }
`;

export const ProposedCell = styled(TableCell)`
    background-color: ${themeGet('colors.secondaryBackground')} !important;
`;

export const MobileTable = styled(Table)`
    td, th {
        background-color: ${themeGet('colors.detailsBackground')};
    }

    th {
        padding-left: 30px;
    }

    td {
        padding-right: 30px;
    }
`;

export const TableProfileOptionName = styled(Text)`
    font-size: 20px;
    font-weight: 700;
`;

export const TableProfileDetails = styled(Card)`
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-direction: row;

    @media (max-width: ${ScreenSize.small}) {
        flex-direction: column;

        div {
            margin-bottom: 15px;
        }

        div:last-child {
            margin-bottom: 0px;
        }
    }
`;

export const ActionsLabel = styled(Heading)`
    color: white;
    margin: 0;
`;

export const TakeActionAccordion = styled(Accordion)`
    margin-bottom: 20px;
    border: thin solid ${themeGet('colors.mlcMediumGrey')};

    .Accordion__icon {
        color: white;
        display: flex;
        align-items: center;
        
        &[hidden] {
            display: none;
        }
    }

    button[class*="Accordionstyle__AccordionButton"] {
        padding-top: 15px;
        padding-bottom: 15px;
        background-color: ${themeGet('colors.brandPrimaryDark')};
    }
`;

export const TakeActionListCard = styled(Card)`
    ul {
        padding-left: 20px;
    }

    li {
        padding-bottom: 1em;
    }
`;

export const TakeActionSectionButtons = styled(Card)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    gap: 5px;
`;

export const LearnMoreLink = styled(NewWindowLink)`
    display: inline-block;
    color: ${themeGet('colors.brandPrimaryLight')} !important;
`;

export const TakeActionContainer = styled(Card)`
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
`;

export const TakeActionSection = styled(Card)`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin: 0 30px;
    background-color: ${themeGet('colors.detailsBackground')};
`;

export const TakeActionContent = styled(Card)`
    padding: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;

    @media (max-width: ${ScreenSize.small}) {
        background-color: ${themeGet('colors.detailsBackground')};
    }
`;

export const TakeActionSectionHeading = styled(Card)`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${themeGet('colors.brandPrimaryDark')};
    padding: 30px 0;

    * {
        color: white;
        font-size: 20px;
    }
`;

export const TakeActionImage = styled(Card)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;

    img {
        width: auto;
        max-width: 90px;
        height: 90px;

        @media (max-width: ${ScreenSize.medium}) {
            height: 90px;
        }

        @media (max-width: ${ScreenSize.small}) {
            height: 70px;
        }
    }
`;

export const TakeActionHelpImageCard = styled(Card)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    gap: 30px;
`;

export const TakeActionHelpMainCard = styled(Card)`
    left: 50%;
    margin-left: -50vw;    
    width: 100vw;
    background-color: ${themeGet('colors.detailsBackground')};
    padding: 25px 0;
`;