import React from "react";

const Unauthorised = () => {
    return (
        <div className='unauthorised'>
            <h1>401 Unauthorised access</h1>
            <p><strong>There is a problem with the page you are trying to reach and it cannot be displayed.</strong></p>
            <p>This could be due to one of the following reasons:</p>
            <ul>
                <li>Page is unavailable</li>
                <li>Unauthorised access</li>
            </ul>
            <p>Please return to the homepage and try again.</p>
        </div>
    )
}

export default Unauthorised;
