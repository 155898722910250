
import React from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import { ProgressBarCard, ProgressBarDiv } from "./NavBar.style";
import "react-step-progress-bar/styles.css";
import { Grid } from "@mlc/web-ui-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

interface Props{
  progress : number;
  length : number;
  activeStep: number;
}

const StepProgressBar = ({progress, length, activeStep}: Props) =>  {
    return (<>
    <ProgressBarCard>
      <Grid>
      <ProgressBarDiv>
        <ProgressBar height={3} filledBackground="white" percent={progress}>
          {[...Array.from(Array(length).keys())].map((key) => (
            <Step key={key} >
              {({ accomplished, index }) => (
              <div
                className={`indexedStep ${accomplished ? "accomplished" : null}`}
              >
                {activeStep > index + 1 ? <FontAwesomeIcon icon={faCheck}/> : index + 1}
              </div>
            )}
            </Step>
          ))}
        </ProgressBar>  
      </ProgressBarDiv>
      </Grid>
      </ProgressBarCard>
      </>
    );
  }
export default StepProgressBar;