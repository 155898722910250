import { questionCircle, times } from '@mlc/symbols';
import { Card, Svg } from '@mlc/web-ui-toolkit';
import React, { useEffect, useRef, useState } from 'react';
import { Popup } from 'semantic-ui-react';
import { logAction } from '../../../logic/functions/logging.function';
import commonTestIds from './Common.elements';
import { InfoButton, InfoPopupCloseButton, TooltipText } from './Common.style';

type Props = {
    content: any;
    dataId?: string;
    label?: string;
    disabled?: boolean;
    log?: string;
    absPos?: boolean;
}

type ContentProps = {
    content: any;
    closePopupFn: any;
}

const PopupContent = (props: ContentProps) => {
    const popupRef = useRef<any>();
    const closeRef = useRef<any>();
    useEffect(() => {
        setTimeout(() => {
            popupRef.current && popupRef.current.focus({ preventScroll: true });
        }, 100);
    }, []);

    const onContentTab = (e: any) => {
        if (e.keyCode === 9 || e.key === 'Tab') {
            e.preventDefault();
            if (e.shiftKey) {
                props.closePopupFn();
            } else {
                setTimeout(() => {
                    closeRef.current && closeRef.current!.focus();
                }, 100);
            }
        }
    }
    return (
        <Card data-id={commonTestIds.infoPopupContent} width="275px">
            <Card display="block" textAlign="right">
                <InfoPopupCloseButton
                    onClick={props.closePopupFn}
                    onKeyDown={(e: any) => {
                        if (e.keyCode === 9 || e.key === 'Tab') {
                            e.preventDefault();
                            props.closePopupFn();
                        }
                    }}
                    aria-label="Close information pop-up"
                    data-id={commonTestIds.infoPopupClose}
                    type="button"
                    ref={closeRef}
                >
                    <Svg symbol={times} />
                </InfoPopupCloseButton>
            </Card>
            <TooltipText>
                <div style={{ outline: 'none' }} data-id={commonTestIds.infoPopupText} ref={popupRef} tabIndex={-1} onKeyDown={onContentTab}>
                    {props.content}
                </div>
            </TooltipText>
        </Card>
    )
}

const InfoPopup = ({absPos = true, ...props}: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const btnRef = useRef<any>();
    const closeAndFocus = () => {
        setIsOpen(false);
        setTimeout(() => {
            btnRef.current && btnRef.current.focus();
        }, 100);
    }

    return (
        <Popup
            content={<PopupContent content={props.content} closePopupFn={closeAndFocus} />}
            trigger={
                <div style={{ display: "inline" }}>
                    <InfoButton
                        type="button"
                        ref={btnRef}
                        aria-label={props.label || "Open information pop-up"}
                        data-id={props.dataId}
                        disabled={props.disabled}
                        aria-disabled={props.disabled}
                        className={props.disabled ? "disabledForm" : ""}
                        onClick={() => props.log && logAction(props.log)}
                        style={absPos ? {position: "absolute"} : {}} //keeps tooltip on same line as last word of the parent text. set false for things like headings since it messes with some relative positioning
                    >
                        <Svg symbol={questionCircle} />
                    </InfoButton>
                </div>
            }
            position="right center"
            on="click"
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            basic={true}
            flowing={true}
        />
    )
};

export default InfoPopup;