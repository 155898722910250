import { Table, TableCell, TableHead, TableRow, Text, TextLink, Card } from "@mlc/web-ui-toolkit";
import React, { useContext } from 'react';
import { AppThemeContext } from "../../../../../context/AppThemeContext";
import { Contribution } from '../../../../../logic/domains/memberData.domain';
import { asDeductible, asRoundedCurrency } from '../../../../../logic/functions/formatter';
import { logAction } from "../../../../../logic/functions/logging.function";
import { TableCard } from '../../../Common/Common.style';
import InfoPopup from "../../../Common/InfoPopup";
import { ProposedContributionsCell, CurrentContributionsCell, ContributionsTableBody } from "../CalculatorSteps.style";
import regularContributionsTestIds from './RegularContributions.elements';
import { showNcc } from "../../../../../logic/functions/util";

type Values = {
    rowHeader: any;
    currentContributionContent: any;
    currentContributionContentId: string;
    proposedContributionContent: any;
    proposedContributionContentId: string;
}

type DesktopRowProps = {
    values: Values;
    bolded?: boolean;
    tooltip?: boolean;
}

type Props = {
    currentContribution: Contribution;
    proposedContribution: Contribution;
    setIsDefinitionsOpen: () => void;
    definitionsRef: any;
    productName: string;
    showProposedValues: boolean;
    showTooltip: boolean;
}

const DesktopTableRow = ({ values, bolded, tooltip }: DesktopRowProps) => {
    const {
        rowHeader,
        currentContributionContent,
        currentContributionContentId,
        proposedContributionContent,
        proposedContributionContentId
    } = values;
    return (
        <TableRow>
            <TableCell el="th" scope="row" align="left">
                {tooltip ? rowHeader : (<Text fontWeight={bolded ? "semibold" : "regular"}>{rowHeader}</Text>)}
            </TableCell>
            <CurrentContributionsCell align="right" data-id={currentContributionContentId}>
                <Text fontWeight={bolded ? "semibold" : "regular"}>{currentContributionContent}</Text>
            </CurrentContributionsCell>
            <ProposedContributionsCell align="right" data-id={proposedContributionContentId}>
                <Text fontWeight={bolded ? "semibold" : "regular"}>{proposedContributionContent}</Text>
            </ProposedContributionsCell>
        </TableRow>
    );
};

const RegularContributionsTable = ({ currentContribution, proposedContribution, setIsDefinitionsOpen, definitionsRef, showProposedValues, productName, showTooltip }: Props) => {
    const content = useContext(AppThemeContext).staticContent.regularContributions(productName);
    // only display proposed values if a contribution amount has been entered at least once
    const getProposedValue = (val: any) => showProposedValues ? val : '-';  

    const taxableIncomeValues: Values = {
        rowHeader: "Taxable income",
        currentContributionContent: asRoundedCurrency(currentContribution.taxableIncome),
        currentContributionContentId: regularContributionsTestIds.taxableIncomeCurrent,
        proposedContributionContent: getProposedValue(asRoundedCurrency(proposedContribution.taxableIncome)),
        proposedContributionContentId: regularContributionsTestIds.taxableIncomeProposed,
    };

    const lessVoluntaryValues: Values = {
        rowHeader: (<>
            {showTooltip ? 
               (
                <>
                    <Text fontWeight="semibold"> Less voluntary concessional contributions (before tax) </Text>
                    <InfoPopup label="Less voluntary concessional contributions (before tax)" content="To help manage increases in employment income (such as bonuses), that could result in exceeding the concessional contribution cap, the contribution amounts recommended by the calculator are limited to the annual concessional contribution caps reduced by $2,000 (that is, a $2,000 buffer is applied)." log="CC_CONT_TOOLTIP_TABLE"/>
                </>
                ) : (
                    <Text fontWeight="semibold">Less voluntary concessional contributions (before tax)</Text>
                )}
                </>),
        currentContributionContent: asDeductible(currentContribution.salarySacrifice),
        currentContributionContentId: regularContributionsTestIds.lessVoluntaryCurrent,
        proposedContributionContent: getProposedValue(asDeductible(proposedContribution.salarySacrifice)),
        proposedContributionContentId: regularContributionsTestIds.lessVoluntaryProposed,
    };

    const lessTaxValues: Values = {
        rowHeader: "Less tax",
        currentContributionContent: asDeductible(currentContribution.incomeTax),
        currentContributionContentId: regularContributionsTestIds.lessTaxCurrent,
        proposedContributionContent: getProposedValue(asDeductible(proposedContribution.incomeTax)),
        proposedContributionContentId: regularContributionsTestIds.lessTaxProposed,
    };

    const netIncomeValues: Values = {
        rowHeader: "Net income after tax",
        currentContributionContent: asRoundedCurrency(currentContribution.netIncomeAfterTax),
        currentContributionContentId: regularContributionsTestIds.netIncomeCurrent,
        proposedContributionContent: getProposedValue(asRoundedCurrency(proposedContribution.netIncomeAfterTax)),
        proposedContributionContentId: regularContributionsTestIds.netIncomeProposed,
    };

    const lessNonConcessionalValues: Values = {
        rowHeader: "Less non concessional contributions (after tax)",
        currentContributionContent: asDeductible(currentContribution.personal),
        currentContributionContentId: regularContributionsTestIds.lessNonConcessionalCurrent,
        proposedContributionContent: getProposedValue(asDeductible(showNcc(proposedContribution) ? proposedContribution.personal : 0)),
        proposedContributionContentId: regularContributionsTestIds.lessNonConcessionalProposed,
    };

    const takeHomePayValues: Values = {
        rowHeader: "Total take home pay",
        currentContributionContent: asRoundedCurrency(currentContribution.totalTakeHomePay),
        currentContributionContentId: regularContributionsTestIds.takeHomePayCurrent,
        proposedContributionContent: getProposedValue(asRoundedCurrency(proposedContribution.totalTakeHomePay + (!showNcc(proposedContribution) ? proposedContribution.personal : 0))),
        proposedContributionContentId: regularContributionsTestIds.takeHomePayProposed,
    };

    const employerContributionsValues: Values = {
        rowHeader: "Employer contributions",
        currentContributionContent: asRoundedCurrency(currentContribution.superGuarantee),
        currentContributionContentId: regularContributionsTestIds.employerContributionsCurrent,
        proposedContributionContent: getProposedValue(asRoundedCurrency(proposedContribution.superGuarantee)),
        proposedContributionContentId: regularContributionsTestIds.employerContributionsProposed,
    };

    const govCoContributionValues: Values = {
        rowHeader: "Government co-contribution",
        currentContributionContent: asRoundedCurrency(currentContribution.govCoCotribution),
        currentContributionContentId: regularContributionsTestIds.govCoContributionCurrent,
        proposedContributionContent: getProposedValue(asRoundedCurrency(proposedContribution.govCoCotribution)),
        proposedContributionContentId: regularContributionsTestIds.govCoContributionProposed,
    };

    const lowIncomeSuperTaxOffsetValues: Values = {
        rowHeader: "Low income super tax offset",
        currentContributionContent: asRoundedCurrency(currentContribution.lowIncomeSuperContribution),
        currentContributionContentId: regularContributionsTestIds.lowIncomeSuperTaxOffsetCurrent,
        proposedContributionContent: getProposedValue(asRoundedCurrency(proposedContribution.lowIncomeSuperContribution)),
        proposedContributionContentId: regularContributionsTestIds.lowIncomeSuperTaxOffsetProposed,
    };

    return (
        <TableCard marginTop="20px">
            <Table variant="bottomLines" data-id={regularContributionsTestIds.contributionsBreakdown}>
                <TableHead>
                    <TableRow>
                        <td width="50%"></td>
                        <CurrentContributionsCell el="th" scope="col" align="right" className="topCell">
                            Current
                            <InfoPopup
                                label="information about current contributions"
                                content={content.currentTooltip}
                                dataId={regularContributionsTestIds.currentContributionsInfo}
                                log="CURRENT_CONT_TT"
                            />
                        </CurrentContributionsCell>
                        <ProposedContributionsCell el="th" scope="col" align="right" className="topCell">
                            Proposed
                            <InfoPopup
                                label="information about proposed contributions"
                                content={content.proposedTooltip}
                                dataId={regularContributionsTestIds.proposedContributionsInfo}
                                log="PROPOSED_CONT_TT"
                            />
                        </ProposedContributionsCell>
                    </TableRow>
                </TableHead>
                <ContributionsTableBody>
                    <DesktopTableRow values={taxableIncomeValues} />
                    <DesktopTableRow values={lessVoluntaryValues} bolded={true} tooltip={true} />
                    <DesktopTableRow values={lessTaxValues} />
                    <DesktopTableRow values={netIncomeValues} />
                    <DesktopTableRow values={lessNonConcessionalValues} bolded={true} />
                    <DesktopTableRow values={takeHomePayValues} bolded={true} />
                    <DesktopTableRow values={employerContributionsValues} />
                    {(currentContribution.govCoCotribution > 0 || proposedContribution.govCoCotribution > 0) && (
                        <DesktopTableRow values={govCoContributionValues} />
                    )}
                    {(currentContribution.lowIncomeSuperContribution > 0 || proposedContribution.lowIncomeSuperContribution > 0) && (
                        <DesktopTableRow values={lowIncomeSuperTaxOffsetValues} />
                    )}
                </ContributionsTableBody>
            </Table>
            <Card paddingLeft="10px" marginTop="10px" paddingBottom="20px">
                <TextLink
                    el="button"
                    type="button"
                    className="definitions"
                    onClick={() => {
                        logAction("CONT_TERMS_TT");
                        setIsDefinitionsOpen();
                    }}
                    ref={definitionsRef}
                    data-id={regularContributionsTestIds.termDefinitionsBtn}
                >
                    Definitions
                </TextLink>
            </Card>
        </TableCard>
    );
};

export default RegularContributionsTable;