import { Card } from '@mlc/web-ui-toolkit';
import React, { useContext } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import * as palette from '@mlc/mlc-palette';
import commonTestIds from '../Common.elements';
import { asCurrency, asRoundedCurrency } from '../../../../logic/functions/formatter';
import DetailsText from '../DetailsText';
import { ThemeContext } from 'styled-components';
import { AppThemeContext } from '../../../../context/AppThemeContext';
import { SpeedometerLabelValue, SpeedometerLabel } from '../Common.style';

type Props = {
    needed: number;
    received: number;
    agePension?: number;
    label?: string;
    disabled?: boolean;
    includeAgePension: boolean;
    showIncome: boolean;
}

const graphColoursMlc = [palette.mlcOrange, palette.mlcAqua, palette.mlcLightGrey];
const graphColoursPlum = ["#572381", "#C9CB00", palette.mlcWhite]

const getGraphData = (needed: number, received: number, agePension = 0, isMlc: boolean, includeAgePension: boolean, showIncome: boolean) => {
    let data: any[] = [];
    //minimum dy to be above line at bottom of graph
    const minDy = showIncome ? -6.75 : -13.25;
    const moreNeeded = Math.round(needed) > Math.round(received) + parseInt(agePension.toString());
    const total = Math.max(needed, received + agePension);

    let graphColours = isMlc ? graphColoursMlc : graphColoursPlum;
    const colours = {
        received: graphColours[0],
        needed: graphColours[2],
        agePension: graphColours[1],
        receivedText: graphColours[0],
        neededText: palette.mlcBlack,
        agePensionText: isMlc ? graphColours[1] : "#4C626C",
    }

    const values = {
        received: moreNeeded ? received : total - agePension,
        needed: moreNeeded ? total - (received + agePension) : 0,
        agePension: agePension
    }

    let receivedObj = {
        title: showIncome ? 'Projected super income per month' : 'Projected super balance',
        label: showIncome ? 'You may receive' : 'You may have',
        value: values.received,
        color: colours.received,
        textColor: colours.receivedText,
        displayValue: asRoundedCurrency(received),
        dy: minDy,
        id: 'received',
        dataId: commonTestIds.speedometerReceivedLabel,
        key: 'received'
    };
    let neededObj = {
        title: showIncome ? 'Target super income per month' : 'Target super balance',
        label: showIncome ? (moreNeeded ? 'You may need' : 'You may only need') : 'You may need',
        value: values.needed,
        color: colours.needed,
        textColor: colours.neededText,
        displayValue: asRoundedCurrency(needed),
        id: 'needed',
        dataId: commonTestIds.speedometerNeededLabel,
        key: 'needed'
    };
    let agePensionObj = {
        title: 'Age Pension',
        label: '+ Age Pension',
        value: values.agePension,
        color: colours.agePension,
        textColor: colours.agePensionText,
        displayValue: asCurrency(agePension),
        dy: received > (total / 2) ? minDy : minDy - 13,
        id: 'age pension',
        dataId: commonTestIds.speedometerAgePensionLabel,
        key: 'agePension'
    };

    data.push(receivedObj);
    if (includeAgePension) {
        data.push(agePensionObj);
    }
    moreNeeded ? data.push(neededObj) : data.unshift(neededObj);

    return {
        total,
        data
    }
};

const SectionLabel = ({ showIncome, ...props }: any) => {
    const getDx = (dx: number, initialDy: number, setDy: number) => {
        const newDy = Math.min(initialDy, setDy);
        if (newDy > -20) {
            return dx < 0 ? dx + 4.2 : dx - 4.2;
        }
        return dx;
    }

    if (props.dataEntry.id === 'needed') {
        return (
            <SpeedometerLabel x={64} y={46} textAnchor="middle" dx={0} dy={0} style={{ ...props.style }} data-id={props.dataEntry.dataId}>
                {`${props.dataEntry.label} `}<SpeedometerLabelValue style={{ ...props.style }}>{props.dataEntry.displayValue}</SpeedometerLabelValue>
            </SpeedometerLabel>
        )
    } else {
        return (
            <>
                <SpeedometerLabel x={props.x} y={props.y - 1} textAnchor={props.textAnchor} dx={getDx(props.dx, props.dy, props.dataEntry.dy)} dy={Math.min(props.dy, props.dataEntry.dy)} style={{ ...props.style }} data-id={props.dataEntry.dataId}>
                    {`${props.dataEntry.label} `}
                    <SpeedometerLabelValue x={props.x} y={props.y + 5} textAnchor={props.textAnchor} dx={getDx(props.dx, props.dy, props.dataEntry.dy)} dy={Math.min(props.dy, props.dataEntry.dy)} style={{ ...props.style }}>
                        {props.dataEntry.displayValue}
                    </SpeedometerLabelValue>
                </SpeedometerLabel>
            </>
        );
    }
}

const Speedometer = ({ needed, received, agePension = 0, label, disabled, includeAgePension, showIncome }: Props) => {
    const isMlc = useContext(AppThemeContext).isMlc;
    const themeContext = useContext(ThemeContext);
    const displayedAgePension = includeAgePension ? agePension : 0;
    const graphData = getGraphData(needed, received, displayedAgePension, isMlc, includeAgePension, showIncome);
    const graphDesc = `Graph comparing your projected super ${showIncome ? 'income' : 'balance'} of ${asRoundedCurrency(received)}${includeAgePension ? ` plus your projected age pension of ${asCurrency(agePension)}` : ''} against your target super ${showIncome ? 'income' : 'balance'} of ${asRoundedCurrency(needed)}`;

    return (
        <Card textAlign="center" data-id={commonTestIds.speedometerGraph}>
            <Card textAlign="center" marginBottom="15px">
                <DetailsText el="h2" fontSize="16px" data-id={commonTestIds.speedometerGraphLabel} role={null} display="inline">
                    {label}
                </DetailsText>
            </Card>
            <PieChart
                data={graphData.data}
                startAngle={180}
                lengthAngle={180}
                radius={30}
                viewBoxSize={[128, 40]}
                center={[64, 40]}
                lineWidth={45}
                totalValue={graphData.total}
                labelPosition={120}
                label={(props) => <SectionLabel {...props} showIncome={showIncome} key={props.dataEntry.key} />}
                labelStyle={(index) => ({
                    fontFamily: themeContext.fonts.lightHeading,
                    fill: graphData.data[index].textColor || graphData.data[index].color
                })}
                style={{ overflow: 'visible' }}
            >
                <title>{label}</title>
                <desc>{graphDesc}</desc>
                <line x1="10" y1="40" x2="118" y2="40" stroke={palette.mlcMediumGrey} strokeWidth="0.25px" />
            </PieChart>
        </Card>
    )
};

export default Speedometer;