import * as palette from '@mlc/mlc-palette';
import { InvestmentMix } from "../../../../../logic/domains/memberData.domain";
import { PieChart } from 'react-minimal-pie-chart';
import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AllocationLegend, AllocationSection, ExpandButton, ExpandContentRiskProfile, ExpandRiskProfileBorder, ExpandRiskProfileContent, ExpandRiskProfileContentContainer, ExpandRiskProfileText, FinishedRPButton, LifestyleContent, NewRiskProfileContent, MaintainRiskProfileBorder, NewRiskProfileSelect, PieChartContent, RiskProfileSelection, RiskProfileSelectionContent, RiskProfileSelectionSection } from "../CalculatorSteps.style";
import classNames from "classnames";
import { faCheckCircle, faCircle, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Paragraph, Text } from '@mlc/web-ui-toolkit';
import { Button } from "@mlc/web-ui-toolkit/lib/components/Button/Button";
import { riskProfileValues } from "./StaticContent";
import * as constants from './StaticContent';
import { getProfileTypeName } from "../../../../../logic/functions/util";
import { AppThemeContext } from '../../../../../context/AppThemeContext';
import { RiskProfileMixType } from '../../../../../enums';
import InfoPopup from '../../../Common/InfoPopup';
import riskProfileTestIds from './RiskProfile.elements';
import { logAction } from '../../../../../logic/functions/logging.function';

type Props = {
    investmentProfileType: InvestmentMix;
    selectedProfileType: InvestmentMix;
    inToolCustomMix: InvestmentMix;
    hasAlternateAssetAllocation: boolean;
    setUpdatingMember: () => void;
    maintainInvestments: boolean;
    values: any;
    setFieldValue: any;
}

const RiskProfileGraphContainer = ({ investmentProfileType, selectedProfileType, setUpdatingMember, values, setFieldValue, maintainInvestments, hasAlternateAssetAllocation, inToolCustomMix }: Props) => {
    const growthColor = useContext(AppThemeContext).isMlc ? palette.mlcOrange : "#572381";
    const defensiveColor = useContext(AppThemeContext).isMlc ? palette.mlcDeepOrange : "#F7B000";
    const selectedType = getProfileTypeName(inToolCustomMix.type!);

    const [selected, setSelected] = useState([maintainInvestments, !maintainInvestments]);
    const [expanded, setExpanded] = useState(false);
    const [localSelected, setLocalSelected] = useState(inToolCustomMix.type);

    const deSelectOthers = (arr: boolean[], num: number) =>
        arr.map((value: boolean, index: number) => (index === num));

    const selectNew = (num: number) =>
        setSelected(prevState => {
            return deSelectOthers(prevState, num);
        });


    const expand = (current: boolean) =>
        setExpanded(state => {
            logAction("RP_DONE_EXPAND");
            return (state ? false : true);
        });

    useEffect(() => {
        if (localSelected !== inToolCustomMix.type) {
            setLocalSelected(inToolCustomMix.type);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inToolCustomMix]);

    useEffect(() => {
        if (maintainInvestments) {
            selectNew(0);
        } else {
            selectNew(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maintainInvestments])

    const ChartContent = ({ growth, defensive, selectIndex, hasTooltip }: any) => (
        <>
            <RiskProfileSelectionContent>
                <Text textAlign="center">
                    <b>Target asset allocation</b>{hasTooltip && (
                        (hasAlternateAssetAllocation &&
                            [RiskProfileMixType.Growth, RiskProfileMixType.Balanced].indexOf(selectedType) >= 0) &&
                        <InfoPopup
                            label="information about growth alternative asset allocation"
                            content={constants.alternativeAllocationTooltip(selectedType)}
                            dataId={riskProfileTestIds.altAllocationTooltip}
                            log="ALT_ALLOCATION_TT"
                        />

                    )}
                </Text>
            </RiskProfileSelectionContent>
            <RiskProfileSelectionContent>
                <AllocationLegend>
                    <AllocationSection>
                        <svg width="15" height="15">
                            <rect style={{ fill: growthColor }} width="15" height="15" className="growth" />
                        </svg>Growth
                    </AllocationSection>
                    <AllocationSection>
                        <svg width="15" height="15">
                            <rect style={{ fill: defensiveColor }} width="15" height="15" className="defensive" />
                        </svg>Defensive
                    </AllocationSection>
                </AllocationLegend>
            </RiskProfileSelectionContent>
            <PieChartContent>
                <PieChart data={[
                    { title: 'Growth', value: Math.round(growth), color: growthColor },
                    { title: 'Defensive', value: Math.round(defensive), color: defensiveColor },
                ]}
                    // Default is 50
                    radius={46}
                    center={[((Math.round(growth) > 0 && Math.round(defensive) > 0) ? 50 : 54), 50]}
                    segmentsShift={(index) => (index === 0 ? 4 : 0.5)}
                    label={({ dataEntry }) => dataEntry.value > 0 ? `${dataEntry.value}% ` : ""}
                />
            </PieChartContent>
            <LifestyleContent className={classNames("expandButton", expanded ? "expanded" : "", selected[selectIndex] ? "selected" : "")}>
                <ExpandButton type="button" onClick={() => {
                    expand(expanded);
                }}
                    className={classNames(expanded ? "expanded" : "")}
                    data-id={riskProfileTestIds.riskProfileExpand}
                >
                    <FontAwesomeIcon size="lg" icon={expanded ? faMinus : faPlus} />
                </ExpandButton>
            </LifestyleContent>
        </>
    );

    const ExpandContent = ({ selectIndex, investmentProfileValues }: any) => (
        <ExpandContentRiskProfile className={classNames(selected[selectIndex] ? "selected" : "")}>
            <ExpandRiskProfileContent>
                <AllocationSection>
                    <svg width="15" height="15">
                        <rect style={{ fill: growthColor }} className="growth" width="15" height="15" />
                    </svg>Growth
                </AllocationSection>
                <ExpandRiskProfileText>
                    <Text>
                        Have the potential for the higher returns but the risks of losing money are increased. especially over the short term.
                    </Text>
                </ExpandRiskProfileText>
            </ExpandRiskProfileContent>
            <ExpandRiskProfileContent>
                <AllocationSection>
                    <svg width="15" height="15">
                        <rect style={{ fill: defensiveColor }} className="growth" width="15" height="15" />
                    </svg>Defensive
                </AllocationSection>
                <ExpandRiskProfileText>
                    <Text>
                        Are usually more stable, and less likely to lose money but the returns may be lower over the long term.
                    </Text>
                </ExpandRiskProfileText>
            </ExpandRiskProfileContent>
            <ExpandRiskProfileContent className={classNames("topPadding", "investmentInfo")}>
                <ExpandRiskProfileBorder>
                    <Text textAlign="center" fontWeight="semibold">
                        Minimum investment timeframe
                    </Text>
                    <Text>
                        {constants.riskProfileDescription[investmentProfileValues.type].investmentTimeframe}
                    </Text>
                </ExpandRiskProfileBorder>
            </ExpandRiskProfileContent>
            <ExpandRiskProfileContent className="investmentInfo">
                <ExpandRiskProfileBorder>
                    <Text fontWeight="semibold">
                        Risk tolerance
                    </Text>
                    <Text>
                        {(constants.riskProfileVolatilityValues as any)[investmentProfileValues.volatility].tolerance}
                    </Text>
                </ExpandRiskProfileBorder>
            </ExpandRiskProfileContent>
            <ExpandRiskProfileContent className="investmentInfo">
                <ExpandRiskProfileBorder>

                    <Text fontWeight="semibold">
                        Expected return
                    </Text>
                    {/* Need to round to the nearest 0.01 decimal for display */}
                    <Text>
                        {(Math.round(investmentProfileValues.expectedReturn * 100) / 100).toFixed(2)}% pa
                    </Text>
                </ExpandRiskProfileBorder>
            </ExpandRiskProfileContent>
            <ExpandRiskProfileContent className={classNames("bottomBorder", "investmentInfo")}>
                <ExpandRiskProfileBorder>

                    <Text fontWeight="semibold">
                        Chance of a loss
                    </Text>
                    <Text>
                        {investmentProfileValues.expectedLoss}
                    </Text>
                </ExpandRiskProfileBorder>
            </ExpandRiskProfileContent>
        </ExpandContentRiskProfile>
    )

    return (
        <>
            <RiskProfileSelectionSection data-id={riskProfileTestIds.yourInvestmentStrategyGraphContainer}>
                <RiskProfileSelection data-id={riskProfileTestIds.maintainRPContainer} className={classNames((selected[0] ? "selected" : ""), (expanded ? "expanded" : ""))}>
                    <LifestyleContent>
                        <Button type="button" className="selectionButton" onClick={() => {
                            if (!selected[0]) {
                                setFieldValue("maintainInvestments", true);
                                setUpdatingMember();
                                logAction("MAINTAIN_INVESTENTS_SELECT");
                            }
                        }
                        }>
                            <FinishedRPButton className={classNames(selected[0] ? "selected" : "", "selectionButton")}>
                                <FontAwesomeIcon size="2x" icon={selected[0] ? faCheckCircle : faCircle} />
                                <Text el="h3" margin={0}> <b>Maintain current investments</b></Text>
                            </FinishedRPButton>
                        </Button>
                    </LifestyleContent>
                    <LifestyleContent className="maintainBorder">
                        <MaintainRiskProfileBorder>
                            <Paragraph>
                                <b>{getProfileTypeName(investmentProfileType.type!)}</b>
                            </Paragraph>
                        </MaintainRiskProfileBorder>
                    </LifestyleContent>
                    <ChartContent growth={investmentProfileType.split.growth} defensive={investmentProfileType.split.defensive} selectIndex={0} hasTooltip={false} />
                </RiskProfileSelection>
                <RiskProfileSelection data-id={riskProfileTestIds.newRPContainer} className={classNames((selected[1] ? "selected" : ""), (expanded ? "expanded" : ""))}>
                    <LifestyleContent>
                        <Button type="button" className="selectionButton" onClick={() => {
                            if (!selected[1]) {
                                setFieldValue("maintainInvestments", false);
                                setFieldValue("selectedProfileType.type", localSelected);
                                setUpdatingMember();
                                logAction("NEW_RP_SELECT");
                            }
                        }
                        }>
                            <FinishedRPButton className={classNames(selected[1] ? "selected" : "", "selectionButton")}>
                                <FontAwesomeIcon size="2x" icon={selected[1] ? faCheckCircle : faCircle} />
                                <Text el="h3" margin={0}><b>Your new risk profile</b></Text>
                            </FinishedRPButton>
                        </Button>
                    </LifestyleContent>
                    <LifestyleContent>
                        <NewRiskProfileContent>
                            <NewRiskProfileSelect
                                value={localSelected!}
                                onChange={(e: any) => {
                                    setLocalSelected(e.target.value);
                                }}
                                onBlur={() => {
                                    if (selectedProfileType.type !== localSelected || maintainInvestments) {
                                        setFieldValue("maintainInvestments", false);
                                        setFieldValue("selectedProfileType.type", localSelected);
                                        setUpdatingMember();
                                        logAction("NEW_RP_SELECT");
                                    }
                                }}
                                onKeyDown={(e: any) => {
                                    if (e.keyCode === 13 || e.key === 'Enter') {
                                        if (selectedProfileType.type !== localSelected || maintainInvestments) {
                                            setFieldValue("maintainInvestments", false);
                                            setFieldValue("selectedProfileType.type", localSelected);
                                            setUpdatingMember();
                                            logAction("NEW_RP_SELECT");
                                        }
                                    }
                                }}
                                options={riskProfileValues(values.suggestedProfileType || "")}
                                data-id={riskProfileTestIds.riskProfileSelect}
                            />
                        </NewRiskProfileContent>
                    </LifestyleContent>
                    <ChartContent growth={inToolCustomMix.split.growth} defensive={inToolCustomMix.split.defensive} selectIndex={1} hasTooltip={true} />
                </RiskProfileSelection>
            </RiskProfileSelectionSection>
            <ExpandRiskProfileContentContainer className={classNames(expanded ? "expanded" : "")}>
                <ExpandContent investmentProfileValues={investmentProfileType} selectIndex={0} />
                <ExpandContent investmentProfileValues={inToolCustomMix} selectIndex={1} />
            </ExpandRiskProfileContentContainer>
        </>
    );
};
export default RiskProfileGraphContainer;