import * as palette from '@mlc/mlc-palette';
import { Accordion, FieldWrapper, NumberInput, TertiaryButton, Text } from '@mlc/web-ui-toolkit';
import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import { ScreenSize } from '../../../../../enums';
import Select from '../../../Common/Select';

export const RetirementProjectionHeading = styled(Text)`
    font-weight: bold;
    margin: 0;
    margin-bottom: 15px;
`;

export const PlayAreaWrapper = styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(5, auto);
    grid-auto-flow: column;
    grid-gap: 0px 15px;

    @media (max-width: ${ScreenSize.medium}) {
        grid-gap: 0px 7.5px;
    }
`;

export const PlayAreaSection = styled.div`
    display: contents;

    img { 
        margin-top: 5px;
        padding: 5px;
        object-fit: contain;
        width: 100%;
        max-height: 80px;
    }


    div.expandButton {
        padding: 10px 0;
        display: flex;
        justify-content: end;

        Button{
            border-style: none;
            background-color: ${themeGet('colors.detailsBackground')};
        }
    }
`;

export const PlayAreaContent = styled.div`
    padding-left: 10px;
    padding-right: 10px;

    :first-child {
        background-color: ${themeGet('colors.brandPrimaryDark')};
        border-top: 1px solid ${palette.mlcLightGrey};
        border-left: 1px solid ${palette.mlcLightGrey};
        border-right: 1px solid ${palette.mlcLightGrey};
    }

    :not(:first-child):not(:last-child) {    
        background-color: ${themeGet('colors.detailsBackground')};
        border-left: 1px solid ${palette.mlcLightGrey};
        border-right: 1px solid ${palette.mlcLightGrey};
    }

    :last-child { 
        background-color: ${themeGet('colors.detailsBackground')};
        border-bottom: 1px solid ${palette.mlcLightGrey};
        border-left: 1px solid ${palette.mlcLightGrey};
        border-right: 1px solid ${palette.mlcLightGrey};
    }

    &.expanded {
        :last-child {
            border-bottom: 1px solid ${themeGet('colors.detailsBackground')};
            }
        }
    }
`;

export const PlayAreaFieldWrapper = styled(FieldWrapper)`
    margin: 20px 0;

    div {
        margin: 0;
    }

    .FieldWrapper__error {
        margin-top: 10px !important;
    }

    @media (max-width: ${ScreenSize.small}) {
        margin: 0;
    }

    &.retirementAgeInput {
        .InputWrapper {
            max-width: 60px;
        }
    }
    
    &.retirementIncomeInput, &.contributionsInput {
        .InputWrapper {
            max-width: 120px;
        }
    }

    &.riskProfileInput {
        max-width: 300px;
    }
`;

export const PlayAreaText = styled(Text)`
    margin: 10px 30px 0;
    display: inline-block;
    text-align: center;
`;

export const PlayAreaExpandWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, minmax(0,1fr));
    grid-template-rows: repeat(2, auto);
    grid-auto-flow: column;
    grid-gap: 0px 15px;

    overflow: hidden;
    max-height: 0px;
    transition: 0.5s max-height ease-in-out;
    &.expanded {
        max-height: 100vh;
    }

    @media (max-width: ${ScreenSize.medium}) {
        grid-gap: 0px 10px;
    }
`;

export const PlayAreaExpandSection = styled.div`
    display: contents;
    div {
        background-color: ${themeGet('colors.detailsBackground')};
    }
`;

export const PlayAreaExpandContent = styled.div`
    span {
        margin: 10px 20px;
        display: inline-block;
        align-text: center;
    }

    :first-child {
        border-left: 1px solid ${palette.mlcLightGrey};
        border-right: 1px solid ${palette.mlcLightGrey};
    }

    :not(:first-child):not(:last-child) {
        border-left: 1px solid ${palette.mlcLightGrey};
        border-right: 1px solid ${palette.mlcLightGrey};
    }

    :last-child {
        border-bottom: 1px solid ${palette.mlcLightGrey};
        border-left: 1px solid ${palette.mlcLightGrey};
        border-right: 1px solid ${palette.mlcLightGrey};
    }
`;

export const PlayAreaHeader = styled.div`
    display: flex;
    padding: 20px 0;
    justify-content: center;
    text-align: center;
    * {
        color: white;
        font-size: 18px;
        font-weight: bold;
    }
`;

export const InputAndText = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(min-content, max-content));
    grid-gap: 10px;
    margin: 25px 10px 0 10px;
    align-items: center;

    input {
        margin: 0 !important;
    }

    span {
        white-space: nowrap;
    }

    @media (max-width: ${ScreenSize.small}) {
        display: flex;
    }
`;

export const PlayAreaAge = styled(NumberInput)`
    display: flex;
    justify-content: end;
`;

export const PlayAreaRiskProfileSelect = styled(Select)`
    .Select {
        white-space: pre-wrap;
        height: fit-content;
        text-overflow: ellipsis;
        margin: 0;
    }
    ${props => props.disabled ? `
        svg {
            visibility: hidden;
        }
        select:hover {
            border: 1px solid red;
            box-shadow : 0 0 4px red;
        }
    ` : ''}
`;

export const PlayAreaRiskProfileButton = styled(TertiaryButton)`
    width: 100%;
    margin-bottom: 15px;
    font-size: 17px;

    @media (max-width: ${ScreenSize.small}) {
        width: fit-content;
        height: fit-content;
        padding: 0;   
        margin-top: 15px;
        margin-bottom: 0;
    }
`;

export const PlayReviewAccordion = styled(Accordion)`
    width: 100%;
    border: none;
    margin-bottom: 15px;

    .content {
        border: 1px solid ${palette.mlcLightGrey};
        padding: 5px 10px;
    }

    .Accordion__icon {
        display: flex;
        align-items: center;

        &[hidden] {
            display: none;
        }
    }

    button[class*="Accordionstyle__AccordionButton"] {
        background-color: ${themeGet('colors.detailsBackground')};
        padding-top: 10px;
        padding-bottom: 10px;
        border: 1px solid ${palette.mlcAlmostWhite};
    }
`;

export const PlayAreaMobileContainer = styled.div`
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
`;

export const PlayAreaMobileSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
`;

export const PlayAreaMobileSectionHeader = styled.div`
    padding: 10px;
    color: white;
    font-weight: bold;
    text-align: center
    background-color: ${themeGet('colors.brandPrimaryDark')};
`;

export const PlayAreaMobileSectionContent = styled.div`
    padding: 10px;
    background-color: ${themeGet('colors.detailsBackground')};
    border: 1px solid ${palette.mlcLightGrey};
    border-top: 0;
`;

export const PlayAreaMobileSectionInput = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    img {
        width: auto;
        margin-right: 15px;
        max-height: 60px;
    }
    
    .FieldWrapper {
        flex-grow: 1;
    }
`;