enum riskProfileTestIds {
    riskProfileSelect = 'risk-profile-select',
    maintainCurrentInvestments = 'maintain-current-investments',
    investmentProfileType = 'investment-profile-type',
    suggestedProfileType = 'suggested-profile-type',
    calculatedAssumedReturn = 'calculated-assumed-return',
    suggestedAssumedReturn = 'suggested-assumed-return',
    calculatedChanceOfLoss = 'calculated-chance-of-loss',
    suggestedChanceOfLoss = 'suggested-chance-of-loss',
    calculatedAllocation = 'calculated-allocation',
    suggestedAllocation = 'suggested-allocation',
    calculatedRiskTolerance = 'calculated-risk-tolerance',
    suggestedRiskTolerance = 'suggested-risk-tolerance',
    exploreRiskProfile = 'explore-risk-profile',
    redoQuestionnaire = 'redo-questionnaire',
    questionnaireRiskProfile = 'questionnaire-risk-profile',
    questionnaireModal = 'questionnaire-modal',
    riskRetireIn = 'risk-retire-in',
    riskNegativeReturns = 'risk-negative-returns',
    riskLowRiskInvestments = 'risk-low-risk-investments',
    riskLongTermDecline = 'risk-long-term-decline',
    riskFluctuations = 'risk-fluctuations',
    assumedAnnualReturn = 'assumed-annual-return',
    chanceOfLoss = 'chance-of-loss',
    allocation = 'allocation',
    riskTolerance = 'risk-tolerance',
    altAllocationTooltip = 'alt-allocation-tooltip',
    questionnaireAssumedAnnualReturn = 'questionnaire-assumed-annual-return',
    questionnaireMinimumTime = 'questionnaire-minimum-time',
    questionnaireAllocationGrowth = 'questionnaire-allocation-growth',
    questionnaireAllocationDefensive = 'questionnaire-allocation-defensive',
    questionnaireRiskTolerance = 'questionnaire-risk-tolerance',
    skipRiskProfile = 'skip-risk-profile',
    riskProfileExpand = 'risk-profile-expand',
    yourInvestmentStrategyGraphContainer = 'investment-strategy-graph-container',
    getRPButton = 'get-risk-profile-button',
    investmentPreferenceError = 'investment-preference-error',
    passiveManagementContent = 'passive-management-content',
    activeManagementContent = 'active-management-content',
    passiveManagementButton = 'passive-management-button',
    activeManagementButton = 'active-management-button',
    tooltipPDSLink= 'tooltip-pds-link',
    RPNextButton = 'risk-profile-next-button',
    investmentPreferenceTooltip = 'investment-preference-tooltip',
    newRPContainer = 'new-rp-container',
    maintainRPContainer = 'maintain-rp-container'
}

export default riskProfileTestIds;