import { Card, ToggleButton, ToggleButtonGroup, FieldWrapper, Input } from '@mlc/web-ui-toolkit';
import { Paragraph } from '@mlc/web-ui-toolkit';
import styled from 'styled-components';
import { ScreenSize } from '../../../../enums';
import { themeGet } from 'styled-system';

export const NPSButton = styled.button`
    background-color: transparent;
    cursor: pointer;
    border: 1px solid transparent;
    margin: 0 10px 0 10px;
`;

export const NPSContainer = styled(Card)`
    left: 50%;
    margin-left: -50vw;    
    width: 100vw;
    background: linear-gradient(white, ${themeGet('colors.detailsBackground')});
`;

export const NPSScoreGroup = styled(ToggleButtonGroup)`
    margin-top: 10px;
    grid-gap: 10px;

    @media (max-width: ${ScreenSize.small}) {
        margin-top: 25px;
    }

    @media (max-width: ${ScreenSize.xSmall}) {
        justify-content: center;
        flex-wrap: wrap;
        grid-gap: 5px;
    }
    .ToggleButton--isChecked {
        .ToggleButton__label {
            background-color: ${themeGet('colors.brandPrimaryLight')} !important;
            border: none !important;
        }
    }
`;

export const NPSScoreButton = styled(ToggleButton)`

    label {
        font-weight: bold;
        border-radius: 50%;
    }
    
    @media (max-width: ${ScreenSize.xSmall}) {
        width: 15%;
    }

    @media (max-width: ${ScreenSize.small}) and (min-width: ${ScreenSize.xSmall}) {
        flex-grow: 1;
    }

    .ToggleButton__label {        
        background: #e6e6e6;
        height: 45px;
        width: 45px !important;
        border: none;
        @media (max-width: ${ScreenSize.small}) {
            padding: unset;    
        }
        @media ( max-width: ${ScreenSize.xxxSmall}) {
            height: 38px;
            width: 38px !important;
        }
    }
`;
export const NPSScoreText = styled(Card)`
    margin-top: 15px !important;
    margin-bottom : -20px !important;
    display: flex;
    span {
        width: 100%;

        &:last-child {
            text-align: right;
        }
    }
`;

export const NPSScoreTextMobile = styled(Paragraph)`
    margin-top: 15px !important;
    font-size: 14px;
    margin-bottom : -20px !important;
    text-align: left !important;
`;

export const NPSScoreTextMobileBottom = styled(Paragraph)`
    font-size: 14px;
    text-align: right !important;
`;

export const NPSFieldWrapper = styled(FieldWrapper)`
    padding-top: 15px;
    text-align: left;
    margin-left: 125px;

    @media (max-width: ${ScreenSize.large}) {
        text-align: center;
        margin-left: 0px;
    }
    
    @media (min-width: ${ScreenSize.small}) {
        .FieldWrapper__body {
            display: inline-block;
        }
    }
`;

export const NPSIntroCard = styled(Card)`
    margin: -30px -30px 30px -30px;
    padding: 15px 30px 15px 30px;
    background: ${themeGet('colors.detailsBackground')};
    border-bottom: lightgrey 1px solid;
`;

export const StickyNPSFooter = styled.div`
    z-index: 99;
    position: sticky;
    bottom: 0;
    left: 50%;
    margin-left: -50vw;    
    width: 100vw;
    max-height: 0;
    overflow: hidden;
    &.isOpen {
        content-visibility: visible;
        max-height: 1500px;
    }
    
    &.forceClose {
        content-visibility: hidden;
    }

    transition: max-height 1.5s ease-in-out;
`;

export const NPSHeader = styled(Card)`
    left: 50%;
    margin-left: -50vw;    
    width: 100vw;
    background: ${themeGet('colors.brandPrimaryLight')};
    padding: 12px 0;
`;

export const NPSButtonRow = styled(Card)`
    margin: 15px 0;
    display: grid;
    grid-template-columns: repeat(2, minmax(min-content, max-content));
    grid-column-gap: 10px;
    place-content: end;
    
    @media (max-width: ${ScreenSize.xSmall}) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const CloseNps = styled.button`
    float: inline-end;
    background: transparent;
    rotate: 45deg;
    border: none;
    :hover {
        cursor: pointer;
    }
    path {
        fill: white;
    }
`;

export const NPSOtherFields = styled(Card)`
    overflow: hidden;
    max-height: 0;
    &.displayed {
        display: flex !important;
        max-height: 1000px;
    }
    transition: max-height .75s ease-in;
`;

export const DesktopScoreAndText = styled(Card)`
    padding: 0;
    display: grid;
    grid-gap: 15px;
    align-items: center;
    grid-template-columns: repeat(3,minmax(min-content, max-content));
`;

export const NPSRatingWrapper = styled(Card)`
    padding: 0 15px;
`;

export const NPSEmailInput = styled(Input)`
    width: 370px !important;
    @media (max-width: ${ScreenSize.xSmall}) {
        width: 100% !important;
    }
`;