import { Card, Paragraph } from '@mlc/web-ui-toolkit';
import React from 'react';
import investmentOptionImg from '../../../../assets/images/plum/icon-graph.png';
import contributionsImg from '../../../../assets/images/plum/icon-piggybank.png';
import coachImg from '../../../../assets/images/plum/financial-coach.png';
import takeActionTestIds from '../TakeAction.elements';
import { plumBookingUrl, plumContactNo } from '../../Common/StaticContent';

const takeAction = (productName: string) => ({
    switchUrl: () => "https://www.plumfs.com.au/member/Popups/switchStrategy/index.asp?action=update",
    closedInvestmentOption: `Your current investment strategy has an allocation to a closed investment option. Upon a full switch out of this investment option, you cannot later direct money back into it. The features of the closed investment option are detailed in the Product Disclosure Statement (PDS) you received when you became a member of your Plum Super account and are no longer detailed in the current PDS for the fund. For further information on the closed investment option you should refer to your Plum Super account member site or contact the Plum Super member centre on ${plumContactNo}.`,
    bookingUrl: plumBookingUrl,
    contactNo: plumContactNo,
    financialAdviceUrl: "https://www.plum.com.au/financial-advice",
    salarySacrificeLearnMore: "https://www.plum.com.au/grow-your-super/salary-sacrifice-contributions",
    personalDeductibleLearnMore: "https://www.plum.com.au/grow-your-super/member-voluntary-contributions",

    bpayInfo: (bpayNum: any) => ({
        title: "Contributing by BPAY® is easy",
        content: (
            <Card data-id={takeActionTestIds.bpayModalContent}>
                <Paragraph>If you haven't already used BPAY&reg;, it won't take long to learn. Simply follow these basic steps:</Paragraph>
                <ul>
                    <li>Obtain the biller code and your BPAY&reg; reference number.</li>
                    <li>Your BPAY&reg; reference number is {bpayNum}.</li>
                </ul>
                <Paragraph>Biller codes vary depending on the type of super contribution. The following are the biller codes for each contribution type that can be made via BPAY&reg; for your superannuation plan: </Paragraph>
                <ul>
                    <li><Card marginBottom="20px">37754 Plum Super - Personal contribution<br /> Note: Claiming a tax deduction and submitting a notice of intent to your Plum Super account is essential in order to treat it as a concessional contribution (before-tax)</Card></li>
                    <li>37762 Plum Super - Spouse contribution<br /> Spouse contributions are amounts that are contributed on your behalf by your spouse.</li>
                </ul>
            </Card>
        )
    }),

    investmentOptionImg: investmentOptionImg,
    contributionsImg: contributionsImg,
    coachImg: coachImg
})

export default takeAction;
