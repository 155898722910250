import React, { useRef, useState } from 'react';
import ModalVideo from 'react-modal-video';
import commonTestIds from './Common.elements';
import { ResponsiveSecondaryButton } from './Common.style';

type Props = {
    btnText?: string;
    videoUrl: string;
}

const ModalVideoPlayer = ({ btnText = "Watch now", videoUrl }: Props) => {
    const [showVideo, setShowVideo] = useState(false);
    const btnRef = useRef<any>();

    return (
        <>
            <ResponsiveSecondaryButton
                data-id={commonTestIds.modalVideoButton}
                ref={btnRef}
                type="button"
                onClick={() => setShowVideo(true)}
            >
                {btnText}
            </ResponsiveSecondaryButton>
            <ModalVideo
                isOpen={showVideo}
                onClose={() => {
                    setShowVideo(false);
                    setTimeout(() => {
                        btnRef.current && btnRef.current!.focus();
                    }, 100);
                }}
                channel="custom"
                url={videoUrl}
            />
        </>
    );
}

export default ModalVideoPlayer;