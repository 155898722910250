import takeAction from '../../components/presentational/TakeAction/TakeActionDetails/StaticContentPlum';
import importantInfo from '../../components/presentational/ImportantInfo/StaticContentPlum';
import retirementGoals from '../../components/presentational/CalculatorTab/CalculatorSteps/RetirementGoals/StaticContentPlum';
import regularContributions from '../../components/presentational/CalculatorTab/CalculatorSteps/RegularContributions/StaticContentPlum';
import aboutYou from '../../components/presentational/CalculatorTab/AboutYou/StaticContentPlum';
import superReport from '../../components/presentational/SuperReport/StaticContentPlum';
import error from '../../components/presentational/ErrorPage/StaticContentPlum';
import calculator from '../../components/presentational/CalculatorTab/StaticContentPlum';
import footer from '../../components/presentational/Footer/StaticContentPlum';
import riskProfile from '../../components/presentational/CalculatorTab/CalculatorSteps/RiskProfile/StaticContentPlum';
import retirementProjection from '../../components/presentational/CalculatorTab/CalculatorSteps/RetirementProjection/StaticContentPlum';

const plumContent = {
    takeAction,
    importantInfo,
    retirementGoals,
    regularContributions,
    aboutYou,
    superReport,
    error,
    calculator,
    footer,
    riskProfile,
    retirementProjection
}

export default plumContent;