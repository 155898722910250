import React from 'react';
import investmentOptionImg from '../../../../assets/images/mkbs/icon-graph.png';
import contributionsImg from '../../../../assets/images/mkbs/icon-piggybank.png';
import coachImg from '../../../../assets/images/mkbs/financial-coach.png';
import { mlcBookingUrl, mlcContactNo } from '../../Common/StaticContent';

const linkQueryString = (accountId: string) => `?accountId=${accountId}&productMid=35&customerId=`;

const takeAction = (productName: string) => ({
    closedInvestmentOption: `Your current investment strategy has an allocation to a closed investment option. Upon a full switch out of this investment option, you cannot later direct money back into it. The features of the closed investment option are detailed in the Product Disclosure Statement (PDS) you received when you became a member of your ${productName} account and are no longer detailed in the current PDS for the fund. For further information on the closed investment option you should refer to your ${productName} account member site or contact the ${productName} member centre on ${mlcContactNo}.`,

    switchUrl: (accountId: string) => `https://www.mlc.com.au/work/transaction/changeMyInvestments${linkQueryString(accountId)}`,
    contributionsUrl: (accountId: string) => `https://www.mlc.com.au/work/flow/addContributionTransaction-flow${linkQueryString(accountId)}`,
    bookingUrl: mlcBookingUrl,
    contactNo: mlcContactNo,
    financialAdviceUrl: "https://www.mlc.com.au/personal/advice/financial-advice",
    investmentOptionImg: investmentOptionImg,
    contributionsImg: contributionsImg,
    coachImg: coachImg,
    salarySacrificeLearnMore: "https://www.mlc.com.au/personal/superannuation/grow-your-super/salary-sacrifice-contributions",
    personalDeductibleLearnMore: "https://www.mlc.com.au/personal/superannuation/grow-your-super/member-voluntary-contributions",
})

export default takeAction;

