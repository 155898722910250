import * as palette from '@mlc/mlc-palette';
import { mlcLogo } from '@mlc/symbols';
import plumLogo from '../../../assets/images/plum/new-logo.png';
import { Card, Grid, Heading, ScreenReaderText, Svg } from "@mlc/web-ui-toolkit";
import { SvgProps } from '@mlc/web-ui-toolkit/lib/components/Svg/Svg';
import classnames from 'classnames';
import { toUpper } from "lodash";
import React, { useContext } from "react";
import { withTheme } from "styled-components";
import { CalculatorHeaderStyle } from "./CalculatorHeader.style";
import { AppThemeContext } from '../../../context/AppThemeContext';
import calculatorHeaderTestIds from './CalculatorHeader.elements';


type LogoProps = {
    theme?: any;
    width?: string | number;
    height?: string | number;
    /** See https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/preserveAspectRatio */
    preserveAspectRatio?: string;
};

const Logo = withTheme(
    ({ width, height, preserveAspectRatio }: LogoProps & SvgProps) => {
        const isMlc = useContext(AppThemeContext).isMlc;
        const themeName = isMlc ? 'mlc' : 'plum';

        return (
            <>
                <ScreenReaderText>{`${toUpper(themeName)} Homepage`}</ScreenReaderText>
                {isMlc ? (
                    <Svg
                        symbol={mlcLogo}
                        width={width}
                        height={height}
                        preserveAspectRatio={preserveAspectRatio}
                        alt="mlc logo"
                        data-uitk='Header__logo-mlc'
                        data-id={calculatorHeaderTestIds.headerLogoMlc}
                    />
                ) : (
                        <img
                            src={plumLogo}
                            alt="plum logo"
                            data-id={calculatorHeaderTestIds.headerLogoPlum}
                        />
                    )
                }
            </>
        );
    }
);


type HeaderProps = {
    headerText?: string;
    align?: string;
};

type Props = HeaderProps;
type State = {
    open: boolean;
};

class CalculatorHeader extends React.Component<Props, State> {
    static contextType = AppThemeContext;

    render() {
        const {
            headerText,
            align,
            ...restProps
        } = this.props;
        return (
            <CalculatorHeaderStyle
                {...restProps}>
                <Card className={classnames(`header__wrapper`, `theme-${this.context.isMlc ? 'mlc' : 'plum'}`)}>
                    <Card className="Header__mobile-nav">
                        <Card className={classnames('Header__panel')}>
                            <Grid>
                                <div
                                    className="Header__logo"
                                    data-uitk="Header__logo"
                                >
                                    <Logo
                                        width={'100%'}
                                        height={'60'}
                                        preserveAspectRatio="xMinYMax meet"
                                    />
                                </div>
                            </Grid>
                        </Card>
                    </Card>
                </Card>
                {headerText && (
                    <Card className="Header__text-banner" color={palette.mlcWhite} containerSize="thick" textAlign={align}>
                        <Grid>
                            <Heading el="h1" color={palette.mlcWhite}>
                                {headerText}
                            </Heading>
                        </Grid>
                    </Card>
                )}
            </CalculatorHeaderStyle>
        )
    }
}

//@ts-ignore
export default withTheme(CalculatorHeader);