import { Card, Checkbox, FieldWrapper, Text, TextLink } from "@mlc/web-ui-toolkit";
import React, { useRef, useState } from "react";
import { logAction } from "../../../logic/functions/logging.function";
import { NavButtonGroup } from "../Common/Common.style";
import { NextButton } from "../Common/NavButtons";
import importantInfoTestIds from "./ImportantInfoModal.elements";

type Props = {
    handleNextStep: () => void;
    accepted: any;
    setAccepted: (val: any) => void;
    content: any;
}

const AgreeTandC = ({ handleNextStep, accepted, setAccepted, content }: Props) => {
    const [agreeInformation, setAgreeInformation] = useState(accepted.tAndC || false);
    const [errorMsgTC, setErrorMsgTC] = useState(false);
    const agreeInfoRef = useRef<HTMLInputElement>();

    function setAgreeCheckBox() {
        setErrorMsgTC(false);
        logAction("ACCEPT_TANDC");
        setAgreeInformation((prevAgreeInformation: any) => !prevAgreeInformation);
        setAccepted({ tAndC: !agreeInformation });
    }

    const checkboxContent = [
        <>
            <Text>
                I have received the <TextLink target="_blank" rel="noopener" href={content.fsgLink} data-id={importantInfoTestIds.getStartedFinancialLink} onClick={() => logAction("WELCOME_FSG_CHECKBOX_LINK")}>Financial Services Guide</TextLink>
                , accept the <TextLink target="_blank" rel="noopener" href={content.termsAndConditionsLink} data-id={importantInfoTestIds.getStartedTermsLink} onClick={() => logAction("WELCOME_TERMS_CHECKBOX_LINK")}>Terms and conditions</TextLink>
                , <TextLink target="_blank" rel="noopener" href={content.assumptionsLink} data-id={importantInfoTestIds.getStartedAssumptionsLink} onClick={() => logAction("WELCOME_ASSUMPTIONS_CHECKBOX_LINK")}>Assumptions and limitations</TextLink> and I agree to receive the limited advice provided by the calculator.
            </Text>
        </>
    ]

    function clickStart() {
        setErrorMsgTC(!agreeInformation);
        if (!agreeInformation) {
            setTimeout(() => {
                agreeInfoRef.current && agreeInfoRef.current!.focus();
            }, 100);
        }

        if (agreeInformation) {
            logAction("NEXT_1");
            handleNextStep();
        }

    }

    return <>

        <Card padding="2px 15px" marginTop="15px">

            <FieldWrapper error="Please provide consent by selecting the checkbox." isInvalid={errorMsgTC && !agreeInformation}>

                <Checkbox
                    id="agree_information"
                    label={checkboxContent}
                    checked={agreeInformation}
                    onChange={setAgreeCheckBox}
                    ref={agreeInfoRef}
                    data-id={importantInfoTestIds.getStartedTermsCheckbox}
                />
            </FieldWrapper>

        </Card>

        <NavButtonGroup>
            <NextButton showArrow={false} width="15% !important" marginTop='15px' onClick={clickStart} data-id={importantInfoTestIds.getStartedStart}>Start</NextButton>
        </NavButtonGroup>
    </>;
}

export default AgreeTandC;

