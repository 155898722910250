import { Heading } from '@mlc/web-ui-toolkit';
import React from 'react';
import commonTestIds from './Common.elements';
import { PageIndicatorContainer } from './Common.style';

type Props = {
    currentPage: number;
    length: number;
    pageType: string;
}

const PageIndicator = ({pageType, currentPage, length}: Props) => {
    return (
        <PageIndicatorContainer textAlign="right" marginBottom={2}>
            <Heading el="span" fontSize="tiny" data-id={commonTestIds.pageIndicator}>{pageType} {currentPage} of {length}</Heading>
        </PageIndicatorContainer>
    )
};

export default PageIndicator;