import projectionImg from '../../../assets/images/plum/icon-graph.png';
import contributionsImg from '../../../assets/images/plum/icon-piggybank.png';
import umbrellaImg from '../../../assets/images/plum/icon-retirement.png';
import onTrackImg from '../../../assets/images/plum/graph.png';
import reportImg from '../../../assets/images/plum/super-report.png';
import KateImg from '../../../assets/images/plum/kate.png';
import { plumContactNo } from '../Common/StaticContent';

const welcome = {  
    contactNo: plumContactNo,
    assumptionsLink: "https://www.plumfs.com.au/links/oats/assumptions",
    assumptionsText: "Assumptions and limitations (PDF, 97KB)",
    termsAndConditionsLink: "https://www.plumfs.com.au/links/oats/terms-conditions",
    termsAndConditionsText: "Terms and conditions (PDF, 65KB)",
    fsgText: "Financial Services Guide (PDF, 198KB)",
    fsgLink: "https://www.plumfs.com.au/links/oats/fsg",
    
    retirementGoalImg: umbrellaImg,
    riskProfileImg: projectionImg,
    contributionsImg: contributionsImg,
    onTrackImg: onTrackImg,
    getReportImg: reportImg,

    kateImg: KateImg,
}

export default welcome;