import { RiskProfileResponse, RiskProfile } from '../domains/riskProfile.domain';
import { post } from './fetch';
import { convertQuestionnaireValues, errorInResponse, redirectToError } from './util';

export const getRiskProfile = async (questionnaireValues: number[]): Promise<RiskProfile> => {
    const apiValues = convertQuestionnaireValues(questionnaireValues);
    const data = await post(`/members/riskProfile`, apiValues) as Promise<RiskProfileResponse>;
    if (errorInResponse(await data)) {
        redirectToError(500);
    }
    return (await data).response as RiskProfile;
}