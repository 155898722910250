import React, { useContext } from 'react';
import { Card, Grid, Row, Col, Text, Heading, Paragraph } from '@mlc/web-ui-toolkit';
import {
	SecondaryNav,
	FooterLink,
	InjectedFooterText,
	FooterCard,
	ContactImg,
	FooterInnerCard
} from './Footer.style';
import { opensInNewWindow } from '../../../logic/functions/util';
import { AppThemeContext } from '../../../context/AppThemeContext';
import { ThemeContext } from 'styled-components';
import NewWindowLink from '../Common/NewWindowLink';
import { logAction } from '../../../logic/functions/logging.function';
import footerTestIds from './Footer.elements';

export const Footer = () => {
	const content = useContext(AppThemeContext).staticContent.footer;
	const isMlc = useContext(AppThemeContext).isMlc;
	const themeContext = useContext(ThemeContext);
	const theme = themeContext.name;

	return (
		<footer>
			<FooterCard className="contact" paddingTop={4} paddingBottom={4}>
				<Grid>
					<FooterInnerCard className="needHelp">
						<ContactImg src={content.contactImg} alt=""/>
						<div>
							<Heading el="h2" fontWeight="semibold" fontSize="little">Need some help?</Heading>
							<Paragraph>
								Getting help at no additional cost is all part of being with {isMlc ? 'MLC' : 'Plum'}.<br/>
								<NewWindowLink id="footer-booking" href={content.bookingUrl} onClick={() => logAction("FOOTER_BOOKING")} data-id={footerTestIds.footerBooking}>Book an appointment</NewWindowLink> with one of our Financial Coaches or call {content.contactNo} (9.00am - 5.30pm, Monday to Friday AEST/AEDT).
							</Paragraph>
						</div>
					</FooterInnerCard>
				</Grid>
			</FooterCard>
			<FooterCard className={theme}>
				<Grid>
					<Row>
						<Col>
							<FooterInnerCard paddingTop={4}>
								<SecondaryNav>
									{content.linksItems.map(({ pageurl, title, includeNewTabMsg, onClick, id }, index) => (
										<FooterLink
											href={pageurl}
											target="_blank"
											rel="noopener"
											key={pageurl + index}
											onClick={onClick}
											className={theme}
											data-id={id}
										>
											<Text fontSize="disclaimer" color="inherit">{title}</Text>
											{includeNewTabMsg && <>{opensInNewWindow}</>}
										</FooterLink>
									))}
								</SecondaryNav>
							</FooterInnerCard>
						</Col>
					</Row>
					<Row>
						<Col>
							<Card paddingBottom={6}>
								<InjectedFooterText className={theme}>
									{content.legal}
								</InjectedFooterText>
							</Card>
						</Col>
					</Row>
				</Grid>
			</FooterCard>
		</footer>
	);
};

export default Footer;
