import * as palette from '@mlc/mlc-palette';
import { Paragraph, Text } from '@mlc/web-ui-toolkit';
import React from 'react';
import { RiskProfileMixType, Volatility } from '../../../../../enums';
import { asRoundedCurrency } from '../../../../../logic/functions/formatter';
import riskProfileTestIds from './RiskProfile.elements';

export const pages = [
    {
        question: "When do you plan to fully retire?",
        tooltipContent: "This question is to confirm how long your money will be invested before you need to access it.",
        tooltipLog: "RP_Q1_TT",
        values: [
            { value: "0", label: "Less than 1 year"},
            { value: "1", label: "Between 1 and 5 years"},
            { value: "2", label: "Between 5 and 9 years"},
            { value: "3", label: "10 years or more"},
        ],
        id: riskProfileTestIds.riskRetireIn,
        formField: "riskRetireIn",
        defaultValue: null
    },
    {
        question: "If you could increase your chance of having more in super at retirement by taking more risk, would you?",
        tooltipContent: "This question is about deciding if it is more important to you to try to achieve your retirement savings goal or to manage the ups and downs (volatility) of your super along the way.",
        tooltipLog: "RP_Q2_TT",
        values: [
            { value: "0", label: "I avoid risk at all cost"}, 
            { value: "1", label: "I'm uncomfortable taking risk"},
            { value: "2", label: "I'm OK with taking risk to achieve a higher super balance at retirement"},
            { value: "3", label: "I'm comfortable with risk and want to maximise my super balance when I retire"},
        ],
        id: riskProfileTestIds.riskLowRiskInvestments,
        formField: "riskLowRiskInvestments",
        defaultValue: null
    },
    {
        question: "Generally, I prefer an investment with little to no ups and downs in value and I'm willing to accept the lower returns these investments make.",
        tooltipContent: "There is a trade-off between the level of risk you're willing to take with your money and the returns you could achieve. This question is to find out which one is more important to you - risk or returns.",
        tooltipLog: "RP_Q3_TT",
        values: [
            { value: "0", label: "Strongly agree"},
            { value: "1", label: "Agree"},
            { value: "2", label: "Disagree"},
            { value: "3", label: "Strongly disagree",}
        ],
        id: riskProfileTestIds.riskNegativeReturns,
        formField: "riskNegativeReturns",
        defaultValue: null,
    },
    {
        question: "",
        tooltipContent: "This question is about your risk tolerance and your ability to stay invested even when markets fall.",
        tooltipLog: "RP_Q4_TT",
        values: [
            { value: "0", label: "Very concerned"},
            { value: "1", label: "Concerned"},
            { value: "2", label: "It's not great, but I can live with it"},
            { value: "3", label: "I'm OK if my money goes up and down in the short term"},
        ],
        id: riskProfileTestIds.riskLongTermDecline,
        formField: "riskLongTermDecline",
        defaultValue: null
    },
    {
        question: "To avoid inflation reducing your super savings you need to consider taking on more risk in how you invest your money, but this can lead to more ups and downs (volatility) with the returns you receive.",
        tooltipContent: (
            <>
            <Text>Inflation is the rise in the cost of everyday goods.<br></br><br></br>This question is to determine your comfort with investing your money in more volatile assets like shares to reduce the risk of your money being reduced by inflation.</Text>
            </>
        ),
        tooltipLog: "RP_Q5_TT",
        values: [
            { value: "0", label: "I prefer minimal volatility knowing this will result in lower returns that don't keep up with inflation", },
            { value: "1", label: "I prefer low volatility but want my money to keep up with inflation"},
            { value: "2", label: "I'm OK with modest volatility to try to get higher returns to outpace inflation over the long term"},
            { value: "3", label: "I'm OK with extreme volatility to try to get the highest returns over the long term"},
        ],
        id: riskProfileTestIds.riskFluctuations,
        formField: "riskFluctuations",
        defaultValue: null
    }
]

export const maintainOption = { value: "Maintain", label: "Maintain current investments" };

export const riskProfileValues = (suggested: string) => ([
    { value: RiskProfileMixType.Cash, label: "Cash" },
    { value: RiskProfileMixType.Conservative, label: "Conservative" },
    { value: RiskProfileMixType.Moderate, label: "Moderate" },
    { value: RiskProfileMixType.Balanced, label: "Balanced" },
    { value: RiskProfileMixType.Growth, label: "Growth" },
    { value: RiskProfileMixType.High_Growth, label: "High Growth" }
].map(val => val.value === suggested ? { ...val, label: `${val.label} (recommended)`} : val));

export const riskProfileDescription: any  = {

    [RiskProfileMixType.High_Growth] : {
        description: (<>
                        <Paragraph>You like to live life in the fast lane and don't mind hitting a few bumps along the way.</Paragraph>
                        <Paragraph>You're comfortable that your investments may significantly rise or fall in value at any point in time and that's okay for you because it means you might achieve higher returns over the long term.</Paragraph>
                        <Paragraph>This type of investment style would invest all your money into growth assets like shares.</Paragraph>
                        <Paragraph>A high growth investor's portfolio has the following features:</Paragraph>
                    </>),
        investmentTimeframe: "7+ years",
    },

    [RiskProfileMixType.Growth] : {
        description: (<>
                        <Paragraph>You're a confident investor who is comfortable to ride the highs and lows of the market to achieve your end goal.</Paragraph>
                        <Paragraph>You want to try to achieve higher returns over the long term and you don't lose any sleep over your money going up and down in value in the short term.</Paragraph>
                        <Paragraph>This type of investment style would invest in mostly growth assets like shares.</Paragraph>
                        <Paragraph>A growth investor's portfolio has the following features:</Paragraph>
                    </>),
        investmentTimeframe: "5-7 years",
    },

    [RiskProfileMixType.Balanced] : {        
        description: (<>
                        <Paragraph>You're comfortable taking some risks to grow your money but you always like to have a safety net.</Paragraph>
                        <Paragraph>You're someone looking for potentially higher returns over the long-term and you're willing to accept some short-term volatility to get there.</Paragraph>
                        <Paragraph>This type of investment style would invest in mostly growth assets like shares with some investment in defensive assets like cash.</Paragraph>
                        <Paragraph>A balanced investor's portfolio has the following features:</Paragraph>
                    </>),
        investmentTimeframe: "5 years",
    },

    [RiskProfileMixType.Moderate] : {        
        description: (<>
                        <Paragraph>You like to sit on the fence and get the best of both worlds.</Paragraph>
                        <Paragraph>You're OK with taking some risk with half of your money to try to get higher returns over the long term, but you try to balance that risk by being more protective with the other half of your money.</Paragraph>
                        <Paragraph>This type of investment style would invest in an equal balance of growth assets like shares and defensive assets like cash.</Paragraph>
                        <Paragraph>A moderate investor's portfolio has the following features:</Paragraph>
                    </>),
        investmentTimeframe: "3-5 years",
    }, 

    [RiskProfileMixType.Conservative] : {        
        description: (<>
                        <Paragraph>Protecting your nest egg is very important to you because you like to have some certainty in your life.</Paragraph>
                        <Paragraph>You prefer to experience a small amount of investment volatility and as a trade-off the value of your investments will be relatively stable over time.</Paragraph>
                        <Paragraph>This type of investment style would invest in mostly defensive assets like cash and fixed interest.</Paragraph>
                        <Paragraph>A conservative investor's portfolio has the following features:</Paragraph>
                    </>),
        investmentTimeframe: "3 years",

    },

    [RiskProfileMixType.Cash] : {        
        description: (<>
                        <Paragraph>When you hear the word 'risk' it can feel scary, so instead you like to play it safe with your money.</Paragraph>
                        <Paragraph>You don't feel comfortable with your money going up and down in value every day and as a trade-off the value of your investments will be relatively stable over time.</Paragraph>
                        <Paragraph>This type of investment style would invest all your money in defensive assets like cash.</Paragraph>
                        <Paragraph>A cash investor's portfolio has the following features:</Paragraph>
                    </>),
        investmentTimeframe: ">3 years",
    }

}

export const riskProfileVolatilityValues = {
    [Volatility.VeryLowRisk]: {
        tolerance: "Very low",
        toleranceColour: palette.mlcAqua
    },
    [Volatility.LowToMediumRisk]: {
        tolerance: "Low to medium",
        toleranceColour: palette.mlcAqua
    },
    [Volatility.MediumRisk]: {
        tolerance: "Medium",
        toleranceColour: palette.mlcWarning
    },
    [Volatility.MediumToHighRisk]: {
        tolerance: "Medium to high",
        toleranceColour: palette.mlcDeepOrange
    },
    [Volatility.HighRisk]: {
        tolerance: "High",
        toleranceColour: palette.mlcError
    },
    [Volatility.VeryHighRisk]: {
        tolerance: "Very high",
        toleranceColour: palette.mlcError
    },
}

const allocations: any = {
    [RiskProfileMixType.Growth]: {
        name: "Growth",
        growth: "85%",
        defensive: "15%"
    },
    [RiskProfileMixType.Balanced]: {
        name: "Balanced",
        growth: "70%",
        defensive: "30%"
    }
}

export const superLoss = (memberSuper: number, memberName : String) => `${memberName}, you currently have ${asRoundedCurrency(memberSuper)} in your super, how would you feel if this dropped 10% to ${asRoundedCurrency((memberSuper - (memberSuper)*0.1))} in a year?`;

export const alternativeAllocationTooltip = (riskProfile: RiskProfileMixType) => `The ${allocations[riskProfile].name} risk profile displays an asset allocation summary that varies from our standard target for this risk profile of ${allocations[riskProfile].growth} growth / ${allocations[riskProfile].defensive} defensive. This is because the proposed investment option for this risk profile changes asset allocation based on your age, which will affect the assumed return used in the projection. See the Assumptions and Limitations for further information.​`;
