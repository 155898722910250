import { Modal, Card, Text, Accordion, Heading } from '@mlc/web-ui-toolkit';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import { ScreenSize } from '../../../enums';
import * as palette from '@mlc/mlc-palette';

export const WelcomeAccordion = styled(Accordion)`
    Button{
        background-color: white;
    }
    background-color: white;
    border-top: thin solid ${palette.mlcLightGrey};

    .Accordion__icon {
        display: flex;
        align-items: center;

        &[hidden] {
            display: none;
        }
    }
`;

export const AccordionText = styled(Card)`
    background-color: white;
    overflow: hidden;

    .rightForYou {
        overflow-wrap: normal;
    }
`;

export const ImportantInfo = styled(Card)`
    padding: 5px 10px 0px 10px;
    overflow: hidden;
    @media (max-width: ${ScreenSize.small}){
        margin: 5px -10px 0px;
    }
`;

export const LastWelcomeAccordion = styled(Accordion)`
    Button{
        background-color: white;
    }
    background-color: white;
    border-top: thin solid ${palette.mlcLightGrey};
    border-bottom: thin solid ${palette.mlcLightGrey};

    .Accordion__icon {
        display: flex;
        align-items: center;

        &[hidden] {
            display: none;
        }
    }
`;

export const ConsentCard = styled(Card)`
    margin-top: 30px;
    padding: 15px;
    background-color: ${themeGet('colors.secondaryBackground')};
`;

export const KateHeading = styled(Heading)`
    font-size: 18px; 
    margin-left: 20px;
    line-height: 22px;
`;

export const WelcomeWarning = styled.div`
    
`;

export const WelcomePhoneNumber = styled.span`
    white-space: nowrap;
`;

export const InitialPopupModal = styled(Modal)`
    .modalHeader {
        hidden: true;
    }

    &__reactModal {
        top: auto;
        width: 941px !important;
        max-width: 941px !important;
        max-height: 100%;

        .modalContentContainer {
            margin: 0px;
        }

        @media (max-width: ${ScreenSize.medium}) {
            width: 90vw !important;
            max-width: 90vw !important;
        }

        overflow-x: hidden;
    }
    div[class*= brandColor] {
        text-align: left;
        button {
            visibility: hidden;
        }
    }
`;

export const ImportantUpdateCard = styled(Card)`
    padding: 10px 10px;
    margin: 5px 10px;
    border: 1px solid ${palette.mlcLightGrey};
    background-color: ${themeGet('colors.detailsBackground')};
`;