import { Card, Paragraph } from '@mlc/web-ui-toolkit';
import React from 'react';
import { mlcBookingUrl, mlcContactNo } from '../Common/StaticContent';
import NewWindowLink from '../Common/NewWindowLink';
import { logAction } from '../../../logic/functions/logging.function';

const error = {
    error500: (
        <>  
        <Card>
            <Paragraph>
                The Personal Super Calculator is currently unavailable. If you need to contact us immediately, please phone us 
                on <b>{mlcContactNo}</b> or try accessing the site again shortly. We apologise for any inconvenience.
            </Paragraph>
        </Card>
        </>
    ),

    error515: (
        <>
        <Card>
            <Paragraph>
                Your super's asset allocation currently exceeds 100% growth assets. As a result, it is not appropriate for us to provide you with a projection or investment advice through the Personal Super Calculator.<br/><br/>
                Our team of financial coaches can help you with any questions you may have, at no additional cost.<br/><NewWindowLink href={mlcBookingUrl} onClick={() => logAction("GROWTH_TRIAGE_BOOKING")}>Book an appointment</NewWindowLink>.<br/><br/>
                You should contact us on <b>{mlcContactNo}</b> for further help.
            </Paragraph>
        </Card>
        </>
    )
}

export default error;