import { Card, PageHeader, ProgressBar } from '@mlc/web-ui-toolkit';
import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

export const NavBarStyle = styled(Card)`
	display: flex;	
	flex-direction: column;
	flex: 1;
	position: relative;
	justify-content: center;
`;

export const NavHeader = styled(PageHeader)`
	background-color: ${themeGet('colors.brandPrimaryLight')};
`;

export const NavProgress = styled(ProgressBar)`
	background-color: ${themeGet('colors.brandPrimaryLight')};
	padding-top: 20px;

	progress.ProgressBar__bar, progress.ProgressBar__bar::-webkit-progress-bar {
		background-color: ${themeGet('colors.progressBarBackground')};
	}
`;

export const ProgressBarCard = styled(Card)`
    background-color: ${themeGet('colors.brandPrimaryLight')};
	padding-top: 10px;
`;

export const ProgressBarDiv = styled.div`
    padding: 20px 10px 15px;

	.RSPBprogressBar {
		background-color: ${themeGet('colors.progressBarBackground')};
	}

    .indexedStep {
        color: ${themeGet('colors.brandPrimaryLight')};
        width: 30px;
        height: 30px;
        font-size: 16px;
		font-weight: 600; 
        background-color: ${themeGet('colors.progressBarBackground')};
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .indexedStep.accomplished {
    	background-color: white;
    }
`;
