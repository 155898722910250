import React, { useContext } from "react";
import { Grid, Card, Paragraph } from '@mlc/web-ui-toolkit';
import { ErrorType } from '../../../enums';
import { AppThemeContext } from "../../../context/AppThemeContext";
import CalculatorHeader from "../CalculatorHeader";

type Props = {
    error: ErrorType;
};

const ErrorPage = ({ error }: Props) => {
    const content = {
        error100: (
            <Paragraph>
                We have experienced a technical difficulty and we are unable to produce and issue you with a Statement of
                Advice. Accordingly, you should <u>not</u> take any action based on any information or statements displayed to
                you in the calculator. Please shut down all screens and re-start the calculator from the beginning.
            </Paragraph>
        ),
        error401: (
            <Card>
                <Paragraph>There is a problem with the page you are trying to reach and it cannot be displayed.</Paragraph>
                <Paragraph>This could be due to one of the following reasons:</Paragraph>
                <ul className="ul_401">
                    <li>Page is unavailable</li>
                    <li>Unauthorised access</li>
                </ul>
                <Paragraph>Please return to the homepage and try again.</Paragraph>
            </Card>
        ),
        error404: (
            <Card>
                <Paragraph>Sorry, we can't find the page or file you're looking for. Please return to the homepage and try again.</Paragraph>
            </Card>
        ),
        ...useContext(AppThemeContext).staticContent.error
    };

    let errorMsg = (content as any)[ErrorType[error]];

    return (
        <>
            <CalculatorHeader headerText={error !== 515 ? "Oops! Something went wrong." : "Contact us"} align="center" />
            <Grid>
                <Card marginTop="50px" marginBottom="50px" textAlign="center" display="block">
                    {errorMsg}
                </Card>
            </Grid>
        </>
    )
}

export default ErrorPage;
