import React from 'react';
import { Text } from '@mlc/web-ui-toolkit';
import NewWindowLink from '../Common/NewWindowLink';
import { logAction } from '../../../logic/functions/logging.function';
import superReportTestIds from './SuperReport.elements';
import docImg from '../../../assets/images/plum/super-report.png';
import soaImg from '../../../assets/images/plum/icon-doc.png';

const superReport = {
    adviceOptions: [
        "You must read the Statement of Advice before acting on our advice.",
        <Text>You can also access this document from the 'Statements of advice' section (within <NewWindowLink href="https://www.plumfs.com.au/links/oats/statements-documents" onClick={() => logAction("SOA_DOCUMENTS_PAGE_LINK")} data-id={superReportTestIds.soaDocumentsPageLink}>Statements & documents</NewWindowLink>) when logged into your super account.</Text>,
        "Check that the information we have about you is complete and accurate. If you think that some relevant information is missing or incorrect, you should not act on any information or recommendations in the Statement of Advice.",
        "Laws and circumstances relevant to our advice may change. If you choose to act on our advice at a later date, make sure you check that the advice in the Statement of Advice is current before doing so.",
        <Text>Please refer to the Product Disclosure Statement (within <NewWindowLink href="https://www.plumfs.com.au/links/oats/statements-documents" onClick={() => logAction("PDS_CLICK")} data-id={superReportTestIds.soaPdsLink}>Statements & documents</NewWindowLink>) for full product details.</Text>
    ],
    adviceHeaderImg: docImg,
    soaImg
}

export default superReport;