import * as React from "react";

type ImageProps = {
    alt: string,
    src: string,
    htmlWidth?: string | number,
    htmlHeight?: string | number
}

export const Image: React.FunctionComponent<ImageProps & React.HTMLAttributes<HTMLImageElement>> = ({ alt, src, htmlWidth, htmlHeight, ...restProps }) => {
    return <img alt={alt} src={src} width={htmlWidth} height={htmlHeight} {...restProps} />;
}
