import coffee from '../../../../../assets/images/mkbs/coffee.png';
import piggyBank from '../../../../../assets/images/mkbs/icon-piggybank.png';


const regularContributions = (productName: string) => ({
    currentTooltip: `The Current column includes any current contributions you have already made to your ${productName} account this financial year.`,
    proposedTooltip: `The Proposed column does not include any current contributions that you have already made this financial year. If you entered an amount above, we have used this as the total amount that you can afford to contribute from your take home pay to your ${productName} account.`,
    coffee: coffee,
    piggyBank: piggyBank,
})

export default regularContributions;