enum retirementGoalsTestIds {
    retireAgeInput = 'retire-age-input',
    retireAgeInfo = 'retire-age-info',
    superAgeInput = 'super-age-input',
    superAgeInfo = 'super-age-info',
    retireIncomeInput = 'retire-income-input',
    retireIncomeTooltip = 'retire-income-tooltip',
    retirementStandardsLinkModest = 'retirement-standards-link-modest',
    retirementStandardsLinkComfortable = 'retirement-standards-link-comfortable',
    retirementStandardsLinkModestExpand = 'retirement-standards-link-modest-expand',
    retirementStandardsLinkComfortableExpand = 'retirement-standards-link-comfortable-expand',
    retirementStandardsLink = 'retirement-standards-link',
    retirementCalculatorLink = 'retirement-calculator-link',
    agePensionGroup = 'age-pension-group',
    agePensionYes = 'age-pension-yes',
    agePensionNo = 'age-pension-no',
    agePensionModal = 'age-pension-modal',
    homeownerGroup = 'homeowner-group',
    homeownerYes = 'homeowner-yes',
    homeownerNo = 'homeowner-no',
    homeownerError = 'homeowner-error',
    partnerGroup = 'partner-group',
    partnerYes = 'partner-yes',
    partnerNo = 'partner-no',
    partnerError = 'partner-error',
    partnerInfo = 'partner-info',
    partnerDob = 'partner-dob',
    partnerDobError = 'partner-dob-error',
    financialInvestments = 'financial-investments',
    nonFinancialAssets = 'non-financial-assets',
    financialInvestmentsInfo = 'financial-investments-info',
    nonFinancialAssetsInfo = 'non-financial-assets-info',
    partnerSuper = 'partner-super',
    partnerAgeFrom = 'partner-age-from',
    partnerAgeTo = 'partner-age-to',
    partnerEmploymentIncome = 'partner-employment-income',
    partnerEmploymentIncomeInfo = 'partner-employment-income-info',
    otherIncomeSelect = 'other-income-select',
    otherIncomeInput = 'other-income-input',
    otherIncomeInfo = 'other-income-info',
    addOtherIncome = 'add-other-income',
    estimatedAgePension = 'estimated-age-pension',
    agePensionStep = 'age-pension-step',
    agePensionOtherIncomeInfo = 'age-pension-other-income-info',
    agePensionOtherIncomeHelp = 'age-pension-other-income-help',
    retirementLifestyleExpand = 'retirement-lifestyle-expand',
    retirementIncomeFrequency = 'retirement-income-frequency',
    retirementLifestyleModerate = 'retirement-lifestyle-moderate',
    retirementLifestyleComfortable = 'retirement-lifestyle-comfortable',
    retirementLifestyleCustom = 'retirement-lifestyle-custom',
    retirementLifestyleExpandedContent = 'retirement-lifestyle-expanded-content'
}

export default retirementGoalsTestIds;