import { Card, Grid, Heading, Label, Paragraph, Row, Text, TextLink } from "@mlc/web-ui-toolkit";
import React, { useContext, useState } from "react";
import { AppThemeContext } from "../../../context/AppThemeContext";
import { logAction } from "../../../logic/functions/logging.function";
import { StyledOptionsList } from "../Common/Common.style";
import { toolDate } from "../Common/StaticContent";
import AgreeTandC from "./AgreeTandC";
import { LastWelcomeAccordion, WelcomeAccordion, ConsentCard, AccordionText, ImportantInfo, WelcomeWarning, WelcomePhoneNumber, InitialPopupModal, ImportantUpdateCard } from "./ImportantInfoModal.style";
import importantInfoModalTestIds from "./ImportantInfoModal.elements";

export interface Props {
    accepted: any;
    setAccepted: (val: any) => void;
    productName: string;
    modalOpen: boolean;
    handleNextStep: () => void;
}

export interface State {
    accordions: boolean[];
}

//calculator step 1 of 8
const ImportantInfoModal = (props: Props, state: State) => {

    const deSelectOthers = (arr: boolean[], num: number) =>
        arr.map((value: boolean, index: number) => (index === num));

    const deSelectSelf = (arr: boolean[], num: number) =>
        arr.map((value: boolean, index: number) => (false));
    
    // Always expand is this calculator right for you
    const currentlyExpanded = 3;

    const [accordions, setAccordions] = useState(deSelectOthers([ false, false, false, false ], currentlyExpanded));

    // Checks if and values of accordions is true on click, to allow for only 1 accordion to be open at a time
    
    const setExpanded = (num : number) => {
         setAccordions(current => {
            if(current[num] === true){
                return deSelectSelf(current, num)
            }
            else return deSelectOthers(current, num)
        })
    };
         

    const content = useContext(AppThemeContext).staticContent.importantInfo;
    const productName =useContext(AppThemeContext).isMlc ? props.productName : "Plum Super";
    const getStartedOptions = [
        "Projections are estimates only. As the future can be unpredictable, results are not guaranteed.",
        "All figures are in today's dollar terms. This means the effects of future inflation have been removed so you can relate the amount back to the purchasing power of your money today."
    ];

        const provides = [
            `advice on investment options within your ${productName} account.`,
            `advice on making regular contributions to your ${productName} account.`,
            `a projection of your super balance over time.`,
        ];

        let notProvides = [
            `topics outside your ${productName} account, including your insurance needs, understanding your cash flow and whether it's better to repay debts or save for future expenses.`,
            `your partner, or when you and/or your partner should retire, or your retirement benefit options.`,
            `any defined benefits or other super funds you may have outside of your ${productName} account.`,
            `complex contribution strategies such as catch-up or downsizer contributions.`,
        ];
        
        if (useContext(AppThemeContext).isMksf) {
            notProvides.push("NAB Term Deposits or MLC Investment Protection.");
        }

        const rightForYou = [
            `want advice on topics outside your ${productName} account, including your insurance needs, understanding your cashflow and whether it's better to repay debts or save for future expenses.`,
            `have made one-off contributions to your super account in this financial year and these contributions will not be ongoing.`,
            `have made non-concessional (after tax) contributions of greater than $120,000 in this financial year, or $110,000 in either of the previous two financial years.`,
            `or your partner are receiving income from other sources such as social security, a super pension or family trust.`,
            `are self-employed.`,
            `are a non-Australian resident for tax purposes.`,
        ];

        return (
            <>
                <InitialPopupModal title="Personal Super Calculator" isOpen={props.modalOpen} hideFooterButtons={true} align="left" shouldCloseOnOverlayClick={false}>
                    <Grid>
                        <ImportantInfo>
                            <Row>
                                <Heading data-id={importantInfoModalTestIds.importantInfoHeader} padding="5px 20px" fontSize="large" el="span" fontWeight="semibold">
                                    Important information before you start
                                </Heading>
                            </Row>
                            <WelcomeAccordion
                                title={<Label fontWeight="semibold" el="h2" marginTop="2.5px" marginBottom="2.5px">Limited advice provided by this calculator</Label>}
                                borderStyle="none"
                                data-id={importantInfoModalTestIds.noAdviceOnAccordion}
                                onChange={() => {
                                    logAction("NOT_PROVIDE_ADVICE_EXPAND");
                                    setExpanded(0)
                                }}
                                isOpen={accordions[0]}
                            ><AccordionText>
                                    <Grid>
                                        <Card marginBottom="30px" marginTop="10px" data-id={importantInfoModalTestIds.noAdviceOnContent}>
                                            <Text>The calculator provides:</Text>
                                            <StyledOptionsList options={provides} />
                                            <Text>The calculator does not provide advice on:</Text>
                                            <StyledOptionsList options={notProvides} />
                                            <WelcomeWarning>
                                                <p>These topics need a broader understanding of your situation. If you're seeking more comprehensive advice, you can call us on <WelcomePhoneNumber>{content.contactNo}.</WelcomePhoneNumber></p>
                                            </WelcomeWarning>
                                        </Card>
                                    </Grid>
                                </AccordionText>
                            </WelcomeAccordion>

                            <WelcomeAccordion
                                title={<Label fontWeight="semibold" el="h2" marginTop="2.5px" marginBottom="2.5px">Assumptions and limitations</Label>}
                                borderStyle="none"
                                data-id={importantInfoModalTestIds.assumptionsAccordion}
                                onChange={() => {
                                    logAction("ASSUMPTIONS_EXPAND");
                                    setExpanded(1)
                                }}
                                isOpen={accordions[1]}
                            >
                                <AccordionText data-id={importantInfoModalTestIds.assumptionsContent}>
                                    <Grid>
                                        <Card marginBottom="30px" marginTop="10px">
                                            <Text>
                                                The calculator provides projections based on assumptions, limitations and the information you provide.
                                            </Text>
                                            <StyledOptionsList options={getStartedOptions} />
                                            <Text>
                                                View the <TextLink target="_blank" rel="noopener" href={content.assumptionsLink} data-id={importantInfoModalTestIds.accordionAssumptionsLink} onClick={() => logAction("ASSUMPTIONS_CLICK")}>{content.assumptionsText}</TextLink>.
                                                The calculator is current as of {toolDate} and is subject to change without notice.
                                            </Text>
                                        </Card>
                                    </Grid>
                                </AccordionText>
                            </WelcomeAccordion>
                            <WelcomeAccordion
                                title={<Label fontWeight="semibold" el="h2" marginTop="2.5px" marginBottom="2.5px">Terms and conditions</Label>}
                                borderStyle="none"
                                data-id={importantInfoModalTestIds.tAndCAccordion}
                                onChange={() => {
                                    logAction("T_AND_C_EXPAND");
                                    setExpanded(2);
                                }}
                                isOpen={accordions[2]}
                            >
                                <AccordionText data-id={importantInfoModalTestIds.tAndCContent}>
                                    <Grid>
                                        <Card marginBottom="30px" marginTop="10px">
                                            <Paragraph>The Personal Super Calculator asks a number of questions about your circumstances to provide you with personal advice, which will be set out in a Statement of Advice (SOA). You should not rely on any advice provided by the calculator unless it is set out in the SOA.</Paragraph>
                                            <Paragraph>Before using the calculator, please read the full list of <TextLink target="_blank" rel="noopener" href={content.termsAndConditionsLink} data-id={importantInfoModalTestIds.accordionTermsLink} onClick={() => logAction("TANDC_CLICK")}>{content.termsAndConditionsText}</TextLink> which includes information about security and your personal information.</Paragraph>
                                            <Paragraph>For more information about our advice services read our <TextLink target="_blank" rel="noopener" href={content.fsgLink} data-id={importantInfoModalTestIds.accordionFinancialLink} onClick={() => logAction("ASSUMPTIONS_CLICK")}>{content.fsgText}</TextLink>.</Paragraph>
                                        </Card>
                                    </Grid>
                                </AccordionText>
                            </WelcomeAccordion>
                            <LastWelcomeAccordion
                                title={<Label fontWeight="semibold" el="h2" marginTop="2.5px" marginBottom="2.5px">Is this calculator right for you?</Label>}
                                borderStyle="none"
                                data-id={importantInfoModalTestIds.rightForYouAccordion}
                                onChange={() => {
                                    logAction("RIGHT_FOR_YOU_EXPAND");
                                    setExpanded(3);
                                }}
                                isOpen={accordions[3]}
                            >
                                <AccordionText className="rightForYou" data-id={importantInfoModalTestIds.rightForYouContent}>
                                    <Grid>
                                        <Card marginBottom="30px" marginTop="10px">
                                            <Paragraph>
                                                The calculator is designed for our members who are saving for their retirement and whose financial needs are straightforward.
                                            </Paragraph>
                                            <Text>The calculator is not appropriate for your use if you:</Text>
                                            <StyledOptionsList heading="" options={rightForYou} />
                                            <Paragraph>If any of the statements above apply to you or you would like to get advice on other topics call us on {content.contactNo}.</Paragraph>
                                            <Paragraph><b>By continuing, you confirm that none of the statements above apply to you.</b></Paragraph>
                                        </Card>
                                    </Grid>
                                </AccordionText>
                            </LastWelcomeAccordion>

                            { useContext(AppThemeContext).isMksf && (
                                <ConsentCard>
                                    <Text><strong>If you have a financial adviser and are not sure this calculator is appropriate for you, we encourage you to get in touch with them to discuss your financial situation.</strong></Text>
                                </ConsentCard>
                            )}
                        </ImportantInfo>
                    </Grid>
                    <Grid>
                        <AgreeTandC
                            handleNextStep={props.handleNextStep}
                            accepted={props.accepted}
                            setAccepted={props.setAccepted}
                            content={content}
                        />
                    </Grid>
                </InitialPopupModal>
            </>
        );
    };

export default ImportantInfoModal;