import React, { useContext } from 'react';
import { ThemeProvider, ThemeContext } from 'styled-components';
import Routing from '../../../routing/Routing';
import Footer from '../../presentational/Footer';
import { AppThemeContext } from '../../../context/AppThemeContext';
import * as palette from '@mlc/mlc-palette';
import { Card } from '@mlc/web-ui-toolkit';

// overrides some default values (e.g. colours, fonts) from the web-ui-toolkit to match the given UI designs
const ThemeOverrider = () => {
    const isMlc = useContext(AppThemeContext).isMlc;
    const themeContext = useContext(ThemeContext);
    const theme = {
        ...themeContext,
        colors: {
            ...themeContext.colors,
            secondaryBackground: isMlc ? palette.mlcAlmostWhite : "#F2F4F6",
            detailsBackground: isMlc ? palette.mlcKoala : "#EAEDF1",
            tooltipHover: isMlc ? palette.mlcDeepOrange : "#A43493",
            progressBarBackground: isMlc ? "#d98553" : "#8965A7",
            riskProfileInfoBackground: isMlc ? palette.mlcAlmostWhite : "#EAEDF1"
        },
        fonts: {
            ...themeContext.fonts,
            default: themeContext.fonts.default.replace("CorpidC1s-Light", "").replace("CorpidC1s-Regular", "").replace("CorpidC1s-Bold", ""),
            lightHeading: isMlc ? themeContext.fonts.headings : themeContext.fonts.default.replace("CorpidC1s-Light", "").replace("CorpidC1s-Regular", "").replace("CorpidC1s-Bold", ""),
            headings: themeContext.fonts.headings.replace("CorpidC1s-Light", "").replace("CorpidC1s-Regular", "").replace("CorpidC1s-Bold", ""),
            buttons: themeContext.fonts.buttons.replace("CorpidC1s-Light", "").replace("CorpidC1s-Regular", "").replace("CorpidC1s-Bold", "")
        },
        global: {
            ...themeContext.global,
            base: themeContext.global.base.replace("CorpidC1s-Light", "").replace("CorpidC1s-Regular", "").replace("CorpidC1s-Bold", ""),
            headings: themeContext.global.headings.replace("CorpidC1s-Light", "").replace("CorpidC1s-Regular", "").replace("CorpidC1s-Bold", "")
        },
    };

    return (
        <ThemeProvider theme={theme} data-id="theme-overrider">
            <Card className="main_container">
                <Card className="content_body">
                    <Routing />
                </Card>
                <Footer />
            </Card>
        </ThemeProvider>
    )
}

export default ThemeOverrider;