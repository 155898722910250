import React, { Children, cloneElement } from 'react';
import { Label, Card, Svg } from '@mlc/web-ui-toolkit';
import _ from 'lodash';
import * as palette from '@mlc/mlc-palette';
import { exclamationTriangle } from '@mlc/symbols';
import InfoPopup from './InfoPopup';
import { HelpText, TooltipLabel, HelpCard } from './Common.style';

type Props = {
    label: any;
    labelEl?: string;
    help?: any;
    error?: any;
    id: string;
    children: any;
    tooltipContent?: any;
    tooltipId?: string;
    tooltipLabel?: string;
    containerProps?: any;
    helpProps?: any;
    extraContent?: any;
    isInvalid?: boolean;
    tooltipLog?: string;
}

const FieldWithTooltip = ({ label, labelEl = 'span', help, id, children, tooltipContent, tooltipId, tooltipLabel, error, containerProps, helpProps, extraContent, isInvalid = false, tooltipLog }: Props) => {
    let field = Children.only<any>(children);
    const labelId = `${id}-label`;
    const errorId = `${id}-error`;
    const helpId = `${id}-help`
    const ariaDescribedby = _.compact([
        field.props['aria-describedby'],
        error ? errorId : null,
        help ? helpId : null
    ]).join(" ");
    
    field = cloneElement(field, {
        ...field.props,
        'aria-describedby': ariaDescribedby,
        ariaLabelledby: labelId,
        id,
        'data-id': id,
        isInvalid
    });

    return (
        <Card marginBottom={4} {...containerProps}>
            <TooltipLabel>
                <Label el={labelEl} id={labelId} htmlFor={id} display="inline">{label}</Label>
                {tooltipContent && <InfoPopup label={tooltipLabel} content={tooltipContent} dataId={tooltipId} log={tooltipLog} />}
            </TooltipLabel>
            {extraContent}
            {field}
            <HelpCard {...helpProps}>
                {isInvalid && error && (
                    <HelpText marginBottom={1} id={errorId} color={palette.mlcError}>
                        <Svg symbol={exclamationTriangle} /> {error}
                    </HelpText>
                )}
                {help && (
                    <HelpText display="block" id={helpId} color={palette.mlcDarkGrey}>
                        {help}
                    </HelpText>
                )}
            </HelpCard>
        </Card>
    )
};

export default FieldWithTooltip;