import retirementIncomeImg from '../../../../../assets/images/plum/icon-retirement.png';
import retirementAgeImg from '../../../../../assets/images/plum/hourglass.png';
import riskProfileImg from '../../../../../assets/images/plum/icon-graph.png';
import regularContributionsImg from '../../../../../assets/images/plum/icon-piggybank.png';

const retirementProjection = {

    retirementAgeImg: retirementAgeImg,
    retirementIncomeImg : retirementIncomeImg,
    riskProfileImg : riskProfileImg,
    regularContributionsImg : regularContributionsImg,

}

export default retirementProjection;