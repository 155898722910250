import React from 'react';
import { chevronLeftSolid, chevronRightSolid } from '@mlc/symbols';
import { BackButtonArrow, BackButtonStyle } from './Common.style';
import { PrimaryButton, Svg } from '@mlc/web-ui-toolkit';


export const NextButton = ({ btnRef, children, showArrow = false, ...restProps }: any) => (
    <PrimaryButton {...restProps} ref={btnRef} id="next-button" type="button">
        {children}{showArrow && <Svg symbol={chevronRightSolid} marginLeft={2} marginBottom="2px" />}
    </PrimaryButton>
)

export const BackButton = ({ btnRef, showArrow = false, ...restProps }: any) => (
    <BackButtonStyle {...restProps} ref={btnRef} id="back-button" type="button">
        {showArrow && <BackButtonArrow symbol={chevronLeftSolid} marginRight={2} marginBottom="2px" className="navArrow" />}Back
    </BackButtonStyle>
)