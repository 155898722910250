import { Card, Paragraph, Text } from '@mlc/web-ui-toolkit';
import React from 'react';
import aboutYouTestIds from './AboutYou.elements';
import iconA from '../../../../assets/images/plum/age-14-29.png';
import iconB from '../../../../assets/images/plum/age-30-44.png';
import iconC from '../../../../assets/images/plum/age-45-64.png';
import iconD from "../../../../assets/images/plum/age-over-64.png";
import { TooltipTextlink } from './AboutYou.style';
import { logAction } from '../../../../logic/functions/logging.function';
import { plumContactNo } from '../../Common/StaticContent';

const aboutYou = (productName: string) => ({
    insurancePremiumsDisclaimer: `Please enter the total current annual insurance premiums (including any stamp duty) for insurance held within your Plum Super account (if any). This can be obtained by calling us on 1300 557 586 Monday to Friday 8am-7pm AEST (8pm daylight savings time).`,
    yourPremiumsDisclaimer: "If all or part of your insurance in Plum is paid by you through deductions from your account balance, please enter the total annual insurance premium paid by you here.",
    employerPremiumsDisclaimer: "If all or part of your insurance in Plum is paid by your employer, please enter the total annual insurance premium paid by your employer here.",
    updateAccountPhoneNo: "1300 55 7586",

    ageContent : {
        17 : {
            image : iconA,
            balance: "$26,500",
        },
        25 : {
            image : iconA,
            balance: "$44,900",
        },
        30 : {
            image : iconB,
            balance: "$77,600",
        },
        35 : {
            image : iconB,
            balance: "$118,100",
        },
        40 : {
            image : iconB,
            balance: "$150,400",
        },
        45 : {
            image : iconC,
            balance: "$189,400",
        },
        50 : {
            image : iconC,
            balance: "$256,200",
        },
        55 : {
            image : iconC,
            balance: "$315,700",
        },
        60 : {
            image: iconC,
            balance: "$387,200",
        },
        65 : {
            image: iconD,
            balance: "$410,900",
        }
    },

    
    likeYouDisclaimer : [
          <Text>This data is provided by DBM Atlas (part of RFI Global) from the Consumer Atlas syndicated program, survey data estimates from over 30,000 interviews of Australians aged 18 and over with superannuation, for the 12 months to May 2024.
          <br/>The peer comparison and commentary is intended to provide a general illustration only and is not a suggestion or recommendation about what you should do with your current superannuation situation, nor does it predict any person's future retirement needs.
          <br/>Further information can be found here: <TooltipTextlink target="_blank" rel="noopener" href="https://www.plum.com.au/tools-and-resources/calculators/supersizer/where-is-this-data-from" data-id={aboutYouTestIds.supersizerFurtherInfo} onClick={() => logAction("LIKE_YOU_TT_LINK")}>https://www.mlc.com.au/personal/superannuation/supersizer/where-is-this-data-from</TooltipTextlink>.</Text>
    ],

    overSuperThresholdMsg: (
        <Card data-id={aboutYouTestIds.otherSuperOverThreshold}>
            <Paragraph>
                Based on your current total super balance, there are further considerations in determining whether
                contributing to super is the best option for you and we are unable to offer you any advice (including a
                Statement of Advice) on those options through the Personal Super Calculator. Accordingly, you should not
                rely on any projection or information provided by the calculator to this point.
            </Paragraph>
            <Paragraph>
                You should contact us on {plumContactNo} for further help.
            </Paragraph>
            <Paragraph>
                To leave the calculator, close all windows.
            </Paragraph>
        </Card>
    ),

    warnings: {
        lowSalary: (
            <div data-id={aboutYouTestIds.lowSalaryWarning}>
                <Text>
                    Your total income value (salary plus other income) is less than your total voluntary contribution strategy. We
                    would expect your total income to be more than the amounts you contribute to super. If you choose not to
                    update your current salary and other income:
                </Text>
                <ul>
                    <li>We will not be able to complete your retirement projection;</li>
                    <li>You will not be able to proceed further;</li>
                    <li>You should not rely on any projection or information provided by the tool to this point;</li>
                    <li>You will not be provided with a Statement of Advice.</li>
                </ul>
            </div>
        ),
        mcb: (
            <div data-id={aboutYouTestIds.mcbWarning}>
                <Paragraph>
                    Your calculated employer contributions are more than what your employer may be required to pay.
                    Therefore, we are unable to assess the actual contributions you are receiving. We are unable to offer
                    you any advice (including a Statement of Advice) through the Personal Super Calculator. Accordingly you
                    should not rely on any projection or information provided by the calculator to this point.
                </Paragraph>
                <Paragraph>You should contact us on {plumContactNo}.</Paragraph>
                <Paragraph>Please leave the calculator by closing all windows.</Paragraph>
            </div>
        ),
        noMemberInsurance: (
            <div data-id={aboutYouTestIds.noMemberInsuranceWarning}>
                <Paragraph>You have provided a nil insurance premium amount.</Paragraph>
                <Paragraph>
                    <Text fontWeight="semibold">Please note:</Text> If you hold insurance within your Plum Super account but do not
                    include the premium amount, your retirement projection may be overstated and any contribution recommendations
                    may be impacted, meaning the advice provided by the calculator may not be appropriate for your circumstances.
                </Paragraph>
            </div>
        ),
        noEmployerPremiums: (
            <div data-id={aboutYouTestIds.noEmployerPremiumsWarning}>
                <Paragraph>
                    <Text fontWeight="semibold">Please confirm:</Text> You have indicated that your employer pays for all
                    your insurance premiums.
                </Paragraph>
                <Paragraph>
                    If this is correct please continue. If you pay for your insurance premiums from your Plum Super account balance,
                    please update your details.
                </Paragraph>
            </div>
        ),
        noInsurancePremiums: (
            <div data-id={aboutYouTestIds.noInsurancePremiumsWarning}>
                <Paragraph>
                    <Text fontWeight="semibold">Please confirm:</Text> You have indicated that you pay for all your insurance
                    premiums (i.e. that your insurance premiums are <u>not</u> paid by your employer).
                </Paragraph>
                <Paragraph>
                    If this is correct please continue. If your employer pays for your insurance premiums, please update
                    your details.
                </Paragraph>
            </div>
        ),
        aboveSgNoEmployerInsurance: (
            <div data-id={aboutYouTestIds.aboveSgNoEmployerInsuranceWarning}>
                <Paragraph>
                    The insurance premium paid by you is more than your estimated SG contributions and the insurance
                    premium paid by your employer is nil.
                </Paragraph>
                <Paragraph>
                    <Text fontWeight="semibold">Please note:</Text> This will have a large impact on how much you can save
                    for retirement. You may need advice on whether your current insurance arrangements are appropriate for
                    your needs instead of the limited advice provided by this calculator.
                </Paragraph>
                <Paragraph>
                    If your employer pays for insurance within your Plum Super account, but you do not include the premium amount, the
                    advice provided by the calculator may not be appropriate for your circumstances.
                </Paragraph>
                <Paragraph>Contact us if you would like to be referred to a financial adviser.</Paragraph>
            </div>
        ),
        aboveSgEmployerInsurance: (
            <div data-id={aboutYouTestIds.aboveSgEmployerInsuranceWarning}>
                <Paragraph>The insurance premium paid by you is more than your estimated SG contributions.</Paragraph>
                <Paragraph>
                    <Text fontWeight="semibold">Please note:</Text> This will have a large impact on how much you can save
                    for retirement. You may need advice on whether your current insurance arrangements are appropriate for
                    your needs instead of the limited advice provided by this calculator.
                </Paragraph>
                <Paragraph>Contact us if you would like to be referred to a financial adviser.</Paragraph>
            </div>
        ),
        highContributions: (
            <div data-id={aboutYouTestIds.highContributionsWarning}>
                <Paragraph>
                    The total concessional contributions we've calculated for you are greater than the annual contribution
                    cap. As a result, we're unable to offer you any advice (including a Statement of Advice) through the Personal Super Calculator.
                    Accordingly, you should not rely on any projection or information provided by the tool to this point.
                </Paragraph>
                <Paragraph>You should contact us on {plumContactNo} for further help.</Paragraph>
                <Paragraph>To leave the calculator, close all windows.</Paragraph>
            </div>
        )
    },
    voluntaryContributionsInfo: <Text>The contributions cap calculation will only calculate any voluntary contributions made to your Plum Super account. The calculator is unable to consider any voluntary contributions you may have made into another super fund (including any other accounts you have in the Plum Super Fund), in this financial year.</Text>,
    contributionsMessage: (
        <>
            <Paragraph>
                As you have made personal contributions to another super fund or plan which we do not have information about, we are unable to offer you any advice (including a Statement of Advice) through the Personal Super Calculator. Accordingly, you should not rely on any projection or information provided by the calculator to this point.
            </Paragraph>
            <Paragraph>
                You should contact us on {plumContactNo} for further help.
            </Paragraph>
            <Paragraph>
                To leave the calculator, close all windows.
            </Paragraph>
        </>
    )
})

export default aboutYou;
