import { Card, Grid, Table, TableBody, TableCell, TableHead, Text } from "@mlc/web-ui-toolkit";
import React from 'react';
import { InvestmentMix, Member } from '../../../../logic/domains/memberData.domain';
import { TargetSetItem } from '../../../../logic/domains/targetSet.domain';
import { asDecimal, asDecimalCurrency } from '../../../../logic/functions/formatter';
import { NoBorderTableRow } from '../../CalculatorTab/CalculatorSteps/CalculatorSteps.style';
import { PaddedRow, ModalTableCard } from '../../Common/Common.style';
import { MobileDeviceQ } from '../../Common/MediaQ';
import takeActionTestIds from '../TakeAction.elements';
import { MobileTable, OptionDisplaySection, ProfileDisplaySection, ProposedCell, TableProfileDetails, TableProfileOptionName } from "../TakeAction.style";

type Values = {
    rowHeader: string;
    currentPercentContent: any;
    currentDollarContent: any;
    proposedPercentContent: any;
    proposedDollarContent: any;
    differencePercentContent: any;
    differenceDollarContent: any;
    currentPercentContentId: string;
    currentDollarContentId: string;
    proposedPercentContentId: string;
    proposedDollarContentId: string;
    differencePercentContentId: string;
    differenceDollarContentId: string;
}

type DesktopRowProps = {
    values: Values;
    fontWeight?: string;
    className?: string;
}

type MobileRowProps = {
    rowHeaderId: string;
    values: Values;
    fontWeight?: string;
    noBorder?: boolean;
}

type Props = {
    targetSet: TargetSetItem;
    memberData: Member;
    currentMix: InvestmentMix;
    tableIndex: number;
}

const DesktopTableRow = ({
    values,
    fontWeight = "regular",
    className
}: DesktopRowProps) => {
    const {
        rowHeader,
        currentPercentContent,
        currentDollarContent,
        proposedPercentContent,
        proposedDollarContent,
        differencePercentContent,
        differenceDollarContent,
        currentPercentContentId,
        currentDollarContentId,
        proposedPercentContentId,
        proposedDollarContentId,
        differencePercentContentId,
        differenceDollarContentId
    } = values;
    return (
        <PaddedRow className={className}>
            <TableCell el="th" scope="row" align="left">
                <Text fontWeight={fontWeight}>{rowHeader}</Text>
            </TableCell>
            <TableCell align="right" data-id={currentPercentContentId}>
                <Text role="text" fontWeight={fontWeight}>{currentPercentContent}%</Text>
            </TableCell>
            <TableCell align="right" data-id={currentDollarContentId}>
                <Text role="text" fontWeight={fontWeight}>{currentDollarContent}</Text>
            </TableCell>
            <ProposedCell align="right" data-id={proposedPercentContentId}>
                <Text role="text" fontWeight={fontWeight}>{proposedPercentContent}%</Text>
            </ProposedCell>
            <ProposedCell align="right" data-id={proposedDollarContentId}>
                <Text role="text" fontWeight={fontWeight}>{proposedDollarContent}</Text>
            </ProposedCell>
            <TableCell align="right" data-id={differencePercentContentId}>
                <Text role="text" fontWeight={fontWeight}>{differencePercentContent}%</Text>
            </TableCell>
            <TableCell align="right" data-id={differenceDollarContentId}>
                <Text role="text" fontWeight={fontWeight}>{differenceDollarContent}</Text>
            </TableCell>
        </PaddedRow>
    );
};

const MobileTableRow = ({
    rowHeaderId,
    values,
    fontWeight = "regular",
    noBorder = false
}: MobileRowProps) => {
    const {
        rowHeader,
        currentPercentContent,
        currentDollarContent,
        proposedPercentContent,
        proposedDollarContent,
        differencePercentContent,
        differenceDollarContent,
        currentPercentContentId,
        currentDollarContentId,
        proposedPercentContentId,
        proposedDollarContentId,
        differencePercentContentId,
        differenceDollarContentId
    } = values;
    return (
        <>
            <NoBorderTableRow>
                <TableCell el="th" align="left" colSpan={2} id={rowHeaderId}>
                    <Text fontWeight={fontWeight}>{rowHeader}</Text>
                </TableCell>
            </NoBorderTableRow>
            <NoBorderTableRow>
                <TableCell align="left" el="th" id={`${takeActionTestIds.currentHeader}-${rowHeaderId}`} rowSpan={2}>
                    <Text fontWeight="semibold">Current</Text>
                </TableCell>
                <TableCell align="right" data-id={currentPercentContentId} headers={`${rowHeaderId} ${takeActionTestIds.currentHeader}-${rowHeaderId}`}>
                    <Text fontWeight={fontWeight}>{currentPercentContent}%</Text>
                </TableCell>
            </NoBorderTableRow>
            <NoBorderTableRow>
                <TableCell align="right" data-id={currentDollarContentId} headers={`${rowHeaderId} ${takeActionTestIds.currentHeader}-${rowHeaderId}`}>
                    <Text fontWeight={fontWeight}>{currentDollarContent}</Text>
                </TableCell>
            </NoBorderTableRow>
            <NoBorderTableRow>
                <ProposedCell align="left" el="th" id={`${takeActionTestIds.proposedHeader}-${rowHeaderId}`} rowSpan={2}>
                    <Text fontWeight="semibold">Proposed</Text>
                </ProposedCell>
                <ProposedCell align="right" data-id={proposedPercentContentId} headers={`${rowHeaderId} ${takeActionTestIds.proposedHeader}-${rowHeaderId}`}>
                    <Text fontWeight={fontWeight}>{proposedPercentContent}%</Text>
                </ProposedCell>
            </NoBorderTableRow>
            <NoBorderTableRow>
                <ProposedCell align="right" data-id={proposedDollarContentId} headers={`${rowHeaderId} ${takeActionTestIds.proposedHeader}-${rowHeaderId}`}>
                    <Text fontWeight={fontWeight}>{proposedDollarContent}</Text>
                </ProposedCell>
            </NoBorderTableRow>
            <NoBorderTableRow>
                <TableCell align="left" el="th" id={`${takeActionTestIds.differenceHeader}-${rowHeaderId}`} rowSpan={2} style={{ borderBottom: !noBorder && 'thin solid #cfcbc3' }}>
                    <Text fontWeight="semibold">Difference</Text>
                </TableCell>
                <TableCell align="right" data-id={differencePercentContentId} headers={`${rowHeaderId} ${takeActionTestIds.differenceHeader}-${rowHeaderId}`}>
                    <Text fontWeight={fontWeight}>{differencePercentContent}%</Text>
                </TableCell>
            </NoBorderTableRow>
            <NoBorderTableRow>
                <TableCell align="right" data-id={differenceDollarContentId} headers={`${rowHeaderId} ${takeActionTestIds.differenceHeader}-${rowHeaderId}`}>
                    <Text fontWeight={fontWeight}>{differenceDollarContent}</Text>
                </TableCell>
            </NoBorderTableRow>
        </>
    );
}

const TakeActionDetailsTable = ({ targetSet, memberData, currentMix, tableIndex }: Props) => {
    const investFeeValues: Values = {
        rowHeader: "Investment fees and costs*",
        currentPercentContent: asDecimal(currentMix.fee.icr, false),
        currentDollarContent: asDecimalCurrency(currentMix.fee.icrDollarAmount, undefined, false),
        proposedPercentContent: targetSet.hasMySuper ? asDecimal(targetSet.mySuperIcr, false) : asDecimal(targetSet.fee.icr, false),
        proposedDollarContent: targetSet.hasMySuper ? asDecimalCurrency(targetSet.mySuperIcrDollar, undefined, false) : asDecimalCurrency(targetSet.fee.icrDollarAmount, undefined, false),
        differencePercentContent: targetSet.hasMySuper ? asDecimal(targetSet.mySuperIcr - currentMix.fee.icr, false) : asDecimal(targetSet.fee.icr - currentMix.fee.icr, false),
        differenceDollarContent: targetSet.hasMySuper ? asDecimalCurrency(targetSet.mySuperIcrDollar - currentMix.fee.icrDollarAmount, undefined, false) : asDecimalCurrency(targetSet.fee.icrDollarAmount - currentMix.fee.icrDollarAmount, undefined, false),
        currentPercentContentId: takeActionTestIds.investFeeCurrentPercent,
        currentDollarContentId: takeActionTestIds.investFeeCurrentDollar,
        proposedPercentContentId: takeActionTestIds.investFeeProposedPercent,
        proposedDollarContentId: takeActionTestIds.investFeeProposedDollar,
        differencePercentContentId: takeActionTestIds.investFeeDifferencePercent,
        differenceDollarContentId: takeActionTestIds.investFeeDifferenceDollar
    };

    const investRebateValues: Values = {
        rowHeader: "Investment rebate",
        currentPercentContent: asDecimal(currentMix.fee.rebate, false),
        currentDollarContent: asDecimalCurrency(currentMix.fee.rebateDollarAmount, undefined, false),
        proposedPercentContent: targetSet.hasMySuper ? asDecimal(targetSet.mySuperRebate, false) : asDecimal(targetSet.fee.rebate, false),
        proposedDollarContent: targetSet.hasMySuper ? asDecimalCurrency(targetSet.mySuperRebateDollar, undefined, false) : asDecimalCurrency(targetSet.fee.rebateDollarAmount, undefined, false),
        differencePercentContent: targetSet.hasMySuper ? asDecimal(targetSet.mySuperRebate - currentMix.fee.rebate, false) : asDecimal(targetSet.fee.rebate - currentMix.fee.rebate, false),
        differenceDollarContent: targetSet.hasMySuper ? asDecimalCurrency(targetSet.mySuperRebateDollar - currentMix.fee.rebateDollarAmount, undefined, false) : asDecimalCurrency(targetSet.fee.rebateDollarAmount - currentMix.fee.rebateDollarAmount, undefined, false),
        currentPercentContentId: takeActionTestIds.investRebateCurrentPercent,
        currentDollarContentId: takeActionTestIds.investRebateCurrentDollar,
        proposedPercentContentId: takeActionTestIds.investRebateProposedPercent,
        proposedDollarContentId: takeActionTestIds.investRebateProposedDollar,
        differencePercentContentId: takeActionTestIds.investRebateDifferencePercent,
        differenceDollarContentId: takeActionTestIds.investRebateDifferenceDollar
    };

    const totalValues: Values = {
        rowHeader: "Total",
        currentPercentContent: asDecimal(currentMix.fee.icr - currentMix.fee.rebate, false),
        currentDollarContent: asDecimalCurrency(currentMix.fee.icrDollarAmount - currentMix.fee.rebateDollarAmount, undefined, false),
        proposedPercentContent: targetSet.hasMySuper ? asDecimal(targetSet.mySuperIcr - targetSet.mySuperRebate, false) : asDecimal(targetSet.fee.icr - targetSet.fee.rebate, false),
        proposedDollarContent: targetSet.hasMySuper ? asDecimalCurrency(targetSet.mySuperIcrDollar - targetSet.mySuperRebateDollar, undefined, false) : asDecimalCurrency(targetSet.fee.icrDollarAmount - targetSet.fee.rebateDollarAmount, undefined, false),
        differencePercentContent: targetSet.hasMySuper
            ? asDecimal((targetSet.mySuperIcr - currentMix.fee.icr) - (targetSet.mySuperRebate - currentMix.fee.rebate), false)
            : asDecimal((targetSet.fee.icr - currentMix.fee.icr) - (targetSet.fee.rebate - currentMix.fee.rebate), false),
        differenceDollarContent: targetSet.hasMySuper
            ? asDecimalCurrency((targetSet.mySuperIcrDollar - currentMix.fee.icrDollarAmount) - (targetSet.mySuperRebateDollar - currentMix.fee.rebateDollarAmount), undefined, false)
            : asDecimalCurrency((targetSet.fee.icrDollarAmount - currentMix.fee.icrDollarAmount) - (targetSet.fee.rebateDollarAmount - currentMix.fee.rebateDollarAmount), undefined, false),
        currentPercentContentId: takeActionTestIds.totalCurrentPercent,
        currentDollarContentId: takeActionTestIds.totalCurrentDollar,
        proposedPercentContentId: takeActionTestIds.totalProposedPercent,
        proposedDollarContentId: takeActionTestIds.totalProposedDollar,
        differencePercentContentId: takeActionTestIds.totalDifferencePercent,
        differenceDollarContentId: takeActionTestIds.totalDifferenceDollar
    }

    return (
        <Card>
            <TableProfileDetails>
                <ProfileDisplaySection>
                    <Text display="block" fontWeight="semibold">Current account balance</Text>
                    <TableProfileOptionName data-id={takeActionTestIds.accountBalance}>{asDecimalCurrency(memberData.currentSuperBalance)}</TableProfileOptionName>
                </ProfileDisplaySection>
                <OptionDisplaySection>
                    <Text display="block" fontWeight="semibold">Recommended investment option</Text>
                    <TableProfileOptionName data-id={takeActionTestIds.tableRecommendedOption}>{targetSet.options[0].name}</TableProfileOptionName>
                </OptionDisplaySection>
            </TableProfileDetails>
            <Card marginBottom="15px">
                <Text data-id={takeActionTestIds.investmentTableHeading}>The following table shows changes in your investment fees for {targetSet.options[0].weighting}% {targetSet.options[0].name}.</Text>
            </Card>
            <Grid>
                <MobileDeviceQ
                    content={
                        <ModalTableCard>
                            <Table variant="bottomLines" data-id={takeActionTestIds.investmentTable}>
                                <colgroup span={2}></colgroup>
                                <colgroup span={2}></colgroup>
                                <colgroup span={2}></colgroup>
                                <TableHead>
                                    <PaddedRow className='top-padded'>
                                        <td width="33%"></td>
                                        <TableCell el="th" scope="colgroup" align="center" colSpan={2}>
                                            <Text fontWeight="semibold">
                                                Current
                                                    </Text>
                                        </TableCell>
                                        <ProposedCell el="th" scope="colgroup" align="center" colSpan={2}>
                                            <Text fontWeight="semibold">
                                                Proposed
                                                    </Text>
                                        </ProposedCell>
                                        <TableCell el="th" scope="colgroup" align="center" colSpan={2}>
                                            <Text fontWeight="semibold">
                                                Difference
                                                </Text>
                                        </TableCell>
                                    </PaddedRow>
                                </TableHead>
                                <TableBody>
                                    <DesktopTableRow
                                        values={investFeeValues}
                                    />
                                    <DesktopTableRow
                                        values={investRebateValues}
                                    />
                                    <DesktopTableRow
                                        values={totalValues}
                                        fontWeight="medium"
                                        className='bottom-padded'
                                    />
                                </TableBody>
                            </Table>
                        </ModalTableCard>
                    }
                    mobileContent={
                        <MobileTable variant="bottomLines" data-id={takeActionTestIds.investmentTable}>
                            <TableBody>
                                <MobileTableRow
                                    rowHeaderId={takeActionTestIds.investmentFeesAndCosts}
                                    values={investFeeValues}
                                />
                                <MobileTableRow
                                    rowHeaderId={takeActionTestIds.investmentRebate}
                                    values={investRebateValues}
                                />
                                <MobileTableRow
                                    rowHeaderId={takeActionTestIds.total}
                                    values={totalValues}
                                    fontWeight="medium"
                                    noBorder={true}
                                />
                            </TableBody>
                        </MobileTable>
                    }
                />
            </Grid >
        </Card >
    );
};

export default TakeActionDetailsTable;

