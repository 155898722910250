import React, { useContext } from 'react';
import { Heading } from '@mlc/web-ui-toolkit';
import { ThemeContext } from 'styled-components';

export const DetailsText = ({ children, fontWeight = "regular", el = "span", fontSize = "tiny", ...restProps }: any) => {
    const themeContext = useContext(ThemeContext);

    return (
        <Heading
            el={el}
            fontSize={fontSize}
            fontWeight={fontWeight}
            role="text"
            style={{ fontFamily: themeContext.fonts.lightHeading }}
            {...restProps}
        >
            {children}
        </Heading>
    );
};

export default DetailsText;