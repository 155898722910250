import styled from 'styled-components';
import { Card, Svg, TertiaryButton, ToggleButtonGroup } from '@mlc/web-ui-toolkit';
import { themeGet } from '@styled-system/theme-get';
import { ScreenSize } from '../../../enums';

//@ts-ignore
export const DetailsCard = styled(Card)`
    margin-bottom: 25px;
    background-color: ${themeGet('colors.detailsBackground')};

    @media (max-width: ${ScreenSize.small}) {
        margin-bottom: 15px;
    }
`;

export const DetailsContainer = styled(Card)`
    display: flex;
    flex-direction: row;

    @media (max-width: ${ScreenSize.small}) {
        flex-direction: column;
    }
`;

export const DetailsContainerSection = styled(Card)`
    @media (min-width: ${ScreenSize.small}) {
        flex-grow: 1;
        width: 0;
    }
`;

export const DetailsSuperBalance = styled(Card)`
    display: flex;
    flex-direction: row;

    @media (max-width: ${ScreenSize.small}) {
        margin-bottom: 60px;
    }
`;

export const DetailsSuperBalanceSection = styled(Card)`
    flex-grow: 1;
    width: 0;
`;

export const UpdateDetailsButton = styled(TertiaryButton)`
    padding: 0;
`;

export const UpdateDetailsIcon = styled(Svg)`
    margin-right: 10px;
    margin-bottom: 5px;
`;

export const DetailsToggleGroup = styled(ToggleButtonGroup)`
    @media (max-width: ${ScreenSize.small}){
        width: 92vw;
    }
    &.disabled {
        .ToggleButton__label, input[type='radio'] {
            background-color: #cfcbc3 !important;
        }
    }
`