import { Card, Heading, Paragraph, ToggleButton } from '@mlc/web-ui-toolkit';
import React, { useState } from 'react';
import { Calculator } from '../../../../logic/domains/calculator.domain';
import { logAction } from '../../../../logic/functions/logging.function';
import DetailsText from '../../Common/DetailsText';
import commonTestIds from '../Common.elements';
import { SpeedometerContainer, SpeedometerContainerSection, SpeedometerToggleTextCard, SpeedometerContainerGraph, SpeedometerToggle, TrackingText } from '../Common.style';
import Speedometer from './Speedometer';
import { Text } from "@mlc/web-ui-toolkit";
import { asRoundedCurrency } from "../../../../logic/functions/formatter";
import InfoPopup from '../InfoPopup';
import _ from "lodash";
import { agePensionInfo, youMayNeedInfo, youMayHaveInfo } from '../StaticContent';


type Props = {
    data: Calculator;
}

const SpeedometerSection = ({ data }: Props) => {
    const { retirementAge, projectedSuper, goalSuper, agePension, includeAgePension, firstName } = data;
    const [showIncome, setShowIncome] = useState(false);

    const getTargetText = (projected: number, goal: number) => {
        const displayedProjected = Math.round(projected);
        const displayedGoal = Math.round(goal);
        let text = '';
        if (displayedProjected < displayedGoal) {
            text = "You're tracking behind";
        } else if (displayedProjected > displayedGoal) {
            text = "You're tracking ahead";
        }

        return text;
    }

    const superText = (<>
        <Text>If you retire at age <b>{retirementAge}</b>,
            <br />you're projected to have <b>{asRoundedCurrency(projectedSuper.balance)}</b>.
            <br /><br />To meet <b>your retirement goals</b>,
            <br />you may need a super balance of <b>{asRoundedCurrency(goalSuper.balance)}</b>.
        </Text>
    </>);
    const incomeText = (<>
        <Text>If you retire at age <b>{retirementAge}</b>,
            <br />you may receive a monthly income of <b>{asRoundedCurrency(projectedSuper.income)}</b>.
            <br /><br />To meet <b>your retirement goals</b>,
            <br />you may need an income of <b>{asRoundedCurrency(goalSuper.income)}</b>.
        </Text>
    </>);

    const tooltipContent = (
        <>
            {_.map(_.compact([youMayNeedInfo, youMayHaveInfo, includeAgePension === 'y' ? agePensionInfo : null]), (value, i) => (
                <Paragraph key={i} fontSize="14px" color="#473f3a">{value}</Paragraph>
            ))}
        </>
    )

    return (
        <SpeedometerContainer paddingTop={6} paddingBottom={6}>
            <SpeedometerContainerSection marginTop="10px">
                <SpeedometerToggleTextCard>
                    <Card display="flex">
                        <Heading fontSize="20px" fontWeight="semibold" paddingBottom="20px" el="h2" margin={0}>{firstName}, are you on track?</Heading>
                        {showIncome && <InfoPopup label="information about your monthly retirement income" content={tooltipContent} dataId={commonTestIds.speedometerInfo} log="SHOW_MONTHLY_INCOME_TT" />}
                    </Card>
                    <DetailsText data-id={commonTestIds.speedometerDescription} fontSize="16px">{showIncome ? incomeText : superText}</DetailsText><br />
                    {!showIncome && getTargetText(projectedSuper.balance, goalSuper.balance) && <TrackingText data-id={commonTestIds.speedometerTracking}>{getTargetText(projectedSuper.balance, goalSuper.balance)}</TrackingText>}
                </SpeedometerToggleTextCard>
            </SpeedometerContainerSection>
            <SpeedometerContainerGraph>
                <SpeedometerToggle
                    name="retirementProjection"
                    value={showIncome ? 'income' : 'super'}
                    onChange={(e: any) => {
                        setShowIncome(e.target.value === 'income');
                    }}
                >
                    <ToggleButton value='super' onClick={() => logAction('SHOW_SUPER_BALANCE')} data-id={commonTestIds.speedometerSuperBalance}>
                        <DetailsText fontSize='16px' color='inherit'>Super balance</DetailsText>
                    </ToggleButton>
                    <ToggleButton value='income' onClick={() => logAction('SHOW_MONTHLY_INCOME')} data-id={commonTestIds.speedometerMonthlyIncome}>
                        <DetailsText fontSize='16px' color='inherit'>Income</DetailsText>
                    </ToggleButton>
                </SpeedometerToggle>
                <Speedometer
                    needed={showIncome ? goalSuper.income : goalSuper.balance}
                    received={showIncome ? projectedSuper.income : projectedSuper.balance}
                    agePension={agePension}
                    includeAgePension={showIncome && includeAgePension === 'y'}
                    showIncome={showIncome}
                />
            </SpeedometerContainerGraph>
        </SpeedometerContainer>
    )
};

export default SpeedometerSection;