import { Svg, TextLink, Card } from '@mlc/web-ui-toolkit';
import styled from 'styled-components';
import { ScreenSize } from '../../../enums';

export const SoaLink = styled(TextLink)`
    display: flex;
    align-items: center;
`;

export const SoaIcon = styled(Svg)`
    margin-right: 10px;
`;

export const SoaHeadingCard = styled(Card)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    gap: 30px;
`;


export const SoaImg = styled.img`
    width: 125px;

    &.mobile {
        display: none;
    }

    @media (max-width: ${ScreenSize.small}) {
        display: none;

        &.mobile {
            display: inline;
            width: 85px;
        }
    }
`;

export const SoaBackgroundSvg = styled.svg`
    fill: white;
    height: 100%;
    width: 100%;
    position: absolute;

    @media (min-width: ${ScreenSize.small}) {
        display: none;
    }
`;
