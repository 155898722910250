enum retirementProjectionTestIds {
    retirementProjectionExpand = 'retirement-projection-expand',
    retirementProjectionAge = 'retirement-projection-age',
    retirementProjectionIncome = 'retirement-projection-income',
    retirementProjectionRiskProfile = 'retirement-projection-risk-profile',
    retirementProjectionDoRpBtn = 'retirement-projection-do-rp-btn',
    retirementProjectionContributions = 'retirement-projection-contributions',
    retirementProjectionAgeExpandContent = 'retirement-projection-age-expand-content',
    retirementProjectionIncomeExpandContent = 'retirement-projection-income-expand-content',
    retirementProjectionRiskProfileExpandContent = 'retirement-projection-risk-profile-expand-content',
    retirementProjectionContributionsExpandContent = 'retirement-projection-contributions-expand-content',
}

export default retirementProjectionTestIds;