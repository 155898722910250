import React, { useContext, useEffect, useState } from "react";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Calculator, FrequencyValues } from "../../../../../logic/domains/calculator.domain";
import { Card, PrimaryButton, Text } from "@mlc/web-ui-toolkit";
import _ from "lodash";
import { focusHeading, validateCalculator, convertToNumber, toMonthlyFromFrequency, getRetirementAgeHelpText } from "../../../../../logic/functions/util";
import { PlayAreaAge, InputAndText, PlayAreaContent, PlayAreaHeader, PlayAreaSection, PlayAreaWrapper, RetirementProjectionHeading, PlayAreaRiskProfileSelect, PlayAreaExpandWrapper, PlayAreaExpandSection, PlayAreaExpandContent, PlayAreaText, PlayAreaRiskProfileButton, PlayAreaFieldWrapper, PlayReviewAccordion, PlayAreaMobileContainer, PlayAreaMobileSection, PlayAreaMobileSectionHeader, PlayAreaMobileSectionInput, PlayAreaMobileSectionContent } from "./RetirementProjection.style";
import { AppThemeContext } from "../../../../../context/AppThemeContext";
import { commonClasses } from "../../../Common/Common.elements";
import classNames from "classnames";
import { maintainOption, riskProfileValues } from "../RiskProfile/StaticContent";
import { asRoundedCurrency } from "../../../../../logic/functions/formatter";
import { customAnnualContributionsError, customAnnualRetirementIncomeError } from "../../../Common/StaticContent";
import { staticContent } from "./StaticContent";
import { MobileDeviceQ } from "../../../Common/MediaQ";
import { ExpandButton, StepInput } from "../CalculatorSteps.style";
import { logAction } from "../../../../../logic/functions/logging.function";
import retirementProjectionTestIds from "./RetirementProjection.elements";
import { NextButton } from "../../../Common/NavButtons";
import { NavButtonGroup } from "../../../Common/Common.style";

type Props = {
  submitForm: () => void;
  formikProps: any;
  setUpdatingMember: () => void;
  jumpToStep: (index: number) => void;
  setErrors: (newErrors: any) => void;
  frequencyValues: FrequencyValues;
  allFrozen: boolean;
  lockNavigation: boolean;
  focusFirstError: () => void;
};

export const RetirementProjection = (props: Props) => {
  const [expanded, setExpanded] = useState(false);
  const content = useContext(AppThemeContext).staticContent.retirementProjection;
  const { setFieldValue } = props.formikProps;
  const values: Calculator = props.formikProps.values;
  const { retirementAge, contributionsAmount, selectedProfileType, suggestedProfileType, goalSuper, investmentProfileType, selectedLifestyle, maintainInvestments } = values;

  const [inputValues, setInputValues] = useState({
    retirementAge: retirementAge,
    selectedProfileType: selectedProfileType,
    contributionsAmount: contributionsAmount,
    goalSuper: goalSuper,
    selectedLifestyle: selectedLifestyle,
    maintainInvestments: maintainInvestments
  });
  const [inputErrors, setInputErrors] = useState<any>({});
  const [localSelected, setLocalSelected] = useState<String | null>("");
  const [isMobileReviewOpen, setIsMobileReviewOpen] = useState(false);

  useEffect(() => {
    if (maintainInvestments) {
      setLocalSelected(maintainOption.value)
    } else if (localSelected !== selectedProfileType.type) {
      setLocalSelected(selectedProfileType.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProfileType.type, maintainInvestments]);

  const expand = (current: boolean) =>
    setExpanded((state) => {
      logAction("RETIREMENT_PROJECTION_EXPAND");
      return state ? false : true;
    });


  useEffect(() => {
    setLocalSelected(maintainInvestments ? maintainOption.value : (suggestedProfileType === null ? investmentProfileType.type : selectedProfileType.type));
    focusHeading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInputValues({
      retirementAge: values.retirementAge,
      contributionsAmount: values.contributionsAmount,
      selectedProfileType: values.selectedProfileType,
      goalSuper: values.goalSuper,
      selectedLifestyle: values.selectedLifestyle,
      maintainInvestments: values.maintainInvestments
    });
  }, [values]);

  useEffect(() => {
    // update after changing inputs but only if no inline errors
    const errors = validateCalculator({
      ...values,
      ...inputValues
    });
    setInputErrors(errors);
    props.setErrors(errors);
    if (
      _.isNil(errors.retirementAge) &&
      _.isNil(errors.contributionsAmount) &&
      _.isNil(errors.goalSuper) &&
      !_.isEqual(values, { ...values, ...inputValues })
    ) {
      setFieldValue("retirementAge", inputValues.retirementAge);
      setFieldValue("contributionsAmount", inputValues.contributionsAmount);
      setFieldValue("goalSuper.income", inputValues.goalSuper.income);
      setFieldValue("selectedLifestyle", inputValues.selectedLifestyle);

      if (inputValues.selectedLifestyle === 2) {
        setFieldValue("inToolCustomIncome", inputValues.goalSuper.income);
      }

      if (inputValues.maintainInvestments) {
        setFieldValue("maintainInvestments", true);
      } else {
        setFieldValue("maintainInvestments", false);
        setFieldValue("selectedProfileType", inputValues.selectedProfileType);
      }
      props.setUpdatingMember();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues.retirementAge, inputValues.contributionsAmount, inputValues.selectedProfileType, inputValues.goalSuper, inputValues.maintainInvestments]);

  const retirementAgeInput = <RetirementAgeInput values={values} inputErrors={inputErrors} inputValues={inputValues} setInputValues={setInputValues} {...props.formikProps} {...props} />;
  const retirementIncomeInput = <RetirementIncomeInput values={values} inputErrors={inputErrors} inputValues={inputValues} setInputValues={setInputValues} {...props.formikProps} {...props} />;
  const riskProfileInput = <RiskProfileInput values={values} inputErrors={inputErrors} inputValues={inputValues} setInputValues={setInputValues} localSelected={localSelected} setLocalSelected={setLocalSelected} {...props.formikProps} {...props} />;
  const contributionsInput = <ContributionsInput values={values} inputErrors={inputErrors} inputValues={inputValues} setInputValues={setInputValues} {...props.formikProps} {...props} />;

  return (
    <MobileDeviceQ
      content={
        <>
          <Card marginBottom="30px">
            <RetirementProjectionHeading el="h2">
              {staticContent.reviewHeader}
            </RetirementProjectionHeading>
            <Text>
              {staticContent.reviewContent}
            </Text>
          </Card>
          <PlayAreaWrapper>
            {/* {values, inputErrors, inputValues, setInputValues, ...props}: any */}
            <DesktopPlaySection heading="Retirement age" input={retirementAgeInput} img={content.retirementAgeImg} expandHeader={staticContent.retirementAgeHeader} expand={expand} expanded={expanded} />
            <DesktopPlaySection heading="Retirement income" input={retirementIncomeInput} img={content.retirementIncomeImg} expandHeader={staticContent.retirementIncomeHeader} expand={expand} expanded={expanded} />
            <DesktopPlaySection heading="Risk profile" input={riskProfileInput} img={content.riskProfileImg} expandHeader={staticContent.riskProfileHeader} expand={expand} expanded={expanded} />
            <DesktopPlaySection heading="Regular contributions" input={contributionsInput} img={content.regularContributionsImg} expandHeader={staticContent.contributionsHeader} expand={expand} expanded={expanded} />
          </PlayAreaWrapper>
          <PlayAreaExpandWrapper className={classNames(expanded ? "expanded" : "")}>
            <DesktopPlayExpandSection data-id={retirementProjectionTestIds.retirementProjectionAgeExpandContent} expandContent={{ text: staticContent.retirementAgeContent }} />
            <DesktopPlayExpandSection data-id={retirementProjectionTestIds.retirementProjectionIncomeExpandContent} expandContent={{ text: staticContent.retirementIncomeContent }} />
            <DesktopPlayExpandSection
              data-id={retirementProjectionTestIds.retirementProjectionRiskProfileExpandContent}
              expandContent={{
                text: staticContent.riskProfileContent,
                extra: (
                  <PlayAreaRiskProfileButton onClick={(e: any) => {
                    logAction("PLAY_AREA_DO_RP");
                    props.jumpToStep(3);
                  }}
                    data-id={retirementProjectionTestIds.retirementProjectionDoRpBtn}
                  >
                    {suggestedProfileType === null ? "Do risk profile" : "Re-do risk profile"}
                  </PlayAreaRiskProfileButton>
                )
              }}
            />
            <DesktopPlayExpandSection data-id={retirementProjectionTestIds.retirementProjectionContributionsExpandContent} expandContent={{ text: staticContent.contributionsContent }} />
          </PlayAreaExpandWrapper>
        </>
      }
      mobileContent={
        <PlayAreaMobileContainer>
          <PlayReviewAccordion
            title={
              <Text el="h2" fontSize="16px" fontWeight="semibold" margin={0}>{staticContent.reviewHeader}</Text>
            }
            onChange={() => {
              logAction("RETIREMENT_PROJECTION_MOBILE_EXPAND");
              setIsMobileReviewOpen(!isMobileReviewOpen);
            }}
            isOpen={isMobileReviewOpen}
          >
            <Card className="content">
              {staticContent.reviewContent}
            </Card>

          </PlayReviewAccordion>
          <NavButtonGroup>
            <PrimaryButton onClick={() => {
              if (_.isEmpty(inputErrors)) {
                if (!props.lockNavigation) {
                  logAction("NEXT_6");
                  props.submitForm();
                }
              } else {
                props.focusFirstError();
              }
            }}>
              Get your report
            </PrimaryButton>
          </NavButtonGroup>
          <MobilePlaySection heading="Retirement age" input={retirementAgeInput} img={content.retirementAgeImg} mainContentHeader={staticContent.retirementAgeHeader} mainContent={staticContent.retirementAgeContent} />
          <MobilePlaySection heading="Retirement income" input={retirementIncomeInput} img={content.retirementIncomeImg} mainContentHeader={staticContent.retirementIncomeHeader} mainContent={staticContent.retirementIncomeContent} />
          <MobilePlaySection heading="Risk profile" input={riskProfileInput} img={content.riskProfileImg} mainContentHeader={staticContent.riskProfileHeader} mainContent={staticContent.riskProfileContent}
            extraContent={(
              <PlayAreaRiskProfileButton onClick={(e: any) => {
                logAction("PLAY_AREA_DO_RP");
                props.jumpToStep(3);
              }}
                data-id={retirementProjectionTestIds.retirementProjectionDoRpBtn}
              >
                {suggestedProfileType === null ? "Do risk profile" : "Re-do risk profile"}
              </PlayAreaRiskProfileButton>
            )} />
          <MobilePlaySection heading="Regular contributions" input={contributionsInput} img={content.regularContributionsImg} mainContentHeader={staticContent.contributionsHeader} mainContent={staticContent.contributionsContent} />
        </PlayAreaMobileContainer>
      }
    />
  );
};

const RetirementAgeInput = ({ values, inputErrors, inputValues, setInputValues }: any) => (
  <PlayAreaFieldWrapper
    label=""
    error={getRetirementAgeHelpText(values)}
    id="play-age"
    isInvalid={inputErrors.retirementAge}
    className="retirementAgeInput"
  ><InputAndText>
      <Text id="play-age-label">Retire at age</Text>
      <PlayAreaAge
        value={inputValues.retirementAge.toString()}
        onBlur={(e: any) => {
          setInputValues({
            ...inputValues,
            retirementAge: parseInt(e.target.value.replace(/,/g, ""))
          });
          logAction("PLAY_AREA_AGE_INTERACT");
        }}
        allowDecimal={false}
        allowLeadingZeroes={true}
        integerLimit={9}
        onKeyDown={(e: any) => {
          if (e.keyCode === 13 || e.key === 'Enter') {
            e.preventDefault();
            setInputValues({
              ...inputValues,
              retirementAge: parseInt(e.target.value.replace(/,/g, ""))
            });
            logAction("PLAY_AREA_AGE_INTERACT");
          }
        }}
        className={
          inputErrors.retirementAge ? commonClasses.validationError : ""
        }
        aria-labelledby="play-age-label"
        aria-describedby="play-age-error"
        aria-invalid={inputErrors.retirementAge}
        data-analytics-capture-value
        data-id={retirementProjectionTestIds.retirementProjectionAge}
      />
    </InputAndText>
  </PlayAreaFieldWrapper>
);

const RetirementIncomeInput = ({ values, inputErrors, inputValues, setInputValues, ...props }: any) => {
  const [incomeUpdated, setIncomeUpdated] = useState(false);
  const [incomeChangedValue, setIncomeChangedValue] = useState("");

  return (
    <PlayAreaFieldWrapper
      label=""
      error={<>
        Please enter an amount between $0 and {asRoundedCurrency(customAnnualRetirementIncomeError / props.frequencyValues.retirementGoals.value)}.
      </>}
      id="play-income"
      isInvalid={inputErrors.goalSuper}
      className="retirementIncomeInput"
    >
      <InputAndText>
        <StepInput
          prefix="$"
          onChange={(e: any) => {
            setIncomeUpdated(true);
            setIncomeChangedValue(e.target.value);
          }}
          onBlur={(e: any) => {
            if (incomeUpdated) {
              let customIncome = toMonthlyFromFrequency(convertToNumber(e.target.value), props.frequencyValues.retirementGoals.value);
              setInputValues({
                ...inputValues,
                goalSuper: {
                  ...inputValues.goalSuper,
                  income: customIncome
                },
                //if changing income, set to custom lifestyle
                selectedLifestyle: customIncome !== values.goalSuper.income ? 2 : values.selectedLifestyle
              });
              logAction("PLAY_AREA_INCOME_INTERACT");
              setIncomeUpdated(false);
            }
          }}
          value={incomeUpdated ? incomeChangedValue : (Math.round(inputValues.goalSuper.income * 12 / props.frequencyValues.retirementGoals.value)).toString()}
          allowDecimal={false}
          allowLeadingZeroes={false}
          integerLimit={9}
          onKeyDown={(e: any) => {
            if (e.keyCode === 13 || e.key === 'Enter') {
              e.preventDefault();
              if (incomeUpdated) {
                let customIncome = toMonthlyFromFrequency(convertToNumber(e.target.value), props.frequencyValues.retirementGoals.value);
                setInputValues({
                  ...inputValues,
                  goalSuper: {
                    ...inputValues.goalSuper,
                    income: customIncome
                  },
                  //if changing income, set to custom lifestyle
                  selectedLifestyle: customIncome !== values.goalSuper.income ? 2 : values.selectedLifestyle
                });
                logAction("PLAY_AREA_INCOME_INTERACT");
                setIncomeUpdated(false);
              }
            }
          }}
          className={inputErrors.goalSuper ? commonClasses.validationError : ''}
          aria-label={`retirement income ${props.frequencyValues.retirementGoals.label}`}
          aria-describedby="play-income-error"
          aria-invalid={inputErrors.goalSuper}
          data-analytics-capture-value
          data-id={retirementProjectionTestIds.retirementProjectionIncome}
        />
        <Text>{props.frequencyValues.retirementGoals.label}</Text>
      </InputAndText>
    </PlayAreaFieldWrapper>
  )
};

const RiskProfileInput = ({ values, inputErrors, inputValues, setInputValues, localSelected, setLocalSelected, ...props }: any) => (
  <PlayAreaFieldWrapper disabled={values.suggestedProfileType === null || props.allFrozen} className="riskProfileInput" >
    <PlayAreaRiskProfileSelect
      title={values.suggestedProfileType === null ? "Unable to change due to not completing risk profile" : ""}
      value={localSelected}
      onChange={(e: any) => {
        setLocalSelected(e.target.value);
      }}
      onBlur={() => {
        if (localSelected === maintainOption.value) {
          setInputValues({
            ...inputValues,
            maintainInvestments: true
          });
        } else {
          setInputValues({
            ...inputValues,
            selectedProfileType: {
              ...inputValues.selectedProfileType,
              type: localSelected
            },
            maintainInvestments: false
          });
        }
        logAction("PLAY_AREA_RP_INTERACT");
      }}
      options={[maintainOption, ...riskProfileValues(values.suggestedProfileType || "")]}
      onKeyDown={(e: any) => {
        if (e.keyCode === 13 || e.key === 'Enter') {
          e.preventDefault();
          if (localSelected === maintainOption.value) {
            setInputValues({
              ...inputValues,
              maintainInvestments: true
            });
          } else {
            setInputValues({
              ...inputValues,
              selectedProfileType: {
                ...inputValues.selectedProfileType,
                type: localSelected
              },
              maintainInvestments: false
            });
          }
          logAction("PLAY_AREA_RP_INTERACT");
        }
      }}
      aria-label={`risk profile selection`}
      data-nodisabletoggle
      data-id={retirementProjectionTestIds.retirementProjectionRiskProfile}
    />
  </PlayAreaFieldWrapper>
);

const ContributionsInput = ({ values, inputErrors, inputValues, setInputValues, ...props }: any) => {
  const [contsUpdated, setContsUpdated] = useState(false);
  const [contsChangedValue, setContsChangedValue] = useState("");
  return (
    <PlayAreaFieldWrapper
      label=""
      id="play-conts"
      error={<>
        Please enter an amount between $0 and {asRoundedCurrency(customAnnualContributionsError / props.frequencyValues.regularContributions.value)}.
      </>}
      isInvalid={inputErrors.contributionsAmount}
      className="contributionsInput"
    >
      <InputAndText>
        <StepInput
          prefix="$"
          value={contsUpdated ? contsChangedValue : Math.round(inputValues.contributionsAmount * 12 / props.frequencyValues.regularContributions.value).toString()}
          onChange={(e: any) => {
            setContsUpdated(true);
            setContsChangedValue(e.target.value);
          }}
          onBlur={(e: any) => {
            if (contsUpdated) {
              let customContributions = toMonthlyFromFrequency(convertToNumber(e.target.value), props.frequencyValues.regularContributions.value);
              setInputValues({
                ...inputValues,
                contributionsAmount: customContributions
              });
              logAction("PLAY_AREA_CONTS_INTERACT");
              setContsUpdated(false);
            }
          }}
          onKeyDown={(e: any) => {
            if (e.keyCode === 13 || e.key === 'Enter') {
              e.preventDefault();
              if (contsUpdated) {
                let customContributions = toMonthlyFromFrequency(convertToNumber(e.target.value), props.frequencyValues.regularContributions.value);
                setInputValues({
                  ...inputValues,
                  contributionsAmount: customContributions
                });
                logAction("PLAY_AREA_CONTS_INTERACT");
                setContsUpdated(false);
              }
            }
          }}
          allowDecimal={false}
          allowLeadingZeroes={true}
          integerLimit={9}
          className={inputErrors.contributionsAmount ? commonClasses.validationError : ''}
          aria-label={`super contributions ${props.frequencyValues.regularContributions.label}`}
          aria-describedby="play-conts-error"
          aria-invalid={inputErrors.contributionsAmount}
          data-analytics-capture-value
          data-id={retirementProjectionTestIds.retirementProjectionContributions}
        />
        <Text>{props.frequencyValues.regularContributions.label}</Text>
      </InputAndText>
    </PlayAreaFieldWrapper>
  )
};

const DesktopPlaySection = ({ heading, input, img, expandHeader, expand, expanded }: any) => (
  <PlayAreaSection>
    <PlayAreaContent>
      <PlayAreaHeader>
        <Text el="h3">{heading}</Text>
      </PlayAreaHeader>
    </PlayAreaContent>
    <PlayAreaContent>
      {input}
    </PlayAreaContent>
    <PlayAreaContent>
      <img alt="" src={img} />
    </PlayAreaContent>
    <PlayAreaContent>
      <PlayAreaText>{expandHeader}</PlayAreaText>
    </PlayAreaContent>
    <PlayAreaContent className={classNames("expandButton", expanded ? "expanded" : "")}>
      <ExpandButton type="button"
        onClick={() => {
          expand(expanded);
        }}
        className={classNames(!expanded ? "expanded" : "")}
        data-id={retirementProjectionTestIds.retirementProjectionExpand}
      >
        <FontAwesomeIcon size="lg" icon={expanded ? faMinus : faPlus} />
      </ExpandButton>
    </PlayAreaContent>
  </PlayAreaSection>
);

const DesktopPlayExpandSection = ({ expandContent }: any) => (
  <PlayAreaExpandSection>
    <PlayAreaExpandContent>
      <Text textAlign="center">{expandContent.text}</Text>
    </PlayAreaExpandContent>
    <PlayAreaExpandContent>
      {expandContent.extra || ""}
    </PlayAreaExpandContent>
  </PlayAreaExpandSection>
);

const MobilePlaySection = ({ heading, input, img, mainContentHeader, mainContent, extraContent }: any) => (
  <PlayAreaMobileSection>
    <PlayAreaMobileSectionHeader>
      {heading}
    </PlayAreaMobileSectionHeader>
    <PlayAreaMobileSectionContent>
      <PlayAreaMobileSectionInput>
        <img alt="" src={img} />
        {input}
      </PlayAreaMobileSectionInput>
      <Text fontWeight="semibold" display="block">
        {mainContentHeader}
      </Text>
      <Text>
        {mainContent}
      </Text>
      <Card display="flex" justifyContent="flex-end">
        {extraContent || ""}
      </Card>
    </PlayAreaMobileSectionContent>
  </PlayAreaMobileSection>
);

export default RetirementProjection;
