enum commonTestIds {
    infoPopupContent = 'info-popup-content',
    infoPopupClose = 'info-popup-close',
    infoPopupText = 'info-popup-text',
    pageIndicator = 'page-indicator',
    speedometerGraph = 'speedometer-graph',
    speedometerGraphLabel = 'speedometer-graph-label',
    speedometerNeededLabel = 'speedometer-needed-label',
    speedometerReceivedLabel = 'speedometer-received-label',
    speedometerAgePensionLabel = 'speedometer-age-pension-label',
    speedometerNeededInfo = 'speedometer-needed-info',
    speedometerReceivedInfo = 'speedometer-received-info',
    speedometerAgePensionInfo = 'speedometer-age-pension-info',
    speedometerInfo = 'speedometer-info',
    speedometerDescription = 'speedometer-description',
    speedometerTracking = 'speedometer-tracking',
    speedometerMonthlyIncome = 'speedometer-monthly-income',
    speedometerSuperBalance = 'speedometer-super-balance',
    optionsList = 'options-list',
    modalVideoButton = 'modal-video-button',
    navBarHeader = 'nav-bar-header'
}

export enum commonClasses {
    validationError = 'validation-error',
}

export default commonTestIds;