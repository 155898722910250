import { Card, LoadingIndicator, Text } from '@mlc/web-ui-toolkit';
import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { PdfViewerDoc, PdfViewerPage, PdfViewerButton, PdfViewerLoadingContainer, PdfViewerLoadingPlaceholder, PdfViewerButtonIcon, PdfViewOverlay, PdfViewerControls, PdfViewerControlsButton, TableOfContentsItem, PagePreviewItem, TableOfContentsExpand, SideBarContainer, SideBarHeading, NavAndDocContainer, PdfViewerHeading, PdfViewerControlsPageNum } from './PdfViewer.style';
import { faBars, faChevronCircleLeft, faChevronCircleRight, faChevronDown, faChevronLeft, faChevronRight, faDownload, faSearchMinus, faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDoubleLeft, faChevronDoubleRight, faCompressAlt, faExpandAlt } from '@fortawesome/pro-solid-svg-icons';
import { Apps as GridIcon } from '@material-ui/icons';
import { logAction } from '../../../logic/functions/logging.function';
import { gaEvent, getPageName } from '../../../logic/functions/gaTracking';
import { downloadSoa } from '../../../logic/functions/soa.function';
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { SizeMe } from 'react-sizeme';
import pdfViewerTestIds from './PdfViewer.elements';

type Props = {
  soaData?: any;
  currPageNum?: number;
}

const PdfViewer = ({ soaData, currPageNum }: Props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(currPageNum || 1);
  const [renderedPageNumber, setRenderedPageNumber] = useState(currPageNum || 1);
  const [renderedScale, setRenderedScale] = useState(1.0);
  const [soaUrl, setSoaUrl] = useState("");
  const [pdfOutline, setPdfOutline] = useState<any[]>([]);
  const [isOutlineOpen, setIsOutlineOpen] = useState(false);
  const [isPagePreviewOpen, setIsPagePreviewOpen] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [scale, setScale] = useState(1.0);
  const maxScale = 2.5;
  const minScale = 1.0;

  useEffect(() => {
    const updateFullscreen = () => {
      // webkit and ms prefixes are used by safari/IE but don't exist on the base document/element so we need to declare them
      let doc = document as globalThis.Document & {
        webkitFullscreenElement: Element;
        msFullscreenElement: Element;
      }

      setIsFullscreen(!!(doc.fullscreenElement ||
        doc.webkitFullscreenElement ||
        doc.msFullscreenElement)
      );
    }
    //handles updating state when exiting fullscreen through keyboard commands
    window.addEventListener('fullscreenchange', updateFullscreen);
    window.addEventListener('webkitfullscreenchange', updateFullscreen);
    window.addEventListener('msfullscreenchange', updateFullscreen);

    return () => {
      window.removeEventListener('fullscreenchange', updateFullscreen);
      window.removeEventListener('webkitfullscreenchange', updateFullscreen);
      window.removeEventListener('msfullscreenchange', updateFullscreen);
    }
  }, []);

  useEffect(() => {
    if (soaData) {
      setSoaUrl(window.URL.createObjectURL(soaData.content));
    }
  }, [soaData]);

  useEffect(() => {
    const showCurrPage = () => {
      //search for this so that it doesn't scroll before page thumbnail is rendered
      const elToShow = document.querySelector(".currPage .react-pdf__Page");
      if (elToShow) {
        setTimeout(() => {
          let currPagePreview = document.getElementById(`pdf-page-preview-${pageNumber}`);
          currPagePreview && currPagePreview.scrollIntoView({ block: 'nearest' });
        }, 0);
      } else {
        window.requestAnimationFrame(showCurrPage);
      }
    }

    if (isPagePreviewOpen) {
      showCurrPage();
    }
  }, [pageNumber, isPagePreviewOpen]);

  useEffect(() => {
    if (isOutlineOpen || isPagePreviewOpen) {
      setTimeout(() => {
        let sidebar = document.getElementById("sidebar-container");
        sidebar && sidebar.focus();
      }, 0);
    }
  }, [isOutlineOpen, isPagePreviewOpen]);

  useEffect(() => {
    setTimeout(() => {
      let pdfViewerLinks: NodeListOf<HTMLAnchorElement> = document.querySelectorAll("section.linkAnnotation a");
      pdfViewerLinks.forEach(link => {
        link.onclick = () => logAction(`PDF_SOA_LINK_PAGE_${renderedPageNumber}_${link.href.replace("https://", "").replace("http://", "").replace(/\//g, "--")}`);
      });
    }, 0);
  }, [renderedPageNumber, renderedScale, isFullscreen]);

  async function setOutlineItems(outline: any, pdf: any) {
    for (let outlineItem of outline) {
      let dest;
      if (typeof outlineItem.dest === 'string') {
        dest = await pdf.getDestination(outlineItem.dest);
      } else {
        dest = outlineItem.dest;
      }
      outlineItem.pageNumber = await pdf.getPageIndex(dest[0]) + 1;

      if (outlineItem.items.length > 0) {
        outlineItem.isExpanded = false;
        setOutlineItems(outlineItem.items, pdf);
      }
    }
  }

  function onDocumentLoadSuccess(pdf: any) {
    setNumPages(pdf.numPages);

    //need to get outline manually as react-pdf Outline component doesn't really give an option for custom rendering
    pdf.getOutline().then(async function (outline: any[]) {
      if (outline) {
        await setOutlineItems(outline, pdf);
        setPdfOutline(outline);
      }
    });
  }

  function changePage(offset: number) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const isLoading = renderedPageNumber !== pageNumber || renderedScale !== scale;

  const ToCItem = ({ item }: any) => (
    <TableOfContentsItem
      href="#"
      onClick={(e: any) => {
        e.preventDefault();
        setPageNumber(item.pageNumber);
        logAction("PDF_TOC_INTERACT");
      }}
      aria-label={`go to ${item.title} in PDF`}
    >
      {item.title}
    </TableOfContentsItem>
  );

  return (
    <PdfViewOverlay data-id={pdfViewerTestIds.pdfViewer} id="pdf-overlay" className={`${isFullscreen ? "fullscreenPdf" : ""}`}>
      <Card display="flex" justifyContent="center" className={`fsResizeSideContainer ${(isOutlineOpen || isPagePreviewOpen) ? "sidebarOpen" : ""}`}>
        {(isOutlineOpen || isPagePreviewOpen) &&
          (
            <>
              {isOutlineOpen &&
                <SideBarContainer className="fsResizeSidebar" aria-label="table of contents section" id="sidebar-container" tabIndex={0}>
                  <SideBarHeading className="sidebarHeading">
                    <FontAwesomeIcon icon={faBars} /><Text marginLeft="5px">Table of contents</Text>
                  </SideBarHeading>
                  {pdfOutline.map((item: any, index: number) => (
                    <Card key={`${item.title}-${index}`} className="fsResizeToc">
                      <Card display="flex" alignItems="center" marginLeft="0.5em" marginTop="10px">
                        {item.items.length > 0 && (
                          <TableOfContentsExpand onClick={() => {
                            let allItems = [...pdfOutline];
                            allItems[index].isExpanded = !allItems[index].isExpanded;
                            setPdfOutline(allItems);
                          }} aria-label={`${pdfOutline[index].isExpanded ? 'hide' : 'show'} sub-items for ${item.title}`}>
                            <FontAwesomeIcon size="xs" icon={pdfOutline[index].isExpanded ? faChevronDown : faChevronRight} />
                          </TableOfContentsExpand>
                        )}
                        <ToCItem item={item} />
                      </Card>
                      {(pdfOutline[index].isExpanded && item.items.length > 0) && item.items.map((subItem: any, subIndex: number) => (
                        <Card key={`${subItem.title}-${subIndex}`} marginLeft="1em" marginTop="10px">
                          <ToCItem item={subItem} />
                        </Card>
                      ))}
                    </Card>
                  ))}
                </SideBarContainer>
              }
              {isPagePreviewOpen && (
                <SizeMe>
                  {({ size }) => (
                    // can't move this to functional component otherwise it messes with the page preview rendering when navigating
                    <SideBarContainer className="fsResizeSidebar" aria-label="page preview grid section" id="sidebar-container" tabIndex={0}>
                      <SideBarHeading className="sidebarHeading">
                        <GridIcon /><Text marginLeft="5px">Pages</Text>
                      </SideBarHeading>
                      <Document
                        file={soaUrl}
                        loading={<></>}
                      >
                        <Card display="flex" flexDirection="column" justifyContent="center">
                          {Array.apply(null, Array(numPages))
                            .map((x, i) => i + 1)
                            .map(pagePreviewNum => (
                              <Card marginTop="10px" display="flex" flexDirection="column" alignItems="center">
                                <PagePreviewItem onClick={() => {
                                  setPageNumber(pagePreviewNum);
                                  logAction("PDF_GRID_INTERACT");
                                }} className={pagePreviewNum === pageNumber ? "currPage" : ""} id={`pdf-page-preview-${pagePreviewNum}`} aria-label={`go to page ${pagePreviewNum}`}>
                                  <Page
                                    key={`pagePreview-${pagePreviewNum}`}
                                    pageNumber={pagePreviewNum}
                                    renderAnnotationLayer={false}
                                    renderTextLayer={false}
                                    loading={<></>}
                                    width={(size.width || 1) - 35}
                                  />
                                </PagePreviewItem>
                                <Text>{pagePreviewNum}</Text>
                              </Card>
                            ))}
                        </Card>
                      </Document>
                    </SideBarContainer>
                  )}
                </SizeMe>
              )}
            </>
          )}
        <Card>
          <NavAndDocContainer className={`${isFullscreen ? "fullscreenPdf" : ""}`}>
            <PdfViewerButton
              disabled={pageNumber <= 1}
              onClick={() => {
                previousPage();
                logAction("PDF_SIDE_BACK");
              }}
              className={`prevButton fsResizeNav ${(isOutlineOpen || isPagePreviewOpen) ? "sidebarOpen" : ""}`}
              aria-label="statement of advice PDF viewer previous page"
              title="Go back one page"
              data-id={pdfViewerTestIds.pdfViewerSideBack}
            >
              <PdfViewerButtonIcon icon={faChevronCircleLeft as IconLookup} />
            </PdfViewerButton>
            <Card>
              <PdfViewerHeading el="h2" className="fsResizeHeading">Your super report</PdfViewerHeading>
              {soaUrl ? (
                <SizeMe>
                  {({ size }) => (
                    <PdfViewerDoc
                      file={soaUrl}
                      onLoadSuccess={onDocumentLoadSuccess}
                      loading={<PdfViewerLoadingContainer className={`fsResizeLoading ${(isOutlineOpen || isPagePreviewOpen) ? "sidebarOpen" : ""}`}><LoadingIndicator variant="scaled" /></PdfViewerLoadingContainer>}
                      externalLinkTarget="_blank"
                      className={["fsResizeDoc", (isOutlineOpen || isPagePreviewOpen) ? "sidebarOpen" : ""]}
                    >
                      {isLoading ? (
                        <PdfViewerPage
                          key={`${renderedPageNumber}-${renderedScale}`}
                          className="prevPage"
                          pageNumber={renderedPageNumber}
                          loading={<></>}
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                          width={size.width || 1}
                          scale={renderedScale}
                        />
                      ) : null}
                      <PdfViewerPage
                        key={`${pageNumber}-${scale}`}
                        loading={<></>}
                        pageNumber={pageNumber}
                        renderAnnotationLayer={true}
                        renderTextLayer={true}
                        onRenderSuccess={() => {
                          setRenderedPageNumber(pageNumber);
                          setRenderedScale(scale);
                        }}
                        width={size.width || 1}
                        scale={scale}
                      />
                    </PdfViewerDoc>
                  )}
                </SizeMe>
              ) : (
                <PdfViewerLoadingPlaceholder className="fsResizeLoading">
                  <PdfViewerLoadingContainer className="fsResizeLoading"><LoadingIndicator variant="scaled" /></PdfViewerLoadingContainer>
                </PdfViewerLoadingPlaceholder>
              )}
            </Card>
            <PdfViewerButton
              disabled={pageNumber >= numPages!}
              onClick={() => {
                nextPage();
                logAction("PDF_SIDE_NEXT");
              }}
              className={`nextButton fsResizeNav ${(isOutlineOpen || isPagePreviewOpen) ? "sidebarOpen" : ""}`}
              aria-label="statement of advice PDF viewer next page"
              title="Go forward one page"
              data-id={pdfViewerTestIds.pdfViewerSideNext}
            >
              <PdfViewerButtonIcon icon={faChevronCircleRight} />
            </PdfViewerButton>
          </NavAndDocContainer>
          <Card>
            <PdfViewerControls className="fsResizeControls">
              {/* jump to start */}
              <PdfViewerControlsButton disabled={pageNumber <= 1}
                onClick={() => {
                  setPageNumber(1);
                  logAction("PDF_JUMP_TO_START");
                }} aria-label="statement of advice PDF viewer jump to first page" title="Go to first page" data-id={pdfViewerTestIds.pdfViewerControlsStart}>
                <FontAwesomeIcon icon={faChevronDoubleLeft} />
              </PdfViewerControlsButton>
              {/* back */}
              <PdfViewerControlsButton disabled={pageNumber <= 1}
                onClick={() => {
                  previousPage();
                  logAction("PDF_CONTROLS_BACK");
                }} aria-label="statement of advice PDF viewer previous page" title="Go back one page" className="hide-mobile" data-id={pdfViewerTestIds.pdfViewerControlsBack}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </PdfViewerControlsButton>
              <PdfViewerControlsPageNum>{pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}</PdfViewerControlsPageNum>
              {/* next */}
              <PdfViewerControlsButton disabled={pageNumber >= numPages!}
                onClick={() => {
                  nextPage();
                  logAction("PDF_CONTROLS_NEXT");
                }} aria-label="statement of advice PDF viewer next page" title="Go forward one page" className="hide-mobile" data-id={pdfViewerTestIds.pdfViewerControlsNext}>
                <FontAwesomeIcon icon={faChevronRight} />
              </PdfViewerControlsButton>
              {/* jump to end */}
              <PdfViewerControlsButton disabled={pageNumber >= numPages!}
                onClick={() => {
                  setPageNumber(numPages!);
                  logAction("PDF_JUMP_TO_END");
                }} aria-label="statement of advice PDF viewer jump to last page" title="Go to last page" data-id={pdfViewerTestIds.pdfViewerControlsEnd}>
                <FontAwesomeIcon icon={faChevronDoubleRight} />
              </PdfViewerControlsButton>
              {/* table of contents */}
              <PdfViewerControlsButton onClick={() => {
                setIsOutlineOpen(!isOutlineOpen);
                setIsPagePreviewOpen(false);
                logAction("PDF_CONTROLS_TOC");
              }}
                aria-label="statement of advice PDF viewer show/hide table of contents"
                title="Table of contents"
                className="hide-mobile"
                data-id={pdfViewerTestIds.pdfViewerControlsToc}
              >
                <FontAwesomeIcon icon={faBars} />
              </PdfViewerControlsButton>
              {/* nav grid */}
              <PdfViewerControlsButton onClick={() => {
                setIsPagePreviewOpen(!isPagePreviewOpen);
                setIsOutlineOpen(false);
                logAction("PDF_CONTROLS_GRID");
              }}
                aria-label="statement of advice PDF viewer show/hide page thumbnails"
                title="Page thumbnails"
                className="hide-mobile"
                data-id={pdfViewerTestIds.pdfViewerControlsGrid}
              >
                <GridIcon />
              </PdfViewerControlsButton>
              {/* fullscreen */}
              <PdfViewerControlsButton onClick={() => {
                if (isFullscreen) {
                  let doc = document as globalThis.Document & {
                    webkitExitFullscreen(): Promise<void>;
                    msExitFullscreen(): Promise<void>;
                  }
                  if (doc.exitFullscreen) {
                    doc.exitFullscreen();
                  } else if (doc.webkitExitFullscreen) {
                    doc.webkitExitFullscreen();
                  } else if (doc.msExitFullscreen) {
                    doc.msExitFullscreen();
                  }
                } else {
                  let fullscreenEl = document.getElementById("pdf-overlay")! as HTMLElement & {
                    webkitRequestFullscreen(): Promise<void>;
                    msRequestFullscreen(): Promise<void>;
                  }
                  if (fullscreenEl.requestFullscreen) {
                    fullscreenEl.requestFullscreen();
                  } else if (fullscreenEl.webkitRequestFullscreen) {
                    fullscreenEl.webkitRequestFullscreen();
                  } else if (fullscreenEl.msRequestFullscreen) {
                    fullscreenEl.msRequestFullscreen();
                  }
                }
                logAction("PDF_CONTROLS_FULLSCREEN");
              }}
                aria-label="statement of advice PDF viewer fullscreen"
                title="Fullscreen"
                data-id={pdfViewerTestIds.pdfViewerControlsFullscreen}
              >
                <FontAwesomeIcon icon={isFullscreen ? faCompressAlt : faExpandAlt} />
              </PdfViewerControlsButton>
              {/* zoom in */}
              <PdfViewerControlsButton disabled={scale >= maxScale}
                onClick={() => {
                  setScale(scale + 0.25);
                  logAction("PDF_CONTROLS_ZOOM_IN");
                }} aria-label="statement of advice PDF viewer zoom in" title="Zoom in" data-id={pdfViewerTestIds.pdfViewerControlsZoomIn}>
                <FontAwesomeIcon icon={faSearchPlus} />
              </PdfViewerControlsButton>
              {/* zoom out */}
              <PdfViewerControlsButton disabled={scale <= minScale}
                onClick={() => {
                  setScale(scale - 0.25);
                  logAction("PDF_CONTROLS_ZOOM_OUT");
                }} aria-label="statement of advice PDF viewer zoom out" title="Zoom out" data-id={pdfViewerTestIds.pdfViewerControlsZoomOut}>
                <FontAwesomeIcon icon={faSearchMinus} />
              </PdfViewerControlsButton>
              {/* download */}
              <PdfViewerControlsButton
                onClick={() => {
                  logAction("DOWNLOAD_SOA");
                  gaEvent('link', 'statement of advice', getPageName());
                  downloadSoa(soaData);
                }}
                data-id={pdfViewerTestIds.pdfViewerControlsDownload}
                aria-label="statement of advice PDF viewer download"
                title="Download"
              >
                <FontAwesomeIcon icon={faDownload} />
              </PdfViewerControlsButton>
            </PdfViewerControls>
          </Card>
        </Card>
      </Card>
    </PdfViewOverlay>
  );
}
export default PdfViewer;