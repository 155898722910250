enum calculatorTabTestIds {
    detailsCurrentBalance = 'details-current-balance',
    detailsCurrentSalary = 'details-current-salary',
    agePensionWarning = 'age-pension-warning',
    contributionsWarning = 'contributions-warning',
    calculatorInitialLoadingIndicator = 'calculator-initial-loading-indicator',
    calculatorLoadingIndicator = 'calculator-loading-indicator',
    adviceModal = 'advice-modal',
    updateYourDetailsBtn = 'update-your-details-btn',
}

export default calculatorTabTestIds;