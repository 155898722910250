import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';
import { Card, TextLink } from '@mlc/web-ui-toolkit';
import * as palette from '@mlc/mlc-palette';
import { ScreenSize } from '../../../enums';

const linkSpacing = css`
	a:not(:last-of-type) {
		margin: 0 35px 0 0;
	}
`;

export const FooterCard = styled(Card)`
	&.mlc {
		background-color: ${palette.mlcPartner};
	}

	&.plum {
		background-color: #F3F4F6;
	}

	&.contact {
		background-color: ${themeGet('colors.detailsBackground')};
	}
`;

export const FooterInnerCard = styled(Card)`
	&.needHelp {
		display: flex;
		align-items: center;

		@media (max-width: ${ScreenSize.small}) {
			flex-direction: column;
			text-align: center;
			img {
				max-width: 95px;
				align-self: center;
				margin-right: 0;
			}
		}
	}

	#footer-booking {
		color: ${themeGet('colors.brandPrimaryLight')} !important;
    
		&:hover, &:focus {
			color:  ${themeGet('colors.brandPrimaryDark')} !important;
		}
	}

`;

export const SecondaryNav = styled.nav`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin-bottom: 15px;

	@media screen and (min-width: ${themeGet('gridTheme.breakpoints.md')}px) {
		${linkSpacing}
		flex-direction: row;
	}
`;

export const FooterSecondary = styled.nav`
	${linkSpacing}
	@media screen and (min-width: ${themeGet('gridTheme.breakpoints.md')}px) {
		text-align: right;
	}
`;

export const FooterLink = styled(TextLink)`
	&.mlc {
		color: ${themeGet('colors.mlcWhite')};

		&:hover,
		&:focus {
			color: ${themeGet('colors.mlcLightGrey')};
		}

		&:focus {
			outline: 1px solid ${themeGet('colors.mlcLightGrey')};
		}
	}
`;

export const InjectedFooterText = styled.div`
	&.mlc {
		p {
			color: ${themeGet('colors.mlcWhite')} !important;
		}
	}

	&.plum {
		p {
			color: ${themeGet('colors.mlcText')} !important;
		}
	}

	p {		
		${themeGet('global.base')}
		margin: 0;
		padding: 0 0 10px 0;
		font-size: ${themeGet('fontSizes.disclaimer')}px;
		line-height: ${themeGet('lineHeights.disclaimer')};
		font-family: ${themeGet('fonts.default')}

		@media screen and (min-width: ${themeGet('gridTheme.breakpoints.md')}px) {
			padding: 0;
		}
	}
`;

export const ContactImg = styled.img`
	height: 90px;
	margin-right: 35px;
`;
