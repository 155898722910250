enum takeActionTestIds {
    nextStepsContainer = 'next-steps-container',
    nextStepsLoading = 'next-steps-loading',
    currentRiskProfile = 'current-risk-profile',
    suggestedRiskProfile = 'suggested-profile-mix',
    recommendedOption = 'recommended-option',
    targetSetName = 'target-set-name',
    targetSetOption = 'target-set-option',
    concessionalContributions = 'concessional-contributions',
    nonConcessionalContributions = 'non-concessional-contributions',
    bpayBtn = 'bpay-btn',
    bpayModalContent = 'bpay-modal-content',
    nextStepsInfoModal = 'next-steps-info-modal',
    talkToCoachModal = 'talk-to-coach-modal',
    maintainInvestmentMix = 'maintain-investment-mix',
    holdInvestments = 'hold-investments',
    investmentTableLink = 'investment-table-link',
    investmentTableModal = 'investment-table-modal',
    investmentTable = 'investment-table',
    investmentTableHeading = 'investment-table-heading',
    closedInvestmentOptionContent = 'closed-investment-option-content',
    currentHeader = 'current-header',
    proposedHeader = 'proposed-header',
    differenceHeader = 'difference-header',
    investmentFeesAndCosts = 'investment-fees-and-costs',
    investmentRebate = 'investment-rebate',
    total = 'total',
    accountBalance = 'account-balance',
    tableRecommendedOption = 'table-recommended-option',
    accountBalanceCurrentHeader = 'account-balance-current-header',
    accountBalanceProposedHeader = 'account-balance-proposed-header',
    investFeeCurrentPercent = 'invest-fee-current-percent',
    investFeeCurrentDollar = 'invest-fee-current-dollar',
    investFeeProposedPercent = 'invest-fee-proposed-percent',
    investFeeProposedDollar = 'invest-fee-proposed-dollar',
    investFeeDifferencePercent = 'invest-fee-difference-percent',
    investFeeDifferenceDollar = 'invest-fee-difference-dollar',
    investRebateCurrentPercent = 'invest-rebate-current-percent',
    investRebateCurrentDollar = 'invest-rebate-current-dollar',
    investRebateProposedPercent = 'invest-rebate-proposed-percent',
    investRebateProposedDollar = 'invest-rebate-proposed-dollar',
    investRebateDifferencePercent = 'invest-rebate-difference-percent',
    investRebateDifferenceDollar = 'invest-rebate-difference-dollar',
    totalCurrentPercent = 'total-current-percent',
    totalCurrentDollar = 'total-current-dollar',
    totalProposedPercent = 'total-proposed-percent',
    totalProposedDollar = 'total-proposed-dollar',
    totalDifferencePercent = 'total-difference-percent',
    totalDifferenceDollar = 'total-difference-dollar',
    nextStepsPreviousTab = 'next-steps-previous-tab',
    makeContBtn = 'make-cont-btn',
    yourRetirementProjection = 'your-retirement-projection',
    updateYourInvestments = 'update-your-investments',
    updateYourContributions = 'update-your-contributions',
    getHelp = 'get-help',
    noRpOrConts = 'no-rp-or-conts',
    exitBtn = 'exit-btn',
    salarySacrificeLearnMore = 'salary-sacrifice-learn-more',
    personalDeductibleLearnMore = 'personal-deductible-learn-more',
    bookingBtn = 'booking-btn',
    switchInvBtn = 'switch-inv-button',
    learnFinancialAdvice = 'learn-financial-advice'
}

export default takeActionTestIds;