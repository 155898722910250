import { createContext } from 'react';
import mlcContent from './statics/MlcContent';
import plumContent from './statics/PlumContent';
import mksfContent from './statics/MksfContent';

// different skins contain different content (e.g. phone numbers, tooltip info) so we can pass them to every component through context
export const mlcTheme = {
	isMlc: true,
	isMksf: false,
	staticContent: mlcContent
}

export const mksfTheme = {
	isMlc: true,
	isMksf: true,
	staticContent: mksfContent
}

export const plumTheme = {
	isMlc: false,
	isMksf: false,
	staticContent: plumContent
}

export const csrfToken: any = {};

export const AppThemeContext = createContext(mlcTheme);