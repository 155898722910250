import React from 'react';
import { TextLink, Text } from '@mlc/web-ui-toolkit';
import { logAction } from '../../../logic/functions/logging.function';
import superReportTestIds from './SuperReport.elements';
import laptopFormImg from '../../../assets/images/mkbs/laptop-form.png';
import soaImg from '../../../assets/images/mkbs/laptop-form.png';

const superReport = {
    adviceOptions: [
        "You must read the Statement of Advice before acting on our advice.",
        "You can also access this document from the 'Statements and Letters' section when logged into your super account.",
        "Check that the information we have about you is complete and accurate. If you think that some relevant information is missing or incorrect, you should not act on any information or recommendations in the Statement of Advice.",
        "Laws and circumstances relevant to our advice may change. If you choose to act on our advice at a later date, make sure you check that the advice in the Statement of Advice is current before doing so.",
        <Text>Please refer to the <TextLink target="_blank" rel="noopener" href="https://www.mlc.com.au/content/dam/mlc/documents/disclosure/mkspf/mkspf-fund-offer-combo-kit.pdf" onClick={() => logAction("PDS_CLICK")} data-id={superReportTestIds.soaPdsLink}>Product Disclosure Statement (PDF, 29.6MB)</TextLink> for full product details.</Text>
    ],
    adviceHeaderImg: laptopFormImg,
    soaImg
}

export default superReport;