import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';

export const inlineVariant = css`
	display: inline-block;
	width: ${(props) => `${props.width ? props.width + 'px' : 'auto'}`};
`;

export const StyledSelect = styled.div`
	position: relative;
	${(props) => (props.variant ? inlineVariant : '')}

	.Select {
		appearance: none;
		margin-bottom: ${themeGet('space.2')}px;
		background-color: ${themeGet('colors.mlcWhite')};
		height: 44px;
		padding: 10px 33px 10px 10px;
		box-sizing: border-box;
		border: thin solid ${themeGet('colors.mlcMediumGrey')};
		font-family: ${themeGet('fonts.default')};
		font-size: ${themeGet('fontSizes.input')}px;
		color: ${themeGet('colors.mlcVeryDarkGrey')};
		border-radius: 0;
		width: 100%;

		&:-moz-focusring {
			color: transparent;
			text-shadow: 0 0 0 #000;
		}

		/* overwrite ie default arrow */
		&::-ms-expand {
			display: none;
		}

		&:focus,
		&:hover {
			outline: none;
			box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
				0 0 4px ${themeGet('colors.brandSecondaryLight')};
			border: thin solid ${themeGet('colors.brandSecondaryLight')};
		}

		&__icon {
			position: absolute;
			pointer-events: none;
			top: 13px;
			right: 13px;
		}

		&.Select--isInvalid {
			&:not(:focus):not(:hover) {
				border-color: ${themeGet('colors.mlcError')};
			}
		}
	}

	@media print {
		color: ${themeGet('colors.mlcVeryDarkGrey')};
		box-shadow: none;
	}
`;
