import * as palette from '@mlc/mlc-palette';
import { Card, TableRow, NumberInput, RadioGroup, Heading, Svg, Text, TableCell, TableBody, FieldWrapper, Row, Modal, TextLink, Paragraph, Accordion } from '@mlc/web-ui-toolkit';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { ScreenSize } from '../../../../enums';
import { Button } from '@mlc/web-ui-toolkit/lib/components/Button/Button';
import { WiderModal } from '../../Common/Common.style';
import Select from '../../Common/Select';

export const InlineRadioGroup = styled(RadioGroup)`
    display: inline;
`;

export const ContributionsInput = styled(NumberInput)`
    // width: 50% !important;
    background-color: ${props => props.readOnly ? `${palette.mlcLightGrey} !important` : ''};
    padding-right: 10px;

    @media (max-width: ${ScreenSize.small}) {
        width: 100% !important;
    }
`;

export const StepInput = styled(NumberInput)`
    width: 100% !important;

    @media (max-width: ${ScreenSize.small}) {
        width: 100% !important;
    }
`;

export const RiskTolerance = styled(Card)`
    width: 15px;
    height: 15px;
    border-radius: 10px;
    display: inline-block;
    margin-right: 5px;
`;

export const CalculatorStepsDiv = styled.div`
    .branded-colour {
        color: ${themeGet('colors.brandPrimaryLight')} !important;
    }
    .branded-colour-dark {
        color: ${themeGet('colors.brandPrimaryDark')} !important;
    }
`;

export const AddOtherIncomeHeading = styled(Heading)`
    color: ${themeGet('colors.brandPrimaryLight')} !important;
`;

export const AddOtherIncomeSymbol = styled(Svg)`
    color: ${themeGet('colors.brandPrimaryLight')} !important;
`;

export const AgePensionUpdateDetails = styled(Card)`
    text-align: right;

    @media (max-width: ${ScreenSize.small}) {
        text-align: left;
        margin-bottom: 30px;
    }
`;

export const CalculatedAgePension = styled(Text)`
    color: ${themeGet('colors.brandPrimaryLight')} !important;
    display: inline;

    @media (max-width: ${ScreenSize.small}) {
        display: block;
        margin-top: 10px;
    }
`;

export const RiskProfileResultHeader = styled(Text)`
    margin : 10px;
`


export const CalculatedRiskProfile = styled(Text)`
    color: ${themeGet('colors.brandPrimaryLight')} !important;

    [class*="LoadingIndicatorstyle__LoadingWrapper"] {
        display: flex;
        justify-content: center;

        .rp-loading {
            width: 60px;
        }
    }
`;

export const NoBorderTableRow = styled(TableRow)`
    border: 0 !important;
`;

export const FlexSegment = styled(Card)`
    flex-grow: 1;
    width: 0;
`;

export const ContributionsWarningCard = styled(Card)`
    background-color: ${themeGet('colors.secondaryBackground')};
    padding: 15px;
    margin-bottom: 30px;
`;

const contributionsCellStyle = `
    &.topCell {
        padding-top: 25px;
        white-space: normal;

        @media (max-width: ${ScreenSize.xxSmall}) {
            button {
                position: relative !important;
            }
        }
    }

    @media (min-width: ${ScreenSize.xxxSmall}) {
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (min-width: ${ScreenSize.xSmall}) {
        padding-left: 25px;
        padding-right: 25px;
    }

    @media (max-width: ${ScreenSize.xxxSmall}){
        padding: 10px 8px;
    }
`;

export const ProposedContributionsCell = styled(TableCell)`
    background-color: ${themeGet('colors.secondaryBackground')};
    width: 20%;
    white-space: nowrap;
    
    ${contributionsCellStyle}
`;

export const CurrentContributionsCell = styled(TableCell)`
    width: 20%;
    white-space: nowrap;

    ${contributionsCellStyle}
`;

export const ContributionsTableBody = styled(TableBody)`
    tr:last-child {
        td {
            padding-bottom: 25px;
        }
    }
`;

export const RiskProfileIntro = styled(Card)`
    background-color: ${themeGet('colors.riskProfileInfoBackground')};
    margin-top: -30px;
    margin-left: -20px;
    margin-right: -20px;
    padding: 25px;
    font-weight: bold;
    font-size: larger;
    width: auto;
`;

export const RiskProfileQuestion = styled(FieldWrapper)`
    legend {
        margin-bottom: 15px;
    }
    
    ol {
        padding-left: 20px;
        margin: 0;
    }

    .last-word {
        white-space: nowrap;

        button {
            padding-right: 0;
        }
    }
`;

export const RiskProfileButtonsGroup = styled(RadioGroup)`
    margin-top: 30px;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;

    @media (max-width: ${ScreenSize.small}) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const RiskProfileButton = styled(Button)`
    img {
        max-width: 45px;
        position: relative;
        margin: auto;
        margin-bottom: 10px;
        display: block;
    }

    img[class*=rp-question-riskFluctuations]{
        max-width: 80px;
    }

    img[class*=rp-question-riskNegativeReturns]{
        margin-bottom: 0px;
        margin-top: -15px;
        max-width: 55px;  
    }

    &:hover,
    &:focus {
        background-color: ${themeGet('colors.brandPrimaryDark')};
        color: white;
        cursor: pointer;
    }

    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    background-color: ${themeGet('colors.mlcWhite')};
    border-color: ${themeGet('colors.brandPrimaryDark')};
    border-style: solid;
    color: black;
    padding: 25px;
    font-size: 16px;

    &.selectedRPQuestion {
        background-color: ${themeGet('colors.brandPrimaryDark')};
        color: white;
    }
    @media (max-width: ${ScreenSize.small}){
        padding: 10px;
        
        img {
            max-width: 20px;
        }
        
        img[class*=rp-question-riskRetireIn]{
            max-width: 40px;  
        }
        
        img[class*=rp-question-riskNegativeReturns]{
            max-width: 50px;  
        }   

        img[class*=rp-question-riskLowRiskInvestments]{
            margin-bottom: 2px;
            max-width: 45px;  
        }

        img[class*=rp-question-riskLongTermDecline]{
            max-width: 45px;  
        }

        img[class*=rp-question-riskFluctuations]{
            max-width: 80px;
        }

    }
`;

// rp extra info
export const RiskProfileExtraInfo = styled(Card)`
    background-color: ${themeGet('colors.riskProfileInfoBackground')};
    padding: 25px;
    width: 100%;

    @media (max-width: ${ScreenSize.small}) {
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px);
    }
`;

export const RiskProfileExtraTitleCard = styled(Card)`
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    @media (max-width: ${ScreenSize.small}) {
        margin-bottom: 15px;
    }
`;

export const RiskProfileExtraTitle = styled(Heading)`
    margin-left: 15px;
`;

export const RiskProfileExtraContentCard = styled(Card)`
    margin-left: 55px;

    @media (max-width: ${ScreenSize.small}) {
        margin-left: 0;
    }
`;

export const RiskProfileExtraSourceLink = styled(Text)`
    white-space: nowrap;

    * {
        white-space: pre-wrap;
        overflow-wrap: break-word;
    }

    &, * { 
        font-size: 12px;
    }
    &.question-5 {
        white-space: pre-wrap !important;
    }

    @media (max-width: ${ScreenSize.small}) {
        display: block;
        text-align: center;
    }
`;

export const RiskProfileExtraInfoRow = styled(Row)`
    @media (max-width: ${ScreenSize.small}) {
        div:not(:last-child) {
            margin-bottom: 2em;
        }

        .link-pretext {
            display: block;
            margin-top: 1em;
        }

        &.responsive-reversed {
            div:last-child {
                margin-bottom: 1em;
            }

            div:not(:last-child) {
                margin-bottom: 0.5em;
            }

            flex-direction: column-reverse;
        }
    }

    .inflation-image-col {
        @media (max-width: ${ScreenSize.small}) {
            display: flex;
            justify-content: center;
        }

        img {
            width: 100%;

            @media (min-width: ${ScreenSize.medium}) {
                width: 90%;
            }

            @media (max-width: ${ScreenSize.small}) {
                width: 80%;
            }
        }
    }
`;
export const RiskProfileResult = styled(Row)`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    
    margin: 10px;
    text-align: center;

    @media (max-width: ${ScreenSize.small}) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const RiskProfileInfo = styled(Card)`
    margin: 0;
    background-color: ${themeGet('colors.riskProfileInfoBackground')};
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
`;

export const RiskProfileResponseHeading = styled(Heading)`
    font-size: 18px !important;
    margin: 10px 15px 0px 15px !important; 
`;

export const RiskProfileResponseContent = styled(Text)`
    color: ${themeGet('colors.brandPrimaryLight')} !important;
    font-weight: bold;
    margin: 0px 5px;
    height: 100%;
    display: flex;
    align-items: end;
    justify-content: center;
`;

export const RiskProfileResponseDescription = styled(Text)`
    margin: 15px 10px;
    &.p:first-child {
        margin-top: 10px !important;
    }
`;

export const TriageModal = styled(Modal)`
    &__reactModal {
       @media (max-width: ${ScreenSize.medium}) {
           width: 90vw !important;
           max-width: 90vw !important;
       }
       overflow-x: hidden;
    }
    div[class*=mlcWhite] {
        .closeIcon {
            color:  ${themeGet('colors.brandPrimaryLight')} !important;
    
            &:hover{
                color:  ${themeGet('colors.brandPrimaryDark')} !important;
            }
        }
    }


   ${props => props.wideFooterButton ? `div[class*=ModalFooter] {
       button {
           width: 100%;
       }
    }` : ''}
   
    div[class*=ModalFooter] {
        display: flex;
        justify-content: right;
        max-width: 100%;
        flex-wrap: wrap;


        button {
            background-color:  ${themeGet('colors.brandPrimaryLight')};
            @media (max-width: ${ScreenSize.small}) {
                display: block;
                width: 100%;
            }
        }
        
        .modalFooterLeftButton {
            background-color: white !important;
            color:  black
            &:hover{
                background-color: ${themeGet('colors.brandPrimaryDark')} !important;
                color: white;
            }
            margin-left: 15px;
            margin-right: 30px;
            @media (max-width: ${ScreenSize.small}) {
                display: block;
                width: 100%;
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
        
        .modalFooterRightButton {
            margin-left: 0;
            margin-right: 15px;
            @media (max-width: ${ScreenSize.small}) {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
`;

export const TriageTextLink = styled(TextLink)`
    display: list-item;
    color: black;
    text-decoration-line: underline;
    width: fit-content;
    &:hover {    
        color: black;
    }
`;

export const BlackTextLink = styled(TextLink)`
    color: black; 
    &:hover {    
        color: black;
    }   
`;

// intrinsic height fallback for safari
export const IndentedWithIcon = styled(Card)`
    .indent-icon, .indent-icon-mobile {
        height: fit-content;
        height: intrinsic;
        margin-left: -10px;
        margin-right: 10px;
    }

    @media (min-width: ${ScreenSize.small}) {
        display: flex;
        
        .indent-icon-mobile {
            display: none;
        }
    }

    @media (max-width: ${ScreenSize.small}) {
        .indent-container-mobile {
            display: flex;
            align-items: center;
        }

        .indent-icon {
            display: none;
        }
    }
`;

export const RpSectionContainer = styled(Card)`
    width: 100%;

    .kate-aligned {
        @media (min-width: ${ScreenSize.small}) {
            margin-top: 25px;
        }

        @media (min-width: ${ScreenSize.medium}) {
            margin-top: 30px;
        }
    }

    .kate-aligned-multiline {
        @media (min-width: ${ScreenSize.small}) {
            margin-top: 15px;
        }
    }
`;

export const RegularContributionsSectionContainer = styled(Card)`
    width: 100%;

    @media (max-width: ${ScreenSize.small}) {
        margin-top: 20px;
    }

    @media (min-width: ${ScreenSize.small}) {
        margin-top: 25px;
    }

    @media (min-width: ${ScreenSize.medium}) {
        margin-top: 30px;
    }
`;

export const XXSWrapTableCell = styled(TableCell)`
    @media (max-width: ${ScreenSize.xxSmall}) {
        overflow-wrap: anywhere;
    }
`;

export const PreSoaIndicator = styled(Card)`
    width: 100vw;
    margin: 100px 0 -30px calc(50% - 50vw); 
    background-color: ${themeGet('colors.detailsBackground')};
    padding: 30px 0;

    @media (min-width: ${ScreenSize.small}) {
        .soa-img {
            margin-right: 25px;
        }
        
        .pre-soa-container {
            display: flex;
            align-items: center;
        }

        .soa-img-mobile {
            display: none;
        }
    }

    @media (max-width: ${ScreenSize.small}) {
        .soa-img {
            display: none;
        }

        .pre-soa-container-mobile {
            display: flex;
            flex-wrap: wrap;
        }

        .soa-img-mobile {
            margin-right: 10px;
        }

        .own-line-mobile {
            width: 100%;
            margin-top: 1.5em;
        }
    }
`;

export const RiskProfileModal = styled(WiderModal)`
    &__reactModal {
        top: auto;
        max-height: 100vh;

        @media (max-width: ${ScreenSize.medium}) {
            max-height: 95vh;
        }

        @media (max-width: ${ScreenSize.xSmall}) {
            max-height: 90vh;
        }
    }
`;

export const InvestmentPreferenceSection = styled.div`
    margin-top: 30px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0,1fr));
    grid-template-rows: repeat(3, auto);
    grid-auto-flow: column;
    grid-gap: 0 10px;
    @media(min-width: ${ScreenSize.small}){
        grid-gap: 0 35px;
    }    
`;

export const InvestmentPreference = styled.div`
    display: contents;
    div {
        filter: grayscale(1);
    }
    .selectionButton {
        display: flex;
        height: 100%;
        align-items: center;
        width: 100%;
        border-style: none;
        @media(min-width: ${ScreenSize.small}){
            gap: 10px;
        }
        background-color: lightgray;
        &:hover{
            cursor: pointer;
            text-decoration: underline;
        }
        p {
            justify-self: start;
            align-self: center;
        }
        svg {
            color: white;
        }
        .fa-circle {     
            stroke: darkgray;
            stroke-width: 20px;
        }
        .fa-check-circle {            
            outline: darkgray;
            stroke-width: 20px;
        }
        .Button__wrapper {  
            width: 100%;
        }
    }
    &.selected {
        div {
            background-color: ${themeGet('colors.detailsBackground')};
            filter: grayscale(0);
        }
        div.expandButton {
            Button{
                background-color: ${themeGet('colors.detailsBackground')};
            }
        }
        .selectionButton {
            * {
                color: white;
            }
            background-color: ${themeGet('colors.brandPrimaryDark')};
        }
    }
    .content {
        display: block;
        padding: 10px 10px 15px 10px;
    }
    .options__children {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    div.image{  
        padding-top: 10px;
        display:flex;
        justify-content: center;
    }
`;

export const InvestmentPreferencesContent = styled.div`
    :first-child {
        border-top: 1px solid ${palette.mlcLightGrey};
        border-left: 1px solid ${palette.mlcLightGrey};
        border-right: 1px solid ${palette.mlcLightGrey};
        border-bottom: 1px solid ${palette.mlcLightGrey};
    }
    :not(:first-child):not(:last-child) {
        border-left: 1px solid ${palette.mlcLightGrey};
        border-right: 1px solid ${palette.mlcLightGrey};
    }
    :last-child {
        border-bottom: 1px solid ${palette.mlcLightGrey};
        border-left: 1px solid ${palette.mlcLightGrey};
        border-right: 1px solid ${palette.mlcLightGrey};
    }
`;

export const FinishedRPButton = styled.div`
    padding: 5% 5%;
    align-items: center;
`;

export const PreferenceFSGLink = styled(TextLink)`
    font-size: 14px;
`;

export const RetirementGoalsHeadingRow = styled(Row)`
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 10px;
`;


export const RetirementGoalsImage = styled.div`
    display: flex;
    align-items: center;
    
    @media (min-width: ${ScreenSize.xSmall}) {
        img {
            padding: 5px;
            width: auto;
            height: 93px;
        }
    }

    @media (max-width: ${ScreenSize.xSmall}) {
        justify-content: center;
        img {
            height: 83px;
        }
    }

    @media (max-width: ${ScreenSize.xxSmall}) {
        img {
            height: 74px;
        }
    }

    @media (max-width: ${ScreenSize.xxxSmall}) {
        img {
            height: 66px;
        }
    }
`;

export const RetirementGoalsSection = styled.div`
    width: 100%;
    display: grid;
    margin-top: 10px;
    grid-template-columns: repeat(3, minmax(0,1fr));
    grid-template-rows: repeat(6, auto);
    grid-auto-flow: column;
    grid-gap: 0px 10px;
`;

export const RetirementGoalsSelection = styled.div`
    display: contents;
    
    div {
        filter: grayscale(1);    
    }

    .selectionButton {
        display: grid;
        width: 100%;
        border-style: none;
        &:hover{
            cursor: pointer;
            text-decoration: underline;
        }
        svg {
            color: white;
        }
        .fa-circle {     
            stroke: darkgray;
            stroke-width: 20px;
        }
        .fa-check-circle {            
            outline: darkgray;
            stroke-width: 20px;
        }
    }

    &.selected {
        div {
            background-color: ${themeGet('colors.detailsBackground')};
            filter: grayscale(0);
        }
        
        .annualValue {
            color: ${themeGet('colors.brandPrimaryDark')};
        }

        .expandButton {
            Button{
                background-color: ${themeGet('colors.detailsBackground')};
            }
        }

        .selectionButton {
            * {
                color: white;
            }
            background-color: ${themeGet('colors.brandPrimaryDark')};
        }
    }

    div.image {
        display:flex;
        justify-content: center;
    }

    div.expandButton {
        display: flex;
        justify-content: end;

        Button{
            border-style: none;
            background-color: white;
        }
    }

    @media (max-width: ${ScreenSize.small}){
        .mobile {
            padding-left: 70px;
            text-align: start;
        }
    }
`;

export const LifestyleContent = styled.div`
    :first-child {
        border-top: 1px solid ${palette.mlcLightGrey};
        border-left: 1px solid ${palette.mlcLightGrey};
        border-right: 1px solid ${palette.mlcLightGrey};
    }

    :not(:first-child):not(:last-child) {
        border-left: 1px solid ${palette.mlcLightGrey};
        border-right: 1px solid ${palette.mlcLightGrey};
    }

    :last-child {
        border-bottom: 1px solid ${palette.mlcLightGrey};
        border-left: 1px solid ${palette.mlcLightGrey};
        border-right: 1px solid ${palette.mlcLightGrey};
    }

    &.expanded {
        :last-child {
            :not(.selected){
                border-bottom: 1px solid ${palette.mlcWhite};
            }
            &.selected {
                border-bottom: 1px solid ${themeGet('colors.detailsBackground')};
            }
        }
    }
`;

export const CustomLifestyle = styled.div`
    padding-top: 5px;
    display: grid;
    margin-left: 10px;
    margin-right: 5px;
    grid-template-columns: repeat(2,auto);

    @media (max-width: ${ScreenSize.small}) {
        display: flex;
        margin-left: 35px;
        margin-bottom:-10px;
    }
`;

export const LifestyleLink = styled.div`
    padding: 5%;
    * {
        color: black;
        font-size: 12px;
        white-space: pre-wrap;
    }
    @media (max-width: ${ScreenSize.small}){
        padding: 0;
        margin: 0 0 10px 70px;
        
        &.customhide {
            margin: 0 0 10px 20px;
        }
        
        &.selected {
            margin: 0 0 10px 10px; 
        }
        display: flex;
        flex-wrap: wrap;
        span{
            white-space: pre;
            place-self: flex-end;
        }
    }
`;

export const ExpandButton = styled(Button)`
    padding-bottom: 5px;
    padding-right: 10px;
    color: black;
    &:hover {
        cursor: pointer;
    }
`;

export const ExpandContentContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0,1fr));
    grid-template-rows: repeat(8, auto);
    grid-auto-flow: column;
    grid-gap: 0px 10px;

    overflow: hidden;
    max-height: 0px;
    transition: 0.5s max-height ease-in-out;
    &.expanded {
        max-height: 70%;
    }
`;

export const ExpandContent = styled.div`
    display: contents;
    div {
        filter: grayscale(1);
    }
    Paragraph {
        padding: 0% 5%;
    }

    &.selected {
        div {
            background-color: ${themeGet('colors.detailsBackground')};
            filter: grayscale(0);
        }

`;

export const ImageWithText = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 5% 5% 0px;
    margin: 10px;
    align-items: center;
    p.selected {
        color: ${themeGet('colors.brandPrimaryDark')};
    }
    @media (min-width: ${ScreenSize.small}){
        img { 
            object-fit: contain;
            width: 70%;
            height: 70px;
        }
        border-top: solid 1px ${palette.mlcLightGrey};
    }

    
    p {
        display: grid;
        align-content: center;
        margin-top: 10px;
        grid-column-start: 2;
        grid-column-end: 4;   
    }

    @media (max-width: ${ScreenSize.small}){
        grid-template-columns: 70px auto;
        padding: 10px 0 0;
        grid-gap: 10px;
        &.lifestyle{
            padding: 0px;
            margin-top: -5px;
        }
        :not(.lifestyle){
            border-top: solid 1px ${palette.mlcLightGrey};
        }
        img.lifestyle {
            width: 70px;
        }
        img.custom {
            width: 54px;
            max-height: 80px;
        }
        p.custom {
            padding-top: 10px;
            line-height: 18px;
        }
        img.apronman {
            max-height: 110px;
            width: 54px;
        }
        img.popcorn {
            width: 30px;
        }
        img { 
            width: 40px;
            align-self: center;
            justify-self: center;
        }
    }
`;



export const ExpandLifestyleContent = styled.div`
    :first-child {
        border-left: 1px solid ${palette.mlcLightGrey};
        border-right: 1px solid ${palette.mlcLightGrey};
    }

    :not(:first-child):not(:last-child) {
        border-left: 1px solid ${palette.mlcLightGrey};
        border-right: 1px solid ${palette.mlcLightGrey};
    }

    :last-child {
        border-bottom: 1px solid ${palette.mlcLightGrey};
        border-left: 1px solid ${palette.mlcLightGrey};
        border-right: 1px solid ${palette.mlcLightGrey};
        @media (max-width: ${ScreenSize.small}){
            padding-bottom: 20px;
        }
    }
`;

export const ExpandParagraph = styled(Paragraph)`
    padding: 5%;
`;

export const LifestyleButton = styled.div`
    padding: 10% 5%;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    @media (max-width: ${ScreenSize.small}){
        padding: 10px 15px;
        grid-gap: 20px;
    }
    h2 {
        font-size: 18px;
    }
`;

export const AsfaLink = styled(Paragraph)`
    padding: 0% 5%;
    margin-top: -20px;
    * {
        color: black !important;
    }
`;

export const ExtendedCard = styled(Card)`
    position: relative;
    left: 50%;
    margin-left: -50vw;    
    width: 100vw;
    &.with-background {
        background: ${themeGet('colors.detailsBackground')};
    }
    &.without-background {
        background: ${themeGet('colors.mlcWhite')};
    }
    
`;

export const ButtonRow = styled.div`
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(3, minmax(min-content, max-content));
    grid-column-gap: 10px;
    place-content: end;
`;

export const RiskProfileMobileHeading = styled.div`
    padding: 20px 0;
    display: grid;
    @media (min-width: ${ScreenSize.small}){
        grid-template-columns: repeat(2, minmax(min-content, max-content));
        img {  
            margin-left: 25px;
            display: flex;
            align-self: center;
        }
    }
`;

export const RiskProfileMobileHeadingContent = styled.div`
    display: flex;
    img {
        width: 70px;
        object-fit: contain;
        margin: -15px 10px 10px 10px;
    }

    @media(min-width: ${ScreenSize.small}){
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-end;
    }
`;

export const RiskProfileSelectionSection = styled.div`
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0,1fr));
    grid-template-rows: repeat(6, auto);
    grid-auto-flow: column;
    grid-gap: 0 10px;

    @media(min-width: ${ScreenSize.small}){
        grid-gap: 0 35px;
    }    
`;

export const RiskProfileSelection = styled.div`
    display: contents;
    div {
        filter: grayscale(1);
    }

    .selectionButton {
        display: flex;
        height: 100%
        align-items: center;
        width: 100%;
        border-style: none;
        @media(min-width: ${ScreenSize.small}){
            gap: 10px;
        }
        background-color: ${themeGet('colors.detailsBackground')};
        &:hover{
            cursor: pointer;
            text-decoration: underline;
        }
        p {
            justify-self: start;
            align-self: center;
        }
        svg {
            color: white;
        }
        .fa-circle {     
            stroke: darkgray;
            stroke-width: 20px;
        }
        .fa-check-circle {            
            outline: darkgray;
            stroke-width: 20px;
        }

        .Button__wrapper {  
            width: 100%;
        }
    }

    &.selected {
        div {
            background-color: ${themeGet('colors.detailsBackground')};
            filter: grayscale(0);

        }

        div.expandButton {
            Button{
                background-color: ${themeGet('colors.detailsBackground')};
            }
        }

        .selectionButton {
            * {
                color: white;
            }
            background-color: ${themeGet('colors.brandPrimaryDark')};
        }
    }

    div.expandButton {
        display: flex;
        justify-content: end;

        Button{
            border-style: none;
            background-color: white;
        }
    }
    div.maintainBorder {
        padding: 0px 10px 10px;
    }

`;

export const MaintainBorder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 10px 10px 10px;
    border-bottom: 1px solid black;
`;

export const NewRiskProfileContent = styled.div`
    padding: 10px 10px 0px 10px;
`;

export const RiskProfileSelectionContent = styled.div`
    border-left: 1px solid ${palette.mlcLightGrey};
    border-right: 1px solid ${palette.mlcLightGrey};
    padding-top: 10px;
    display : flex;
    justify-content: center;
`;

export const PieChartContent = styled.div`
    padding: 10px 5% 10px 5%;
    @media (min-width: ${ScreenSize.small}){
        padding: 10px 15% 10px 15%;
    }

    border-left: 1px solid ${palette.mlcLightGrey};
    border-right: 1px solid ${palette.mlcLightGrey};



    text{
        font-size: 7px;
        fill: white;
    }
`;

export const AllocationLegend = styled.div`
    @media (min-width: ${ScreenSize.xxSmall}){
        display: grid;
        grid-template-columns: repeat(2, minmax(0,1fr));
    }
`;

export const AllocationSection = styled(Card)`
    display: flex;
    padding-left: 15px;
    gap: 10px;
    @media (max-width: ${ScreenSize.small}){
        gap: 5px;
        padding-left: 5px;
    }
    svg {
        align-self: center;
    }
    @media (min-width: ${ScreenSize.xSmall}){
        justify-items: center;
    } 
`;

export const ExpandRiskProfileContentContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0,1fr));
    grid-template-rows: repeat(6, auto);
    grid-auto-flow: column;
    grid-gap: 0 10px;
    @media(min-width: ${ScreenSize.small}){
        grid-gap: 0 35px;
    }
    overflow: hidden;
    max-height: 0px;
    transition: 1s max-height ease-in-out;
    &.expanded {
        max-height: 250vh;
        @media(min-width: ${ScreenSize.small}){
            max-height: 125vh;
        }
    }
`;

export const ExpandContentRiskProfile = styled.div`
    display: contents;
    div {
        filter: grayscale(1);
    }
    
    Paragraph {
        padding: 5%;
    }

    &.selected {
        div {
            background-color: ${themeGet('colors.detailsBackground')};
            filter: grayscale(0);
        }

`;

export const ExpandRiskProfileContent = styled.div`
    :first-child {
        padding-top: 10px;
        border-left: 1px solid ${palette.mlcLightGrey};
        border-right: 1px solid ${palette.mlcLightGrey};
    }

    :not(:first-child):not(:last-child) {
        border-left: 1px solid ${palette.mlcLightGrey};
        border-right: 1px solid ${palette.mlcLightGrey};
    }

    :last-child {
        border-bottom: 1px solid ${palette.mlcLightGrey};
        border-left: 1px solid ${palette.mlcLightGrey};
        border-right: 1px solid ${palette.mlcLightGrey};
        @media (max-width: ${ScreenSize.small}){
            margin-bottom: 10px;
        }
    }

    &.investmentInfo{
        @media (min-width: ${ScreenSize.small}){
            &.topPadding {
                padding-top: 30px;
            }    
        }
        padding: 5% 0 0;
        &.bottomBorder {
            padding-bottom: 5%;
        }
        
    }

`;

export const ExpandRiskProfileText = styled.div`
    margin: 5px 5px 10px 5px;
    @media (min-width: ${ScreenSize.small}){
        margin: 5px 30px 15px 40px;  
    }
`;

export const MaintainRiskProfileBorder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid ${palette.mlcLightGrey};
    padding-top: 10px;
    height: 100%;
`;

export const ExpandRiskProfileBorder = styled.div`
    display: grid;
    justify-items: center;
    border-top: 1px solid ${palette.mlcLightGrey};
    padding-top: 5%;
`;

export const ContributionsHeading = styled.div`
    padding: 20px 0;
    display: grid;
    @media (min-width: ${ScreenSize.small}){
        grid-template-columns: repeat(2, minmax(min-content, max-content));
        img {  
            margin-left: 25px;
            display: flex;
            align-self: center;
        }
    }
`;

export const ContributionImageWithText = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(min-content, max-content));
    padding: 20px 30px;
    justify-items: center;
    @media(max-width: ${ScreenSize.small}){
        padding: 10px 0;
        img {
            content-visibility: hidden;
        }
    }
    img { 
        margin-top: 5px;
        padding: 5px;
        object-fit: contain;
        width: 100%;
        max-height: 80px;
    }

    ul {
        margin: 0;
    }
`;

export const RegularContributionsAccordion = styled(Accordion)`
    margin-bottom:15px;

    Button:first-child {
        background-color: ${themeGet('colors.detailsBackground')};
    }
    .definitions{
        background-color: white !important;
    }

    :not(.open) {
        border: none;
        Button{        
            border: 1px solid ${palette.mlcLightGrey} !important;
        }
    }

    .open {
        border: 1px solid ${palette.mlcLightGrey} !important;
        Button{        
            border: none;
        }
    }

    border-color: ${palette.mlcLightGrey} !important;

    .Accordion__icon {
        display: flex;
        align-items: center;

        &[hidden] {
            display: none;
        }
    }
`;

export const ContributionsHeadingContent = styled.div`
    display: flex;
    img {
        width: 70px;
        object-fit: contain;
        margin: -15px 10px 10px 10px;
    }

    @media(min-width: ${ScreenSize.small}){
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-end;
    }
`;

export const RegularContributionsInputs = styled.div`
    padding-top: 5px;
    display: grid;
    grid-template-columns: 45vw 45vw;
    @media (min-width: ${ScreenSize.small}){
        grid-template-columns: repeat(2,250px);
    }
    grid-gap: 10px;
`;

export const RegularContributionsWrapper = styled.div`
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    margin-top: 10px; 
`;

export const NewRiskProfileSelect = styled(Select)`
    .Select {
        font-weight: bold;
        white-space: pre-wrap;
        height: fit-content;
        text-overflow: ellipsis;
    }
`;

export const MobileExpandWrapper = styled.div`
    display: grid;
    grid-template-rows: repeat(6, auto);
    

    overflow: hidden;
    max-height: 0px;
    transition: 0.5s max-height ease-in-out;
    &.expanded {
        max-height: 70%;
    }
`;

export const MobileLifestyleWrapper = styled.div`
    display: grid;
    grid-template-rows: repeat(4, minmax(min-content, max-content));
    margin-top: 25px;
`;

export const MobileTextWrapper = styled.div`
    p {
        margin-bottom: 0;
    }
    p.selected {
        color: ${themeGet('colors.brandPrimaryDark')};
    }
`;

export const RetirementGoalsText = styled.div`
    display: flex;
    align-items: center;
`;
