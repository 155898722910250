import { get } from './fetch';
import { errorInResponse, redirectToError } from './util';

export const authorise = async () => {
    try {
        const data: any = await get(`/members/authorise`, { saveXsrf: true, noErrorRedirect: true });
        if (errorInResponse(await data)) {
            redirectToError(500);
        }
        return data.xsrf;
    } catch (e) {
        return undefined;
    }
}