export const staticContent = {
    reviewHeader: "Review your retirement goals",
    reviewContent: "Play with the options below to see how making small changes can impact your retirement. Once you're happy with your updates, and all the information is accurate, it's time to generate your personalised super report.",
    retirementAgeHeader: "Consider your retirement age",
    retirementAgeContent: "Working for a few extra years could help you achieve your ideal retirement lifestyle.",
    retirementIncomeHeader: "Take into account your lifestyle",
    retirementIncomeContent: "The amount of super you need to save depends on the type of lifestyle you want to live in retirement.",
    riskProfileHeader: "Explore different levels of risk",
    riskProfileContent: "The returns you get depend on how much risk you're willing to take with how your super is invested.",
    contributionsHeader: "How much can you afford?",
    contributionsContent: "Making extra contributions can help boost your retirement savings."
}