import React, { HTMLAttributes, SelectHTMLAttributes } from 'react';
import classnames from 'classnames';
import { chevronDown } from '@mlc/symbols';

import { Svg, LoadingIndicator } from '@mlc/web-ui-toolkit';
import { UitkBaseProps, FieldWrapperClone, UitkStatics } from '@mlc/web-ui-toolkit/src/typings';
import { StyledSelect } from './Select.style';

// take toolkit select so we can remove the placeholder option
type SelectProps = {
	isInvalid?: boolean;
	onChange?: (...args: any[]) => any;
	placeholder?: string;
	value?: string | any[] | number;
	options?: Array<any>;
	width?: string | object;
	variant?: 'block' | 'inline';
	isLoading?: boolean;
};
export const Select = ({
	id,
	placeholder,
	name,
	value,
	options,
	isInvalid,
	onChange,
	ariaLabelledby,
	ariaDescribedby,
	isFormField,
	className,
	variant,
	width,
	forwardedRef,
	isLoading,
	...restProps
}: UitkBaseProps &
	SelectProps &
	HTMLAttributes<HTMLSelectElement> &
	SelectHTMLAttributes<HTMLSelectElement> &
	FieldWrapperClone &
	UitkStatics) => {
	return (
		<StyledSelect className={className}>
			<select
				aria-describedby={ariaDescribedby}
				aria-labelledby={ariaLabelledby}
				aria-invalid={isInvalid}
				onChange={onChange}
				id={id}
				name={name}
				value={value || ''}
				{...restProps}
				className={classnames('Select', isInvalid ? 'Select--isInvalid' : '')}
				ref={forwardedRef}
			>
				{options &&
					options.map((item, index) => {
						const label =
							typeof item === 'string' ? item : item.label ? item.label : null;
						const value =
							typeof item === 'string' ? item : item.value ? item.value : '';
						return (
							<option key={value + index} value={value}>
								{label}
							</option>
						);
					})}
			</select>
			{isLoading ? (
				<div className="Select__icon">
					<LoadingIndicator variant="inline" colorTheme="dark" />
				</div>
			) : (
				<Svg className="Select__icon" symbol={chevronDown} />
			)}
		</StyledSelect>
	);
};

Select.defaultProps = {
	placeholder: 'Please select',
};
Select.isFormField = true;

export default Select;
