import takeAction from '../../components/presentational/TakeAction/TakeActionDetails/StaticContentMksf';
import importantInfo from '../../components/presentational/ImportantInfo/StaticContentMlc';
import retirementGoals from '../../components/presentational/CalculatorTab/CalculatorSteps/RetirementGoals/StaticContentMlc';
import regularContributions from '../../components/presentational/CalculatorTab/CalculatorSteps/RegularContributions/StaticContentMlc';
import aboutYou from '../../components/presentational/CalculatorTab/AboutYou/StaticContentMksf';
import superReport from '../../components/presentational/SuperReport/StaticContentMksf';
import error from '../../components/presentational/ErrorPage/StaticContentMlc';
import calculator from '../../components/presentational/CalculatorTab/StaticContentMlc';
import footer from '../../components/presentational/Footer/StaticContentMlc';
import riskProfile from '../../components/presentational/CalculatorTab/CalculatorSteps/RiskProfile/StaticContentMksf';
import retirementProjection from '../../components/presentational/CalculatorTab/CalculatorSteps/RetirementProjection/StaticContentMlc';

const mksfContent = {
    takeAction,
    importantInfo,
    retirementGoals,
    regularContributions,
    aboutYou,
    superReport,
    error,
    calculator,
    footer,
    riskProfile,
    retirementProjection
}

export default mksfContent;