import React, { FunctionComponent } from 'react';
import Media from 'react-media';
import { ScreenSize } from '../../../enums';

interface Props {
	smallWidth: ScreenSize;
	content: any;
	mobileContent: any;
}

/**
 * Executes a query to check the screen min width before attempting to
 * render the children.
 *
 * @param minWidth the minimum width to check before rendering the children
 * @param children the content to render
 */
const MediaQ: FunctionComponent<Props> = ({ smallWidth, mobileContent, content }) => {
	return (
		<Media
			queries={{
				small: `(max-width: ${smallWidth})`
			}}
		>
			{matches => (
				<>
					{matches.small ? mobileContent : content}
				</>
			)}
		</Media>
	);
};

const MobileDeviceQ = ({ content, mobileContent }: { content: any, mobileContent: any }) => {
	return <MediaQ smallWidth={ScreenSize.small} content={content} mobileContent={mobileContent} />;
};

export default MediaQ;
export { MobileDeviceQ };
