import { Card, Heading, TextLink, Text } from '@mlc/web-ui-toolkit';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as palette from '@mlc/mlc-palette';
import { ScreenSize } from '../../../enums';

//a4 dimensions to match pdf
const getHeight = (width) => `height: ${width * (21 / 29.7)}px`;

// pixel values for widths, need to set static widths for pdf viewer Page component and stopping flickering when swapping pages/scaling
const pdfWidthValues = {
    default: 840,
    large: 800,
    medium: 600
}

const sidebarWidthValues = {
    default: 210,
    large: 200,
    medium: 160
}

const sidebarHeight = `
    @media (max-width: ${ScreenSize.large}) {
        ${getHeight(pdfWidthValues.large - sidebarWidthValues.large)};
    }

    @media (max-width: ${ScreenSize.medium}) {
        ${getHeight(pdfWidthValues.medium - sidebarWidthValues.medium)};
    }

    @media (max-width: ${ScreenSize.small}) {
        height: calc((90vw - 100px) * (21 / 29.7));
    }
`;

const pdfHeight = `
    ${getHeight(pdfWidthValues.default)};

    @media (max-width: ${ScreenSize.large}) {
        ${getHeight(pdfWidthValues.large)};
    }

    @media (max-width: ${ScreenSize.medium}) {
        ${getHeight(pdfWidthValues.medium)};
    }

    @media (max-width: ${ScreenSize.small}) {
        height: calc((90vw - 100px) * (21 / 29.7));
    }

    @media (min-width: ${ScreenSize.pdfViewerChange}) {
        &.sidebarOpen {
            ${sidebarHeight}
        }
    }
`;

const pdfWidth = `
    width: ${pdfWidthValues.default}px;

    @media (max-width: ${ScreenSize.large}) {
        width: ${pdfWidthValues.large}px;
    }

    @media (max-width: ${ScreenSize.medium}) {
        width: ${pdfWidthValues.medium}px;
    }

    @media (max-width: ${ScreenSize.small}) {
        width: calc(90vw - 100px);
    }

    @media (min-width: ${ScreenSize.pdfViewerChange}) {
        &.sidebarOpen {
            @media (max-width: ${ScreenSize.large}) {
                width: ${pdfWidthValues.large - sidebarWidthValues.large}px;
            }

            @media (max-width: ${ScreenSize.medium}) {
                width: ${pdfWidthValues.medium - sidebarWidthValues.medium}px;
            }

            @media (max-width: ${ScreenSize.small}) {
                width: calc(90vw - 260px);
            }
        }
    }
`;

const fullscreenWidth = `
    width: 75vw;

    @media (min-aspect-ratio: 16/9) {
        width: 65vw;      
    }
`;

const fullscreenPdfHeight = `85vh`;

export const PdfViewerLoadingPlaceholder = styled.div`
    ${pdfHeight};
    ${pdfWidth};
`;

export const PdfViewerLoadingContainer = styled.div`
    ${pdfHeight};

    [class*="LoadingIndicatorstyle__LoadingWrapper"] {
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    [class*="LoadingIndicatorstyle__LoadingSpinner"] {
        width: 60px;
    }
`;

export const PdfViewerDoc = styled(Document)`
    ${pdfHeight};
    ${pdfWidth};
    position: relative;
    overflow: auto;

    .react-pdf__Page__canvas {
        position: relative;
    }
`;

export const PdfViewerHeading = styled(Heading)`
    color: white;
    font-size: 26px;
    margin-bottom: 10px;

    @media (max-width: ${ScreenSize.small}) {
        font-size: 22px;
    }
`;

export const PdfViewerPage = styled(Page)`
    &.prevPage {
        position: absolute !important;
        z-index: 1;
    }
`;

export const PdfViewOverlay = styled(Card)`
    background-color: ${themeGet('colors.brandPrimaryLight')};
    padding: 20px 5px 10px 5px;
    z-index: 2;
    overflow: hidden;
    width: 100%;

    // fullscreen resizing to use viewport units
    &.fullscreenPdf {
        .fsResizeSideContainer {
            display: flex;
            align-items: center;
            height: 100%
        }

        .fsResizeSidebar {
            margin-top: 0;
            width: 22.5vw;
            height: 100%;

            // sidebar heading
            .sidebarHeading {
                @media (min-width: ${ScreenSize.large}) {
                    span, svg {
                        font-size: 26px;
        
                        &.MuiSvgIcon-root {
                            font-size: 31px;
                        }
                    }
                }
            }
        }

        .fsResizeDoc {
            ${fullscreenWidth}
            height: ${fullscreenPdfHeight};
            display: flex;

            &.sidebarOpen {
                width: 65vw;
            }
        }

        .fsResizeLoading {
            ${fullscreenWidth}
            height: ${fullscreenPdfHeight};
        }

        .fsResizeNav {
            width: 7.5vw;
            position: relative;

            // moves to middle of displayed page height
            top: calc(38vw * (21 / 29.7));
            
            @media (min-aspect-ratio: 16/9) {
                top: calc(31.5vw * (21 / 29.7));        
            }

            &.sidebarOpen {
                top: calc(31.5vw * (21 / 29.7));
            }

            @media (max-width: ${ScreenSize.pdfViewerChange}) {
                width: 10vw;
            }
        }

        .fsResizeHeading {
            font-size: min(3vw, 4vh);
            line-height: min(3vw, 4vh);
            margin-bottom: min(1vw, 1vh); 
        }

        .fsResizeControls {
            svg, span {
                font-size: min(3vw, 4vh);
                line-height: min(3vw, 4vh);
        
                &.MuiSvgIcon-root {
                    font-size: min(calc(3vw + 5px), calc(4vh + 5px));
                    line-height: min(calc(3vw + 5px), calc(4vh + 5px));
                }
            }

            padding: 10px 10vw;

            @media (max-width: ${ScreenSize.pdfViewerChange}) {
                svg, span {
                    font-size: min(5vw, 6vh);
                    line-height: min(5vw, 6vh);
            
                    &.MuiSvgIcon-root {
                        font-size: min(calc(5vw + 5px), calc(6vh + 5px));
                        line-height: min(calc(5vw + 5px), calc(6vh + 5px));
                    }
                }

                padding: 0;
            }
        }

        .fsResizeToc {
            @media (min-width: ${ScreenSize.large}) {
                a, svg {
                    font-size: 16px;
    
                    &.MuiSvgIcon-root {
                        font-size: 21px;
                    }
                }
            }
        }
    }
`;

export const PdfViewerButton = styled.button`
// remove default button styling
    background: none;
	border: none;
    display: flex;
    justify-content: center;

    z-index: 1;
    height: 100%;
    width: 60px;
    margin-top: 12.5px;

    &.prevButton {
        margin-right: 5px;
    }

    &.nextButton {
        margin-left: 5px;
    }

    &:disabled {
        opacity: 0.25;
    }

    &:not(:disabled) {
        cursor: pointer;
        &:hover, &:focus {
            svg {
                color: ${palette.mlcLightGrey};
            }
        }
    }

    @media (max-width: ${ScreenSize.small}) {
        width: 50px;
    }
`;

export const PdfViewerButtonIcon = styled(FontAwesomeIcon)`
    color: white;
    height: 100%;
    width: 100% !important;
`;

export const PdfViewerControls = styled(Card)`
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 10px 56px;

    @media (max-width: ${ScreenSize.pdfViewerChange}) {
        padding: 10px 10px;
    }
`;

export const PdfViewerControlsPageNum = styled(Text)`
    font-size: 18px;
    padding-left: 6px;
    padding-right: 6px;

    @media (max-width: ${ScreenSize.small}) {
        font-size: 16px;
    }
`;

export const PdfViewerControlsButton = styled.button`
    background: none;
    border: none;
    color: white;

    display: flex;
    align-items: center;

    &:disabled {
        opacity: 0.25;
    }

    svg {
        font-size: 20px;

        &.MuiSvgIcon-root {
            font-size: 25px;
        }
    }

    &:not(:disabled) {
        cursor: pointer;
        &:hover, &:focus {
            svg {
                color: ${palette.mlcLightGrey};
            }
        }
    }

    @media (max-width: ${ScreenSize.pdfViewerChange}) {
        &.hide-mobile {
            display: none;
        }
    }

    @media (max-width: ${ScreenSize.small}) {
        font-size: 10px;
        &.MuiSvgIcon-root {
            font-size: 12.5px;
        }
    }
`;

export const SideBarContainer = styled(Card)`
    ${pdfHeight};
    ${sidebarHeight};
    width: ${sidebarWidthValues.default}px;

    padding: 15px;
    margin-top: 45px;
    background-color: white;
    overflow-y: auto;

    &.tableOfContents {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
    }

    @media (max-width: ${ScreenSize.large}) {
        width: ${sidebarWidthValues.large}px;
    }
    
    @media (max-width: ${ScreenSize.medium}) {
        width: ${sidebarWidthValues.medium}px;
    }

    @media (max-width: ${ScreenSize.pdfViewerChange}) {
        display: none;
    }
`;

export const SideBarHeading = styled(Card)`
    display: flex;
    align-items: center;

    svg {
        color: ${themeGet('colors.brandPrimaryLight')};
        font-size: 20px;

        &.MuiSvgIcon-root {
            font-size: 25px;
        }

        @media (max-width: ${ScreenSize.small}) {
            font-size: 10px;
            &.MuiSvgIcon-root {
                font-size: 12.5px;
            }
        }
    }
`;

export const TableOfContentsItem = styled(TextLink)`
    font-size: 12px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: black;
    text-decoration: none;
    &:hover, &:focus {    
        color: black;
        text-decoration: underline;
    }
`;

export const TableOfContentsExpand = styled.button`
    // remove default button styling
    background: none;
    border: none;
    cursor: pointer;

    display: flex;
    align-items: center;
    margin-left: -1em;
    margin-right: 0.5em;
    padding: 0;
`;

export const PagePreviewItem = styled.button`
    // remove default button styling
    background: none;
    border: 1px solid black;
    padding: 0;
    cursor: pointer;

    &.currPage, &:hover, &:focus {
        outline: 2px solid black;
    }
`;

export const PdfViewerGrid = styled(Card)`
    & > .Grid__container {
        max-width: fit-content;
    }
`;

export const NavAndDocContainer = styled(Card)`
    display: flex;
    justify-content: center;

    :not(&.fullscreenPdf) {
        align-items: center;
    }
`;