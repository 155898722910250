import * as palette from '@mlc/mlc-palette';
import { Card, Col, Grid, Row } from '@mlc/web-ui-toolkit';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Calculator, FrequencyValues } from '../../../../logic/domains/calculator.domain';
import { logAction } from '../../../../logic/functions/logging.function';
import { focusFirstError, validateCalculator } from '../../../../logic/functions/util';
import { NavButtonGroup } from '../../Common/Common.style';
import { BackButton, NextButton } from '../../Common/NavButtons';
import calculatorStepsTestIds from './CalculatorSteps.elements';
import { CalculatorStepsDiv } from './CalculatorSteps.style';
import RegularContributions from './RegularContributions';
import RetirementGoals from './RetirementGoals/RetirementGoals';
import RetirementProjection from './RetirementProjection/RetirementProjection';
import RiskProfile from './RiskProfile/RiskProfile';

type Props = {
    formikProps: any;
    handleNextStep: () => void;
    handlePreviousStep: () => void;
    setUpdatingMember: () => void;
    setForceUpdating: () => void;
    nextRef: any;
    backRef: any;
    allFrozen: boolean;
    stepIndex: number;
    jumpToStep: (index: number) => void;
    setLocalCalculatorValues: (values: any) => void;
    lockNavigation: boolean;
    frequencyValues: FrequencyValues;
    proposedMaxCcCont: number;
}

const CalculatorSteps = ({ formikProps, handleNextStep, handlePreviousStep, setUpdatingMember, setForceUpdating, nextRef, backRef, allFrozen, stepIndex, jumpToStep, setLocalCalculatorValues, lockNavigation, frequencyValues, proposedMaxCcCont }: Props) => {
    const { submitForm } = formikProps;
    const values: Calculator = formikProps.values;
    const [stepsDisabled, setStepsDisabled] = useState(false);
    const agePensionRef = useRef<any>();
    const [inputErrors, setInputErrors] = useState<any>(validateCalculator({...values}));
    const [disableNext, setDisableNext] = useState(false);

    const setErrors = (newErrors: any) => {
        setInputErrors(newErrors);
    }

    useEffect(() => {
        setErrors(validateCalculator({...values}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values])

    const setDisabled = (el: any, readonly = false) => {
        if (el.id !== "back-button" && el.id !== "next-button" && !el.attributes.getNamedItem("data-nodisabletoggle")){
            el.setAttribute(readonly ? "readonly" : "disabled", "");
            el.setAttribute(readonly ? "aria-readonly" : "aria-disabled", true);
            el.classList.add("disabledForm");
        }
    }

    const setEnabled = (el: any, readonly = false) => {
        if (el.id !== "back-button" && el.id !== "next-button" && !el.attributes.getNamedItem("data-nodisabletoggle")) {
            el.removeAttribute(readonly ? "readonly" : "disabled");
            el.classList.remove("disabledForm");
        }
    }

    useEffect(() => {
        // generic enable or disable all inputs on the page, some inputs are controlled by other means (e.g. see some in AboutYou)
        if (allFrozen) {
            setStepsDisabled(true);
            const container = document.getElementById(calculatorStepsTestIds.calculatorStepsContainer);
            container?.querySelectorAll("button").forEach(el => setDisabled(el));
            container?.querySelectorAll(".ToggleButton__label").forEach(el => el.classList.add("disabledForm"));
            container?.querySelectorAll("input[type='text']").forEach(el => setDisabled(el, true));
            container?.querySelectorAll("input[type='checkbox']").forEach(el => setDisabled(el));
            container?.querySelectorAll("input[type='radio']").forEach(el => setDisabled(el));
            container?.querySelectorAll("select").forEach(el => setDisabled(el));
            container?.querySelectorAll("a[type='button']").forEach(el => setDisabled(el));
        } else {
            setStepsDisabled(false);
            const container = document.getElementById(calculatorStepsTestIds.calculatorStepsContainer);
            container?.querySelectorAll("button").forEach(el => setEnabled(el));
            container?.querySelectorAll(".ToggleButton__label").forEach(el => el.classList.remove("disabledForm"));
            container?.querySelectorAll("input[type='text']").forEach(el => setEnabled(el, true));
            container?.querySelectorAll("input[type='checkbox']").forEach(el => setEnabled(el));
            container?.querySelectorAll("input[type='radio']").forEach(el => setEnabled(el));
            container?.querySelectorAll("select").forEach(el => setEnabled(el));
            container?.querySelectorAll("a[type='button']").forEach(el => setEnabled(el));
        }

        setDisableNext((stepsDisabled && stepIndex === 5));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allFrozen, stepIndex]);

    return (
        <CalculatorStepsDiv>
            <Grid id={calculatorStepsTestIds.calculatorStepsContainer} data-id={calculatorStepsTestIds.calculatorStepsContainer} noGutter={true}>
                <Card backgroundColor={palette.mlcWhite}>
                    {stepIndex === 2 && (
                        <Grid>
                            <Row>
                                <Col md={12} col={12} noGutter={true}>
                                    <RetirementGoals
                                        formikProps={formikProps}
                                        agePensionRef={agePensionRef}
                                        setUpdatingMember={setUpdatingMember}
                                        setForceUpdating={setForceUpdating}
                                        setErrors={setErrors}
                                        frequencyValues={frequencyValues}
                                    />
                                </Col>
                            </Row>
                        </Grid>
                    )}
                    {stepIndex === 3 && (
                        <Grid>
                            <Row>
                                <Col md={12} col={12} noGutter={true}>
                                    <RiskProfile
                                        formikProps={formikProps}
                                        setUpdatingMember={setUpdatingMember}
                                        handleNextStep={handleNextStep}
                                        handlePreviousStep={handlePreviousStep}
                                    />
                                </Col>
                            </Row>
                        </Grid>
                    )}
                    {stepIndex === 4 && (
                        <Grid>
                            <Row>
                                <Col md={12} col={12} noGutter={true}>
                                    <RegularContributions
                                        formikProps={formikProps}
                                        setUpdatingMember={setUpdatingMember}
                                        jumpToStep={jumpToStep}
                                        setErrors={setErrors}
                                        frequencyValues={frequencyValues}
                                        proposedMaxCcCont={proposedMaxCcCont}
                                    />
                                </Col>
                            </Row>
                        </Grid>

                    )}
                {stepIndex === 5 && (
                    <Grid>
                        <Row>
                            <Col md={12} col={12} noGutter={true}>
                                <RetirementProjection
                                    submitForm={submitForm}
                                    lockNavigation={lockNavigation}
                                    focusFirstError={focusFirstError}
                                    formikProps={formikProps}
                                    setUpdatingMember={setUpdatingMember}
                                    jumpToStep={jumpToStep}
                                    setErrors={setErrors}
                                    frequencyValues={frequencyValues} 
                                    allFrozen={allFrozen}/>
                            </Col>
                        </Row>
                    </Grid>
                )}    
                </Card>
                {stepIndex !== 3 && (
                <NavButtonGroup>
                    <NextButton
                        onClick={() => {
                            if (_.isEmpty(inputErrors)) {
                                if (!lockNavigation) {
                                    logAction(`NEXT_${stepIndex + 1}`);
                                    if (stepIndex === 5) {
                                        submitForm();
                                    } else {
                                        handleNextStep();
                                    }
                                }
                            } else {
                                focusFirstError();
                            }
                        }}
                        disabled={disableNext}
                        aria-disabled={disableNext}
                        className={disableNext ? "disabledForm" : ""}
                        btnRef={nextRef}
                        data-id={calculatorStepsTestIds.nextStepButton}
                    >
                        {stepIndex === 5 ? "Get your report" : "Next"}
                    </NextButton>
                    <BackButton
                        btnRef={backRef}
                        onClick={() => {
                            if (!lockNavigation) {
                                logAction(`BACK_${stepIndex + 1}`);
                                handlePreviousStep();
                            }
                        }}
                        data-id={calculatorStepsTestIds.calculatorTabPrevious}
                    />
                </NavButtonGroup>
                )}
            </Grid>
        </CalculatorStepsDiv>
    );
};

export default CalculatorSteps;