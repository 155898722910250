import { Text, Paragraph, Col, Card } from '@mlc/web-ui-toolkit';
import React from 'react';
import ModalVideoPlayer from '../../../Common/ModalVideoPlayer';
import infoImg from '../../../../../assets/images/mkbs/lightbulb.png';
import inflationImg from '../../../../../assets/images/mkbs/inflation-coffee-illustration.png';
import NewWindowLink from '../../../Common/NewWindowLink';
import { PreferenceFSGLink, RiskProfileExtraInfoRow, RiskProfileExtraSourceLink } from '../CalculatorSteps.style';
import { Image } from '../../../Image';

import q1_eggtimer_lowest from '../../../../../assets/images/mkbs/egg-timer-0.png';
import q1_eggtimer_low from '../../../../../assets/images/mkbs/egg-timer-1.png';
import q1_eggtimer_medium from '../../../../../assets/images/mkbs/egg-timer-2.png';
import q1_eggtimer_highest from '../../../../../assets/images/mkbs/egg-timer-3.png';

import q2_gage_lowest from '../../../../../assets/images/mkbs/gage-empty.png';
import q2_gage_low from '../../../../../assets/images/mkbs/gage-low.png';
import q2_gage_medium from '../../../../../assets/images/mkbs/gage-almost-full.png';
import q2_gage_highest from '../../../../../assets/images/mkbs/gage-full.png';

import q3_thumb_two_down from '../../../../../assets/images/mkbs/two-thumb-down.png';
import q3_thumb_down from '../../../../../assets/images/mkbs/thumb-down.png';
import q3_thumb_up from '../../../../../assets/images/mkbs/thumb-up.png';
import q3_thumb_two_up from '../../../../../assets/images/mkbs/two-thumb-up.png';


import q4_very_concerned from '../../../../../assets/images/mkbs/very-concerned.png';
import q4_concerned from '../../../../../assets/images/mkbs/concerned.png';
import q4_ok from '../../../../../assets/images/mkbs/ok.png';
import q4_can_live_with from '../../../../../assets/images/mkbs/can-live-with-it.png';


import q5_extreme from '../../../../../assets/images/mkbs/extreme.png';
import q5_minimal from '../../../../../assets/images/mkbs/minimal.png';
import q5_modest from '../../../../../assets/images/mkbs/modest.png';
import q5_low from '../../../../../assets/images/mkbs/low.png';

import graph from '../../../../../assets/images/mkbs/icon-graph.png';
import active from '../../../../../assets/images/mkbs/active-icon-arrow.png';
import passive from '../../../../../assets/images/mkbs/passive-icon.png';
import riskProfileTestIds from './RiskProfile.elements';
import { logAction } from '../../../../../logic/functions/logging.function';


const riskProfile = {
    questionExtraContent: [
        {
            title: "Retirement intentions of Australians",
            content: (
                <>
                    <Paragraph>The average retirement age is 63 based on data from the Australian Bureau of Statistics of people who have retired within the last 5 years.</Paragraph>
                    <RiskProfileExtraSourceLink>Source: <NewWindowLink href="https://www.abs.gov.au/ausstats/abs@.nsf/mf/6238.0">Australian Bureau of Statistics</NewWindowLink></RiskProfileExtraSourceLink>
                </>
            )
        },
        {
            title: "Why understanding risk matters",
            content: (
                <RiskProfileExtraInfoRow>
                    <Col md={8} col={12}>
                        <Text>Did you know that every type of investment has some risk? Investments that are expected to have higher returns also have higher risk. You need to be comfortable with the level of risk you're taking. </Text>
                        <Text className="link-pretext">Watch this 3 min video to learn more.</Text>
                    </Col>
                    <Col md={4} col={12}>
                        <Card display="flex" justifyContent="center">
                            <ModalVideoPlayer videoUrl="https://publish.viostream.com/player/iframe/t1brdxdgjdmxn" />
                        </Card>
                    </Col>
                </RiskProfileExtraInfoRow>
            )
        },
        {
            title: "Why do investment markets go up and down?",
            content: (
                <RiskProfileExtraInfoRow>
                    <Col md={8} col={12}>
                        <Text>As a rule of thumb, if you want higher returns, you should be prepared to hold onto your investments for a number of years - and expect that they may fluctuate in value a lot over time. </Text>
                        <Text className="link-pretext">Watch this 3 min video to learn more.</Text>
                    </Col>
                    <Col md={4} col={12}>
                        <Card display="flex" justifyContent="center">
                            <ModalVideoPlayer videoUrl="https://publish.viostream.com/player/iframe/t1brdxdgjdmmu" />
                        </Card>
                    </Col>
                </RiskProfileExtraInfoRow>
            )
        },
        null,
        {
            title: "Why should you care about inflation?",
            content: (
                <>
                    <RiskProfileExtraInfoRow className="responsive-reversed">
                        <Col md={6} col={12} className="inflation-image-col">
                            <Image alt="" src={inflationImg} />
                        </Col>
                        <Col md={6} col={12}>
                            <Paragraph>
                                Inflation is when the costs of things go up over time and this can reduce the value of your super savings.
                            </Paragraph>
                            <Paragraph>
                                This means the return you get from your super needs to be at least the same as inflation otherwise your money would actually go backwards in value.
                            </Paragraph>
                        </Col>
                    </RiskProfileExtraInfoRow>
                    <RiskProfileExtraSourceLink className="question-5">Assumptions: Figures are based on the cost of a coffee ($4.50) increasing by an annual price inflation rate of 2.47% over a 10- and 20- year period.</RiskProfileExtraSourceLink>
                </>
            )
        },
        null
    ],
    infoImg,

    questionImages: [
        [q1_eggtimer_lowest, q1_eggtimer_low, q1_eggtimer_medium, q1_eggtimer_highest],
        [q2_gage_lowest, q2_gage_low, q2_gage_medium, q2_gage_highest],
        [q3_thumb_two_up, q3_thumb_up, q3_thumb_down, q3_thumb_two_down],
        [q4_very_concerned, q4_concerned, q4_can_live_with, q4_ok],
        [q5_minimal, q5_low, q5_modest, q5_extreme],
    ],

    graph: graph,
    active: active,
    passive: passive,

    managementTooltipContent: (
        <>
            <Text>Investment managers have different approaches to selecting investments, which may result in different returns. No single investment approach is guaranteed to outperform all others in all market conditions. There are generally two broad approaches: passive and active management.</Text>
            <br /><br />
            <Text><b>Passive management (Lower cost)</b> - Managers choose investments to form a portfolio which will deliver a return that closely tracks a market benchmark. Passive management tends to have lower costs because managers don't require extensive resources to select investments.</Text>
            <br /><br />
            <Text><b>Active management</b> - Managers select investments they believe, based on research, will perform better than a market benchmark over the long term.</Text>
            <br /><br />
            <Text>A market benchmark is a measure that is used to compare performances of different investment portfolios.</Text>
            <br /><br />
            <Text paddingTop={2}>Please refer to your </Text>
            <PreferenceFSGLink className="small" target="_blank" rel="noopener" href="https://www.mlc.com.au/links/oats/pds" data-id={riskProfileTestIds.tooltipPDSLink} onClick={() => logAction("INVESTMENT_STRATEGY_PDS_CLICK")}>
                <Text>Product Disclosure Statement</Text>
            </PreferenceFSGLink>
            <Text> for further details of investment approaches.</Text>
        </>
    )
}


export default riskProfile;