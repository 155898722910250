import { SkinResponse } from '../domains/skin.domain';
import { post } from './fetch';
import { Skin } from '../../enums';

export const getMemberSkin = async (): Promise<string | undefined> => {
    try {
        const data = await post(`/members/skin`, {}, { noErrorRedirect: true }) as Promise<SkinResponse>;
        if ((await data).error) {
            return Skin.Unauthorised;
        } else {
            return (await data).response.skin;
        }
    } catch (e) {
        return undefined;
    }
}