import { faCheckCircle, faCircle, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, FieldWrapper, Paragraph, Row, Text, TextLink, ToggleButton, ToggleButtonGroup } from "@mlc/web-ui-toolkit";
import { Button } from "@mlc/web-ui-toolkit/lib/components/Button/Button";
import classNames from "classnames";
import _ from "lodash";
import { logAction } from "../../../../../logic/functions/logging.function";
import React, { useContext, useEffect, useState } from "react";
import { AppThemeContext } from "../../../../../context/AppThemeContext";
import { Calculator, FrequencyValues } from "../../../../../logic/domains/calculator.domain";
import { asRoundedCurrency } from "../../../../../logic/functions/formatter";
import { convertToNumber, focusHeading, getRetirementAgeHelpText, getSuperAgeHelpText, toMonthlyFromFrequency, validateCalculator } from "../../../../../logic/functions/util";
import { commonClasses } from "../../../Common/Common.elements";
import FieldWithTooltip from "../../../Common/FieldWithTooltip";
import Select from "../../../Common/Select";
import { customAnnualRetirementIncomeError } from "../../../Common/StaticContent";
import { MobileDeviceQ } from "../../../Common/MediaQ";
import { AnnuallyText } from "../../AboutYou/AboutYou.style";
import { AsfaLink, CustomLifestyle, ExpandButton, ExpandContent, ExpandContentContainer, ExpandLifestyleContent, ExpandParagraph, ImageWithText, LifestyleButton, LifestyleContent, LifestyleLink, MobileExpandWrapper, MobileLifestyleWrapper, MobileTextWrapper, RetirementGoalsSection, RetirementGoalsSelection, StepInput } from "../CalculatorSteps.style";
import AgePensionModal from "./AgePensionModal";
import retirementGoalsTestIds from "./RetirementGoals.elements";

type Props = {
    formikProps: any;
    agePensionRef: any;
    setUpdatingMember: () => void;
    setForceUpdating: () => void;
    setErrors: (newErrors: any) => void;
    frequencyValues: FrequencyValues;
}

//calculator step 4 of 8
const RetirementGoals = (props: Props) => {
    const content = useContext(AppThemeContext).staticContent.retirementGoals;
    const { setFieldValue, handleChange } = props.formikProps;
    const values: Calculator = props.formikProps.values;
    const { retirementAge, superAge, includeAgePension, goalSuper, selectedLifestyle, inToolCustomIncome } = values;
    const [openAgeModal, setOpenAgeModal] = useState(false);
    const [inputValues, setInputValues] = useState({
        retirementAge: retirementAge,
        superAge: superAge,
        goalSuper: goalSuper,
        selectedLifestyle: selectedLifestyle
    });
    // stores a monthly value as backend uses monthly
    const [customIncome, setCustomIncome] = useState(inToolCustomIncome);

    //index 0-3 modest lifestyle top to bottom
    //index 4-7 comformtable lifestyle top to bottom
    const annualValues = [
        values.asfaModestReferenceAmount, values.asfaModestReferenceAmount * 0.175, values.asfaModestReferenceAmount * 0.18, values.asfaModestReferenceAmount * 0.175, 
        values.asfaComfortableReferenceAmount, values.asfaComfortableReferenceAmount * 0.144, values.asfaComfortableReferenceAmount * 0.224, values.asfaComfortableReferenceAmount * 0.183
    ];
    //previous monthly value 99999 * 12
    const [inputErrors, setInputErrors] = useState<any>({});
    const [expanded, setExpanded] = useState(false);
    const [selectedFrequency, setSelectedFrequency] = useState(props.frequencyValues.retirementGoals);
    const [displayedValues, setDisplayedValues] = useState(annualValues.map(value => (asRoundedCurrency(Math.round(value / props.frequencyValues.retirementGoals.value)))));
    const [incomeInputChanged, setIncomeInputChanged] = useState(false);
    const [incomeChangedValue, setIncomeChangedValue] = useState("");

    const deSelectOthers = (arr: boolean[], num: number) =>
        arr.map((value: boolean, index: number) => (index === num));

    const [selected, setSelected] = useState(deSelectOthers([false, false, false], selectedLifestyle));
    const [mobileExpanded, setMobileExpanded] = useState([false, false]);

    const selectNew = (num: number) =>
        setSelected(prevState => {
            return deSelectOthers(prevState, num);
        });

    const expand = (current: boolean) =>
        setExpanded(state => {
            logAction("LIFESTYLE_EXPAND_CLICK");
            return (state ? false : true);
        });


    useEffect(() => {
        focusHeading();
    }, []);

    useEffect(() => {
        setInputValues({
            retirementAge: values.retirementAge,
            superAge: values.superAge,
            goalSuper: values.goalSuper,
            selectedLifestyle: values.selectedLifestyle
        })
    }, [values]);

    useEffect(() => {
        if (!selected[inputValues.selectedLifestyle]) {
            selectNew(inputValues.selectedLifestyle);
        }
        // update after changing inputs but only if no inline errors
        const errors = validateCalculator({ ...values, ...inputValues });
        setInputErrors(errors);
        props.setErrors(errors);
        if (_.isNil(errors.retirementAge) && _.isNil(errors.superAge) && _.isNil(errors.goalSuper) && !_.isEqual(values, { ...values, ...inputValues })) {
            setFieldValue("retirementAge", inputValues.retirementAge);
            setFieldValue("superAge", inputValues.superAge);
            setFieldValue("goalSuper.income", inputValues.goalSuper.income);
            setFieldValue("selectedLifestyle", inputValues.selectedLifestyle);
            setFieldValue("inToolCustomIncome", customIncome);
            props.setUpdatingMember();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValues.retirementAge, inputValues.superAge, inputValues.goalSuper, inputValues.selectedLifestyle]);


    const cancelAgePension = () => {
        setOpenAgeModal(false);
    }

    return (
        <>
            <Row>
                <Col md={4} col={12}>
                    <FieldWithTooltip
                        label="You're hoping to retire at age"
                        error={getRetirementAgeHelpText(values)}
                        id={retirementGoalsTestIds.retireAgeInput}
                        tooltipContent={
                            <>
                                <ul>
                                    <li>
                                        We have used 60 as a minimum retirement age. This is when retirees can access their super benefits tax free. Super benefits may be accessible earlier if certain conditions are met, please contact us on {content.contactNum} if this applies to your situation.
                                    </li>
                                    <li> 
                                        If you are 60 or above, the minimum value you can enter is your current age plus one year.
                                    </li>
                                    <li>
                                        The default value is the financial year in which you turn Age Pension age.
                                    </li>
                                </ul>
                            </>
                        }
                        tooltipId={retirementGoalsTestIds.retireAgeInfo}
                        tooltipLabel="information about retirement age"
                        tooltipLog="TARGET_RETIRE_AGE_TT"
                        isInvalid={inputErrors.retirementAge}
                    >
                        <StepInput
                            value={inputValues.retirementAge.toString()}
                            onBlur={(e: any) => {
                                setInputValues({
                                    ...inputValues,
                                    retirementAge: parseInt((e.target.value).replace(/,/g, ""))
                                });
                            }}
                            onKeyDown={(e: any) => {
                                if (e.keyCode === 13 || e.key === 'Enter') {
                                    setInputValues({
                                        ...inputValues,
                                        retirementAge: parseInt((e.target.value).replace(/,/g, ""))
                                    });
                                }
                            }}
                            allowDecimal={false}
                            allowLeadingZeroes={true}
                            integerLimit={9}
                            className={inputErrors.retirementAge ? commonClasses.validationError : ''}
                            data-analytics-capture-value
                        />
                    </FieldWithTooltip>
                </Col>
            </Row>
            <Row>
                <Col md={4} col={12}>
                    <FieldWithTooltip
                        label="You'd like your money to last until you're"
                        error={getSuperAgeHelpText(values)}
                        id={retirementGoalsTestIds.superAgeInput}
                        tooltipContent={
                            <div>
                                <ul>
                                    <li>We have used your life expectancy as a default based on your age and gender as determined by statistics used by the Australian Government Actuary.</li>
                                    <li>You can select an age above your life expectancy, which may require you to save more money to achieve this goal.</li>
                                    <li>You are unable to select an age less than your life expectancy as this increases the risk of you outliving your savings.</li>
                                </ul>
                            </div>
                        }
                        tooltipId={retirementGoalsTestIds.superAgeInfo}
                        tooltipLabel="information about life expectancy"
                        tooltipLog="TARGET_SUPER_AGE_TT"
                        isInvalid={inputErrors.superAge}
                    >
                        <StepInput
                            value={inputValues.superAge.toString()}
                            onBlur={(e: any) => {
                                setInputValues({
                                    ...inputValues,
                                    superAge: parseInt((e.target.value).replace(/,/g, ""))
                                });
                            }}
                            onKeyDown={(e: any) => {
                                if (e.keyCode === 13 || e.key === 'Enter') {
                                    setInputValues({
                                        ...inputValues,
                                        superAge: parseInt((e.target.value).replace(/,/g, ""))
                                    });
                                }
                            }}
                            allowDecimal={false}
                            allowLeadingZeroes={true}
                            integerLimit={9}
                            className={inputErrors.superAge ? commonClasses.validationError : ''}
                            data-analytics-capture-value
                        />
                    </FieldWithTooltip>
                </Col>
            </Row>
            <Row>
                <Col col={12}>
                    <FieldWrapper label="You want to include the Age Pension">
                        <ToggleButtonGroup
                            name="includeAgePension"
                            value={includeAgePension}
                            onChange={(event: any) => {
                                if (event.target.value === "n") {
                                    handleChange(event);
                                    props.setUpdatingMember();
                                } else {
                                    setOpenAgeModal(true);
                                }
                            }}
                            data-id={retirementGoalsTestIds.agePensionGroup}
                        >
                            <ToggleButton
                                value="y"
                                data-id={retirementGoalsTestIds.agePensionYes}
                                ref={props.agePensionRef}
                                onClick={() => {
                                    setFieldValue("agePensionInfoComplete", false);
                                    setOpenAgeModal(true);
                                }}
                            >
                                Yes
                            </ToggleButton>
                            <ToggleButton value="n" data-id={retirementGoalsTestIds.agePensionNo}>No</ToggleButton>
                        </ToggleButtonGroup>
                    </FieldWrapper>
                </Col>
            </Row>
            {includeAgePension === "y" && (
                <Row>
                    <Col col={12}>
                        <Card marginBottom="25px">
                            <TextLink el="button" type="button" onClick={() => setOpenAgeModal(true)}>Update your Age Pension details</TextLink>
                        </Card>
                    </Col>
                </Row>
            )}
            <Row>
                <Col md={4} col={12}>
                    <FieldWrapper label="You'd like to see your retirement income">
                        <Select
                            onChange={(e: any) => {
                                const newFreqValue = e.target.value;
                                const newFreqLabel = e.target.options[e.target.selectedIndex].text;
                                setSelectedFrequency({ value: newFreqValue, label: newFreqLabel.toLowerCase() });
                                setDisplayedValues(annualValues.map(value => (asRoundedCurrency(Math.round(value / newFreqValue)))));
                                props.frequencyValues.retirementGoals = { value: newFreqValue, label: newFreqLabel.toLowerCase() };
                                logAction(`RETIREMENT_INCOME_FREQUENCY_${newFreqLabel}`);
                            }}
                            value={selectedFrequency.value}
                            options={[{ value: 1, label: "Annually" }, { value: 12, label: "Monthly" }, { value: 26, label: "Fortnightly" }, { value: 52, label: "Weekly" }]} 
                            data-id={retirementGoalsTestIds.retirementIncomeFrequency}/>
                    </FieldWrapper>
                </Col>
            </Row>
            <AgePensionModal
                isOpen={openAgeModal}
                setOpenFunction={setOpenAgeModal}
                onCancel={cancelAgePension}
                formikProps={props.formikProps}
                setUpdatingMember={props.setUpdatingMember}
                setForceUpdating={props.setForceUpdating}
            />
            <Row>
                <Col>
                    <Text>
                        In retirement you want to lead a:
                    </Text>
                </Col>
            </Row>
            <MobileDeviceQ
            content={<>
            <RetirementGoalsSection>
                {/* Modest lifestyle section */}
                <RetirementGoalsSelection className={classNames(selected[0] ? "selected" : "", expanded ? "expanded" : "")}>
                    <LifestyleContent>
                        <Button type="button" className="selectionButton" onClick={() => {
                            if (!selected[0]) {
                                setInputValues({
                                    ...inputValues,
                                    goalSuper: {
                                        ...inputValues.goalSuper,
                                        income: annualValues[0] / 12
                                    },
                                    selectedLifestyle: 0
                                });
                                logAction("LIFESTYLE_SELECT_0")
                            }
                        }}
                        data-id={retirementGoalsTestIds.retirementLifestyleModerate}>
                            <LifestyleButton className={classNames(selected[0] ? "selected" : "", "selectionButton")}>
                                <FontAwesomeIcon size="2x" icon={selected[0] ? faCheckCircle : faCircle} />
                                <Text el="h2" margin={0}>
                                    <b>Modest lifestyle</b>
                                </Text>
                            </LifestyleButton>
                        </Button>
                    </LifestyleContent>
                    <LifestyleContent>
                        <Paragraph className="annualValue" paddingTop="15px" textAlign="center">
                            <b>{displayedValues[0]} {selectedFrequency.label}*</b>
                        </Paragraph>
                    </LifestyleContent>
                    <LifestyleContent className="image">
                        <img width="65px" height="auto" src={content.apronman} alt="" />
                    </LifestyleContent>
                    <LifestyleContent>
                        <Card padding="5%">
                            A <b>modest lifestyle</b> is better than being on the Age Pension but only allows for the basics such as a home cooked meal rather than dining out every weekend.
                        </Card>
                    </LifestyleContent>
                    <LifestyleContent>
                        <LifestyleLink>
                            <Text>*
                                <TextLink data-id={retirementGoalsTestIds.retirementStandardsLinkModest} target="_blank" rel="noopener" href={content.asfaRetirementStandardsLink} onClick={() => logAction("LINK_ASFA_STANDARDS")}>
                                    ASFA Retirement Standard (Single home owner)
                                </TextLink>
                            </Text>
                        </LifestyleLink>
                    </LifestyleContent>
                    <LifestyleContent className={classNames("expandButton", expanded ? "expanded" : "", selected[0] ? "selected" : "")}>
                        <ExpandButton type="button"
                            onClick={() => {
                                expand(expanded);
                            }}
                            data-id={retirementGoalsTestIds.retirementLifestyleExpand}
                            className={classNames(!expanded ? "expanded" : "")}><FontAwesomeIcon size="lg" icon={expanded ? faMinus : faPlus} />
                        </ExpandButton>
                    </LifestyleContent>
                </RetirementGoalsSelection>
                {/* Comfortable lifestyle section */}
                <RetirementGoalsSelection className={classNames((selected[1] ? "selected" : ""), (expanded ? "expanded" : ""))}>
                    <LifestyleContent>
                        <Button type="button" className="selectionButton" onClick={() => {
                            if (!selected[1]) {
                                setInputValues({
                                    ...inputValues,
                                    goalSuper: {
                                        ...inputValues.goalSuper,
                                        income: annualValues[4] / 12
                                    },
                                    selectedLifestyle: 1
                                });
                                logAction("LIFESTYLE_SELECT_1");
                            }
                        }}
                        data-id={retirementGoalsTestIds.retirementLifestyleComfortable}>
                            <LifestyleButton className={classNames(selected[1] ? "selected" : "", "selectionButton")}>
                                <FontAwesomeIcon size="2x" icon={selected[1] ? faCheckCircle : faCircle} />
                                <Text el="h2" margin={0}><b>Comfortable lifestyle</b></Text>
                            </LifestyleButton>
                        </Button>
                    </LifestyleContent>
                    <LifestyleContent>
                        <Paragraph className="annualValue" paddingTop="15px" textAlign="center">
                            <b>{displayedValues[4]} {selectedFrequency.label}*</b>
                        </Paragraph>
                    </LifestyleContent>
                    <LifestyleContent className="image">
                        <img alt="" width="105px" height="auto" src={content.comfortable} />
                    </LifestyleContent>
                    <LifestyleContent>
                        <Card padding="5%" >
                            A <b>comfortable lifestyle</b> means you can do the things you enjoy like golf, yoga, dining out with friends occasionally.
                        </Card>
                    </LifestyleContent>
                    <LifestyleContent>
                        <LifestyleLink>
                            <Text>*
                                <TextLink data-id={retirementGoalsTestIds.retirementStandardsLinkComfortable} target="_blank" rel="noopener" href={content.asfaRetirementStandardsLink} onClick={() => logAction("LINK_ASFA_STANDARDS")}>
                                    ASFA Retirement Standard (Single home owner)
                                </TextLink>
                            </Text>
                        </LifestyleLink>
                    </LifestyleContent>
                    <LifestyleContent className={classNames("expandButton", expanded ? "expanded" : "", selected[1] ? "selected" : "")}>
                        <ExpandButton type="button" onClick={() => {
                            expand(expanded);
                        }}
                        data-id={retirementGoalsTestIds.retirementLifestyleExpand}
                            className={classNames(expanded ? "expanded" : "")}><FontAwesomeIcon size="lg" icon={expanded ? faMinus : faPlus} />
                        </ExpandButton>
                    </LifestyleContent>
                </RetirementGoalsSelection>
                {/* Custom lifestyle section */}
                <RetirementGoalsSelection className={classNames(selected[2] ? "selected" : "", "selectionButton")}>
                    <LifestyleContent>
                        <Button type="button" className="selectionButton" onClick={() => {
                            if (!selected[2]) {
                                setInputValues({
                                    ...inputValues,
                                    goalSuper: {
                                        ...inputValues.goalSuper,
                                        income: customIncome
                                    },
                                    selectedLifestyle: 2
                                });
                                logAction("LIFESTYLE_SELECT_2");
                            }
                        }}
                        data-id={retirementGoalsTestIds.retirementLifestyleCustom}>
                            <LifestyleButton className={classNames(selected[2] ? "selected" : "", "selectionButton")}>
                                <FontAwesomeIcon size="2x" icon={selected[2] ? faCheckCircle : faCircle} />
                                <Text el="h2" margin={0}><b>Custom lifestyle</b></Text>
                            </LifestyleButton>
                        </Button>
                    </LifestyleContent>
                    <LifestyleContent>
                        <CustomLifestyle>
                            <FieldWithTooltip
                                label=""
                                error={<>
                                    Please enter an amount between $0 and {asRoundedCurrency(customAnnualRetirementIncomeError / selectedFrequency.value)}.
                                </>}
                                id={retirementGoalsTestIds.retireIncomeInput}
                                isInvalid={inputErrors.goalSuper}
                            >
                                <CustomLifestyle>
                                    <StepInput
                                        prefix="$"
                                        onChange={(e: any) => {
                                            setIncomeInputChanged(true);
                                            setIncomeChangedValue(e.target.value);
                                        }}
                                        onBlur={(e: any) => {
                                            if (incomeInputChanged) {
                                                let newCustomIncome = toMonthlyFromFrequency(convertToNumber(e.target.value), selectedFrequency.value);
                                                setInputValues({
                                                    ...inputValues,
                                                    goalSuper: {
                                                        ...inputValues.goalSuper,
                                                        income: newCustomIncome
                                                    },
                                                    selectedLifestyle: 2
                                                });
                                                setCustomIncome(newCustomIncome);
                                                setIncomeInputChanged(false);
                                            } else {
                                                setInputValues({
                                                    ...inputValues,
                                                    selectedLifestyle: 2
                                                });
                                            }
                                            logAction("LIFESTYLE_SELECT_2");
                                        }}
                                        onKeyDown={(e: any) => {
                                            if (e.keyCode === 13 || e.key === 'Enter') {
                                                if (incomeInputChanged) {
                                                    let newCustomIncome = toMonthlyFromFrequency(convertToNumber(e.target.value), selectedFrequency.value);
                                                    setInputValues({
                                                        ...inputValues,
                                                        goalSuper: {
                                                            ...inputValues.goalSuper,
                                                            income: newCustomIncome
                                                        },
                                                        selectedLifestyle: 2
                                                    });
                                                    setCustomIncome(newCustomIncome);
                                                    setIncomeInputChanged(false);
                                                } else {
                                                    setInputValues({
                                                        ...inputValues,
                                                        selectedLifestyle: 2
                                                    });
                                                }
                                                logAction("LIFESTYLE_SELECT_2");
                                            }
                                        }}
                                        value={incomeInputChanged ? incomeChangedValue : (Math.round(customIncome * 12 / selectedFrequency.value)).toString()}
                                        allowDecimal={false}
                                        allowLeadingZeroes={false}
                                        integerLimit={9}
                                        className={inputErrors.goalSuper ? commonClasses.validationError : ''}
                                        data-analytics-capture-value
                                        data-id={retirementGoalsTestIds.retireIncomeInput}
                                    />
                                    <AnnuallyText><b>{selectedFrequency.label}</b></AnnuallyText>
                                </CustomLifestyle>
                            </FieldWithTooltip>
                        </CustomLifestyle>
                    </LifestyleContent>
                    <LifestyleContent className="image">
                        <img alt="" width="90px" height="auto" src={content.custom} />
                    </LifestyleContent>
                    <LifestyleContent>
                        <Card padding="5%">
                            You know what lifestyle will suit you. Enter the amount of income you need to enjoy your retirement.
                        </Card>
                    </LifestyleContent>
                    <LifestyleContent>
                        <LifestyleLink>
                            <Text>
                                Not sure how much you can afford?
                            </Text>
                            <br />
                            <TextLink onClick={() => logAction("LINK_ASFA_CALC")} data-id={retirementGoalsTestIds.retirementCalculatorLink} target="_blank" rel="noopener" href="https://moneysmart.gov.au/budgeting/budget-planner">
                                Try ASIC's Budget planner
                            </TextLink>
                        </LifestyleLink>
                    </LifestyleContent>
                    <LifestyleContent>
                    </LifestyleContent>
                </RetirementGoalsSelection>
            </RetirementGoalsSection>
            <ExpandContentContainer className={classNames(expanded ? "expanded" : "")} data-id={retirementGoalsTestIds.retirementLifestyleExpandedContent}>
                <ExpandContent className={classNames(selected[0] ? "selected" : "")}>
                    <ExpandLifestyleContent>
                        <ExpandParagraph>
                            Some other example of what a <b>modest</b> retirement lifestyle looks like are below.
                        </ExpandParagraph>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent>
                        <AsfaLink >
                            See the <TextLink data-id={retirementGoalsTestIds.retirementStandardsLinkModestExpand} target="_blank" rel="noopener" href={content.asfaRetirementStandardsLink} onClick={() => logAction("EXPAND_LINK_ASFA_STANDARDS")}>ASFA website</TextLink> to learn about others.
                        </AsfaLink>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent>
                        <ImageWithText>
                            <img alt="" src={content.noodlebox} />
                            <Paragraph className={classNames(selected[0] ? "selected" : "")}>
                                <b>{displayedValues[1]} {selectedFrequency.label}</b>
                            </Paragraph>
                        </ImageWithText>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent>
                        <ExpandParagraph>
                            Take out and occasional cheap restaurants.
                        </ExpandParagraph>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent>
                        <ImageWithText >
                            <img alt="" src={content.popcorn} />
                            <Paragraph className={classNames(selected[0] ? "selected" : "")}>
                                <b>{displayedValues[2]} {selectedFrequency.label}</b>
                            </Paragraph>
                        </ImageWithText>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent>
                        <ExpandParagraph>
                            One leisure activity infrequently e.g. some trips to the cinema. One holiday in Australia or a few short breaks.
                        </ExpandParagraph>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent>
                        <ImageWithText>
                            <img alt="" src={content.car} />
                            <Paragraph className={classNames(selected[0] ? "selected" : "")}>
                                <b>{displayedValues[3]} {selectedFrequency.label}</b>
                            </Paragraph>
                        </ImageWithText>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent>
                        <ExpandParagraph>
                            Owning a cheaper, more basic car.
                        </ExpandParagraph>
                    </ExpandLifestyleContent>
                </ExpandContent>
                <ExpandContent className={classNames(selected[1] ? "selected" : "")}>
                    <ExpandLifestyleContent>
                        <ExpandParagraph>
                            Some other examples of what a <b>comfortable</b> retirement lifestyle looks like are below.
                        </ExpandParagraph>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent>
                        <AsfaLink>
                            See the <TextLink data-id={retirementGoalsTestIds.retirementStandardsLinkComfortableExpand} target="_blank" rel="noopener" href={content.asfaRetirementStandardsLink} onClick={() => logAction("EXPAND_LINK_ASFA_STANDARDS")}>ASFA website</TextLink> to learn about others.
                        </AsfaLink>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent >
                        <ImageWithText>
                            <img alt="" src={content.cocktail} />
                            <Paragraph className={classNames(selected[1] ? "selected" : "")}>
                                <b>{displayedValues[5]} {selectedFrequency.label}</b>
                            </Paragraph>
                        </ImageWithText>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent>
                        <ExpandParagraph>
                            Restaurant dining, good range and quality of food.
                        </ExpandParagraph>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent>
                        <ImageWithText>
                            <img alt="" src={content.palmtree} />
                            <Paragraph className={classNames(selected[1] ? "selected" : "")}>
                                <b>{displayedValues[6]} {selectedFrequency.label}</b>
                            </Paragraph>
                        </ImageWithText>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent>
                        <ExpandParagraph>
                            Enjoy a range of regular leisure activities. Domestic and occasional overseas holidays.
                        </ExpandParagraph>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent>
                        <ImageWithText>
                            <img alt="" src={content.keys} />
                            <Paragraph className={classNames(selected[1] ? "selected" : "")}>
                                <b>{displayedValues[7]} {selectedFrequency.label}</b>
                            </Paragraph>
                        </ImageWithText>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent>
                        <ExpandParagraph>
                            Own a reasonable car.
                        </ExpandParagraph>
                    </ExpandLifestyleContent>
                </ExpandContent>
            </ExpandContentContainer>
        </>
        }
        mobileContent={<>
            {/* Modest lifestyle section */}
            <MobileLifestyleWrapper>
                <RetirementGoalsSelection className={classNames(selected[0] ? "selected" : "", mobileExpanded[0] ? "expanded" : "")}>
                    <LifestyleContent>
                        <Button type="button" className="selectionButton" onClick={() => {
                            if (!selected[0]) {
                                setInputValues({
                                    ...inputValues,
                                    goalSuper: {
                                        ...inputValues.goalSuper,
                                        income: annualValues[0] / 12
                                    },
                                    selectedLifestyle: 0
                                });
                                setMobileExpanded([false, false]);
                                logAction("LIFESTYLE_SELECT_0");
                            }
                        }}>
                            <LifestyleButton className={classNames(selected[0] ? "selected" : "", "selectionButton")}>
                                <FontAwesomeIcon size="2x" icon={selected[0] ? faCheckCircle : faCircle} />
                                <Paragraph>
                                    <b>Modest lifestyle</b>
                                </Paragraph>
                            </LifestyleButton>
                        </Button>
                    </LifestyleContent>
                    <LifestyleContent>
                        <Paragraph className={classNames("annualValue", "mobile")} paddingTop="15px" textAlign="center">
                            <b>{displayedValues[0]} {selectedFrequency.label}*</b>
                        </Paragraph>
                    </LifestyleContent>
                    <LifestyleContent>
                        <LifestyleLink>
                            <Text>*
                                <TextLink data-id={retirementGoalsTestIds.retirementStandardsLinkModest} target="_blank" rel="noopener" href={content.asfaRetirementStandardsLink} onClick={() => logAction("LINK_ASFA_STANDARDS")}>
                                    ASFA Retirement Standard (Single home owner)
                                </TextLink>
                            </Text>
                        </LifestyleLink>
                    </LifestyleContent>
                    <LifestyleContent className={classNames("expandButton", mobileExpanded[0] ? "expanded": "", selected[0] ? "selected" : "")}>
                        <ExpandButton type="button"
                            onClick={() => {
                                setMobileExpanded([!mobileExpanded[0], mobileExpanded[1]]);
                                logAction("LIFESTYLE_EXPAND_CLICK");
                            }}
                            data-id={retirementGoalsTestIds.retirementLifestyleExpand}
                            className={classNames(mobileExpanded[0] ? "expanded": "")}><FontAwesomeIcon size="lg" icon={mobileExpanded[0] ? faMinus : faPlus} />
                        </ExpandButton>
                    </LifestyleContent>
                </RetirementGoalsSelection>
            </MobileLifestyleWrapper>
            <MobileExpandWrapper className={classNames(mobileExpanded[0] ? "expanded": "")} data-id={retirementGoalsTestIds.retirementLifestyleExpandedContent}>
                <ExpandContent className={classNames(selected[0] ? "selected" : "", mobileExpanded[0] ? "expanded": "")}>
                    <ExpandLifestyleContent>
                        <ImageWithText className="lifestyle">
                            <img className={classNames("lifestyle", "apronman")} alt="" width="70px" height="auto" src={content.apronman} />
                            <Paragraph>
                                A modest lifestyle is better than being on the Age Pension but only allows for the basics such as a home cooked meal rather than dining out every weekend.
                            </Paragraph>
                        </ImageWithText>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent>
                        <ExpandParagraph>
                            Some other example of what a <b>modest</b> retirement lifestyle looks like are below.
                        </ExpandParagraph>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent>
                        <AsfaLink>
                            See the <TextLink data-id={retirementGoalsTestIds.retirementStandardsLinkModestExpand} target="_blank" rel="noopener" href={content.asfaRetirementStandardsLink} onClick={() => logAction("EXPAND_LINK_ASFA_STANDARDS")}>ASFA website</TextLink> to learn about others.
                        </AsfaLink>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent>
                        <ImageWithText>
                            <img alt="" src={content.noodlebox} />
                            <MobileTextWrapper>
                                <Paragraph className={classNames(selected[0] ? "selected" : "")}>
                                    <b>{displayedValues[1]} {selectedFrequency.label}</b>
                                </Paragraph>
                                <Text>
                                    Take out and occasional cheap restaurants.
                                </Text>
                            </MobileTextWrapper>
                        </ImageWithText>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent>
                        <ImageWithText >
                            {/* Resize the popcorn specifically because of the icon being different dimensions..... */}
                            <img alt="" src={content.popcorn} className="popcorn" />
                            <MobileTextWrapper>
                            <Paragraph className={classNames(selected[0] ? "selected" : "")}>
                                <b>{displayedValues[2]} {selectedFrequency.label}</b>
                            </Paragraph>
                            <Text>
                                One leisure activity infrequently e.g. some trips to the cinema. One holiday in Australia or a few short breaks.
                            </Text>
                            </MobileTextWrapper>
                        </ImageWithText>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent>
                        <ImageWithText>
                            <img alt="" src={content.car} />
                            <MobileTextWrapper>
                            <Paragraph className={classNames(selected[0] ? "selected" : "")}>
                                <b>{displayedValues[3]} {selectedFrequency.label}</b>
                            </Paragraph>
                            <Text>
                                Owning a cheaper, more basic car.
                            </Text>
                            </MobileTextWrapper>
                        </ImageWithText>
                    </ExpandLifestyleContent>
                </ExpandContent>
            </MobileExpandWrapper>
            {/* Comfortable lifestyle section */}
            <MobileLifestyleWrapper>
                <RetirementGoalsSelection className={classNames(selected[1] ? "selected" : "", mobileExpanded[1] ? "expanded" : "")}>
                    <LifestyleContent>
                        <Button type="button" className="selectionButton" onClick={() => {
                            if (!selected[1]) {
                                setInputValues({
                                    ...inputValues,
                                    goalSuper: {
                                        ...inputValues.goalSuper,
                                        income: annualValues[4] / 12
                                    },
                                    selectedLifestyle: 1
                                });
                                setMobileExpanded([false, false]);
                                logAction("LIFESTYLE_SELECT_1");
                            }
                        }}>
                            <LifestyleButton className={classNames(selected[1] ? "selected" : "", "selectionButton")}>
                                <FontAwesomeIcon size="2x" icon={selected[1] ? faCheckCircle : faCircle} />
                                <Paragraph> <b>Comfortable lifestyle</b> </Paragraph>
                            </LifestyleButton>
                        </Button>
                    </LifestyleContent>
                    <LifestyleContent>
                        <Paragraph className={classNames("annualValue", "mobile")} paddingTop="15px" textAlign="center">
                            <b>{displayedValues[4]} {selectedFrequency.label}*</b>
                        </Paragraph>
                    </LifestyleContent>
                    <LifestyleContent>
                        <LifestyleLink>
                            <Text>*
                                <TextLink data-id={retirementGoalsTestIds.retirementStandardsLinkComfortable} target="_blank" rel="noopener" href={content.asfaRetirementStandardsLink} onClick={() => logAction("LINK_ASFA_STANDARDS")}>
                                    ASFA Retirement Standard (Single home owner)
                                </TextLink>
                            </Text>
                        </LifestyleLink>
                    </LifestyleContent>
                    <LifestyleContent className={classNames("expandButton", mobileExpanded[1] ? "expanded": "", selected[1] ? "selected" : "")}>
                        <ExpandButton type="button" onClick={() => {
                            setMobileExpanded([mobileExpanded[0], !mobileExpanded[1]]);
                            logAction("LIFESTYLE_EXPAND_CLICK");
                        }}
                        data-id={retirementGoalsTestIds.retirementLifestyleExpand}
                        className={classNames(mobileExpanded[1] ? "expanded": "")}><FontAwesomeIcon size="lg" icon={mobileExpanded[1] ? faMinus : faPlus} />
                        </ExpandButton>
                    </LifestyleContent>
                </RetirementGoalsSelection>
            </MobileLifestyleWrapper>
            <MobileExpandWrapper className={classNames(mobileExpanded[1] ? "expanded": "")} data-id={retirementGoalsTestIds.retirementLifestyleExpandedContent}>
                <ExpandContent className={classNames(selected[1] ? "selected" : "", mobileExpanded[1] ? "expanded": "")}>
                    <ExpandLifestyleContent>
                        <ImageWithText className="lifestyle">
                        <img className="lifestyle" alt="" width="70px" height="auto" src={content.comfortable} />
                        <Paragraph>
                            A comfortable lifestyle means you can do the things you enjoy like golf, yoga, dining out with friends occasionally.
                        </Paragraph>
                        </ImageWithText>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent>
                        <ExpandParagraph>
                            Some other examples of what a <b>comfortable</b> retirement lifestyle looks like are below.
                        </ExpandParagraph>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent>
                        <AsfaLink>
                            See the <TextLink data-id={retirementGoalsTestIds.retirementStandardsLinkComfortableExpand} target="_blank" rel="noopener" href={content.asfaRetirementStandardsLink} onClick={() => logAction("EXPAND_LINK_ASFA_STANDARDS")}>ASFA website</TextLink> to learn about others.
                        </AsfaLink>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent >
                        <ImageWithText>
                            <img alt="" src={content.cocktail} />
                            <MobileTextWrapper>
                                <Paragraph className={classNames(selected[1] ? "selected" : "")}>
                                    <b>{displayedValues[5]} {selectedFrequency.label}</b>
                                </Paragraph>
                                <Text>
                                    Restaurant dining. good range and quality of food.
                                </Text>
                            </MobileTextWrapper>
                        </ImageWithText>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent>
                        <ImageWithText>
                            <img alt="" src={content.palmtree} />
                            <MobileTextWrapper>
                            <Paragraph className={classNames(selected[1] ? "selected" : "")}>
                                <b>{displayedValues[6]} {selectedFrequency.label}</b>
                            </Paragraph>
                            <Text>
                                Enjoy a range of regular leisure activities. Domestic and occasional overseas holidays.
                            </Text>
                            </MobileTextWrapper>
                        </ImageWithText>
                    </ExpandLifestyleContent>
                    <ExpandLifestyleContent>
                        <ImageWithText>
                            <img alt="" src={content.keys} />
                            <MobileTextWrapper>
                            <Paragraph className={classNames(selected[1] ? "selected" : "")}>
                                <b>{displayedValues[7]} {selectedFrequency.label}</b>
                            </Paragraph>
                            <Text> 
                                Own a reasonable car.
                            </Text>
                            </MobileTextWrapper>
                        </ImageWithText>
                    </ExpandLifestyleContent>
                </ExpandContent>
            </MobileExpandWrapper>
            {/* Custom lifestyle section */}
            <MobileLifestyleWrapper>
                <RetirementGoalsSelection className={classNames(selected[2] ? "selected" : "")}>
                        <LifestyleContent>
                            <Button type="button" className="selectionButton" onClick={() => {
                                if (!selected[2]) {
                                    setInputValues({
                                        ...inputValues,
                                        goalSuper: {
                                            ...inputValues.goalSuper,
                                            income: customIncome
                                        },
                                        selectedLifestyle: 2
                                    });
                                    setMobileExpanded([false,false]);
                                    logAction("LIFESTYLE_SELECT_2");
                                }
                                }}>
                                <LifestyleButton className={classNames(selected[2] ? "selected" : "", "selectionButton")}>
                                    <FontAwesomeIcon size="2x" icon={selected[2] ? faCheckCircle : faCircle} />
                                    <Text el="h2" margin={0}><b>Custom lifestyle</b></Text>
                                </LifestyleButton>
                            </Button>
                        </LifestyleContent>
                        <LifestyleContent>
                        <CustomLifestyle>
                            <FieldWithTooltip
                                label=""
                                error={<>
                                    Please enter an amount between $0 and {asRoundedCurrency(customAnnualRetirementIncomeError / selectedFrequency.value)}.
                                </>}
                                id={retirementGoalsTestIds.retireIncomeInput}
                                isInvalid={inputErrors.goalSuper}
                            >
                                <CustomLifestyle>
                                    <StepInput
                                        prefix="$"
                                        onChange={(e: any) => {
                                            setIncomeInputChanged(true);
                                            setIncomeChangedValue(e.target.value);
                                        }}
                                        onBlur={(e: any) => {
                                            if (incomeInputChanged) {
                                                let newCustomIncome = toMonthlyFromFrequency(convertToNumber(e.target.value), selectedFrequency.value);
                                                setInputValues({
                                                    ...inputValues,
                                                    goalSuper: {
                                                        ...inputValues.goalSuper,
                                                        income: newCustomIncome
                                                    },
                                                    selectedLifestyle: 2
                                                });
                                                setCustomIncome(newCustomIncome);
                                                setMobileExpanded([false,false]);
                                                setIncomeInputChanged(false);
                                            } else {
                                                setInputValues({
                                                    ...inputValues,
                                                    selectedLifestyle: 2
                                                });
                                            }
                                            logAction("LIFESTYLE_SELECT_2");
                                        }}
                                        onKeyDown={(e: any) => {
                                            if (e.keyCode === 13 || e.key === 'Enter') {
                                                if (incomeInputChanged) {
                                                    let newCustomIncome = toMonthlyFromFrequency(convertToNumber(e.target.value), selectedFrequency.value);
                                                    setInputValues({
                                                        ...inputValues,
                                                        goalSuper: {
                                                            ...inputValues.goalSuper,
                                                            income: newCustomIncome
                                                        },
                                                        selectedLifestyle: 2
                                                    });
                                                    setCustomIncome(newCustomIncome);
                                                    setMobileExpanded([false,false]);
                                                    setIncomeInputChanged(false);
                                                } else {
                                                    setInputValues({
                                                        ...inputValues,
                                                        selectedLifestyle: 2
                                                    });
                                                }
                                                logAction("LIFESTYLE_SELECT_2");
                                            }
                                        }}
                                        value={incomeInputChanged ? incomeChangedValue : (Math.round(customIncome * 12 / selectedFrequency.value)).toString()}
                                        allowDecimal={false}
                                        allowLeadingZeroes={false}
                                        integerLimit={9}
                                        className={classNames(inputErrors.goalSuper ? commonClasses.validationError : '', "customlifestyle")}
                                        data-analytics-capture-value
                                        data-id={retirementGoalsTestIds.retireIncomeInput}
                                    />
                                    <AnnuallyText><b>{selectedFrequency.label}</b></AnnuallyText>
                                </CustomLifestyle>
                            </FieldWithTooltip>
                        </CustomLifestyle>
                    </LifestyleContent>
                    {selectedLifestyle === 2 ? (<><LifestyleContent>
                        <ImageWithText className="lifestyle">
                            <img className="custom" alt="" width="70px" height="auto" src={content.custom} />
                            <Paragraph className="custom">
                                You know what lifestyle will suit you. Enter the amount of income you need to enjoy your retirement.
                            </Paragraph>
                        </ImageWithText>
                    </LifestyleContent>
                    <LifestyleContent>
                        <LifestyleLink className="customhide">
                            <Text>
                                Not sure how much you can afford?{' '}
                            </Text>
                            <TextLink onClick={() => logAction("LINK_ASFA_CALC")} target="_blank" rel="noopener" href="https://moneysmart.gov.au/budgeting/budget-planner">
                                Try ASIC's Budget planner
                            </TextLink>
                        </LifestyleLink>
                    </LifestyleContent>
                    </>) : <LifestyleContent>
                        <LifestyleLink>
                            <Text>
                                Not sure how much you can afford?{' '}
                            </Text>
                            <TextLink onClick={() => logAction("LINK_ASFA_CALC")} target="_blank" rel="noopener" href="https://moneysmart.gov.au/budgeting/budget-planner">
                                Try ASIC's Budget planner
                            </TextLink>
                        </LifestyleLink>
                    </LifestyleContent>}
                </RetirementGoalsSelection>
            </MobileLifestyleWrapper>
        </>}
        />
        </>
    );
};

export default RetirementGoals;
