import React from 'react';
import _ from 'lodash';
import { Card, Heading } from '@mlc/web-ui-toolkit';
import commonTestIds from './Common.elements';

type Props = {
    options: any[];
    heading?: string;
    headingLevel?: string;
    className?: string;
}

const OptionsList = ({ options, heading, headingLevel = "h3", className }: Props) => (
    <Card data-id={commonTestIds.optionsList}>
        {heading ? (
            <Heading className="options__Header" el={headingLevel} fontSize={{ xs: 'tiny', md: 'tiny' }}>
                {heading}
            </Heading>
        ) : null}
        <ul className={className}>
            {_.map(options, (option, i) => (
                <li key={i}>
                    {option}
                </li>
            ))}
        </ul>
    </Card>
);

export default OptionsList;