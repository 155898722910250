import React from 'react';

const AnalyticsForm = ({children, stepIndex, onSubmit}: any) => (
    <form
        noValidate
        id='calculatorForm'
        onSubmit={onSubmit ? onSubmit : (e: any) => e.preventDefault()}
        data-id="personal-super-calculator-form"
        data-analytics-step={`Step ${stepIndex + 1} of 8`}
    >
        {children}
    </form>
);

export default AnalyticsForm;