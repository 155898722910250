enum aboutYouTestIds {
    updateDetailsNext = 'update-btn',
    updateDetailsBack = 'update-details-back',
    yourSalaryInput = 'your-salary-input',
    yourSalaryInputField = 'your-salary-input-field',
    yourSalaryTooltip = 'your-salary-tooltip',
    employerContributionInput = 'employer-contribution-input',
    employerContributionInputField = 'employer-contribution-input-field',
    yourInsurancePremiumsInput = 'your-insurance-premiums-input',
    yourInsurancePremiumsInputField = 'your-insurance-premiums-input-field',
    employerPremiumsInput = 'employer-premiums-input',
    employerPremiumsInputField = 'employer-premiums-input-field',
    employmentIncomeInput = 'employment-income-input',
    employmentIncomeInputField = 'employment-income-input-field',
    taxableIncomeInput = 'taxable-income-input',
    taxableIncomeInputField = 'taxable-income-input-field',
    employerContributionTooltip = 'employer-contribution-tooltip',
    yourInsurancePremiumsTooltip = 'your-insurance-premiums-tooltip',
    employerPremiumsTooltip = 'employer-premiums-tooltip',
    employmentIncomeTooltip = 'employment-income-tooltip',
    taxableIncomeTooltip = 'taxable-income-tooltip',
    detailsWarningModal = 'details-warning-modal',
    noSalaryWarning = 'no-salary-warning',
    lowSalaryWarning = 'low-salary-warning',
    mcbWarning = 'mcb-warning',
    noPremiumsWarning = 'no-premiums-warning',
    noMemberInsuranceWarning = 'no-member-insurance-warning',
    noEmployerPremiumsWarning = 'no-employer-premiums-warning',
    noInsurancePremiumsWarning = 'no-insurance-premiums-warning',
    highPremiumsWarning = 'high-premiums-warning',
    aboveSgNoEmployerInsuranceWarning = 'above-sg-no-employer-insurance-warning',
    aboveSgEmployerInsuranceWarning = 'above-sg-employer-insurance-warning',
    highContributionsWarning = 'high-contributions-warning',
    otherIncomeLabel = 'other-income-label',
    otherIncomeTooltip = 'other-income-tooltip',
    otherIncomeRadioGroup = 'other-income-radio-group',
    otherIncomeYes = 'other-income-yes',
    otherIncomeNo = 'other-income-no',
    otherSuperRadioGroup = 'other-super-radio-group',
    otherSuperYes = 'other-super-yes',
    otherSuperNo = 'other-super-no',
    highContributionsModal = 'high-contributions-modal',
    otherSuperInput = 'other-super-input',
    otherSuperInputField = 'other-super-input-field',
    otherSuperModal = 'other-super-modal',
    otherSuperOverThreshold = 'other-super-over-threshold',
    otherSuperHelp = 'other-super-help',
    voluntaryContributionsInfo = 'voluntary-contributions-info',
    voluntaryContributionsGroup = 'voluntary-contributions-group',
    voluntaryContributionsYes = 'voluntary-contributions-yes',
    voluntaryContributionsNo = 'voluntary-contributions-no',
    voluntaryContributionsLabel = 'voluntary-contributions-label',
    contributionsMessageModal = 'contributions-message-modal',
    peopleLikeYouTT = 'people-like-you-tooltip',
    supersizerFurtherInfo='supersizer-further-info-link',
}

export default aboutYouTestIds;