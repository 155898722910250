import { faCheckCircle, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Paragraph, SecondaryButton, Text, Grid, Row, Col, Svg, Heading, PrimaryButton } from '@mlc/web-ui-toolkit';
import { Button } from '@mlc/web-ui-toolkit/lib/components/Button/Button';
import React, { useEffect, useContext, useState } from 'react';
import { AppThemeContext } from '../../../../../context/AppThemeContext';
import { Calculator } from '../../../../../logic/domains/calculator.domain';
import { gaEvent, getPageName } from '../../../../../logic/functions/gaTracking';
import { logAction } from '../../../../../logic/functions/logging.function';
import { focusHeading, getProfileTypeName } from '../../../../../logic/functions/util';
import { HelpText, NavButtonGroup, ResponsivePrimaryButton, ResponsiveSecondaryButton } from '../../../Common/Common.style';
import InfoPopup from '../../../Common/InfoPopup';
import { MobileDeviceQ } from '../../../Common/MediaQ';
import { exclamationTriangle } from '@mlc/symbols';
import * as palette from '@mlc/mlc-palette';
import { ButtonRow, ExtendedCard, FinishedRPButton, IndentedWithIcon, InvestmentPreference, InvestmentPreferencesContent, InvestmentPreferenceSection, RiskProfileMobileHeading, RiskProfileMobileHeadingContent, RpSectionContainer } from '../CalculatorSteps.style';
import RiskProfileQuestionnaire from './RiskProfileQuestionnaire';
import riskProfileTestIds from './RiskProfile.elements';
import calculatorStepsTestIds from '../CalculatorSteps.elements';
import RiskProfileGraphContainer from "./RiskProfileGraphContainer";

type Props = {
    formikProps: any;
    setUpdatingMember: () => void;
    handleNextStep: () => void;
    handlePreviousStep: () => void;
}

const ProfilerBtn = ({ children, ...restProps }: any) => (
    <MobileDeviceQ
        content={
            <PrimaryButton {...restProps}>
                {children}
            </PrimaryButton>
        }
        mobileContent={
            <ResponsivePrimaryButton {...restProps}>
                {children}
            </ResponsivePrimaryButton>
        }
    />
)

const FinishedProfilerBtn = ({ children, ...restProps }: any) => (
    <MobileDeviceQ
        content={
            <SecondaryButton {...restProps}>
                {children}
            </SecondaryButton>
        }
        mobileContent={
            <ResponsiveSecondaryButton {...restProps}>
                {children}
            </ResponsiveSecondaryButton>
        }
    />
)

//calculator step 3 of 7
const RiskProfile = (props: Props) => {
    const { setFieldValue } = props.formikProps;
    const values: Calculator = props.formikProps.values;
    const { investmentProfileType, selectedProfileType, maintainInvestments, suggestedProfileType, isNabGsfMember, selectedMixHasMySuper, inToolCustomMix, activeInvestment } = values;
    const [isQuestionnaireOpen, setIsQuestionnaireOpen] = useState(false);
    const content: any = useContext(AppThemeContext).staticContent.riskProfile;
    const [investmentPreferenceError, setInvestmentPreferenceError] = useState(false);

    useEffect(() => {
        focusHeading();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <IndentedWithIcon>
                <RpSectionContainer>
                    <Grid noGutter={true}>
                        {/* makes the graphs only appear after completing first questionnaire and data is fully loaded */}
                        {!(!!inToolCustomMix.type) || !inToolCustomMix.expectedReturn ? (
                            <>
                                <ExtendedCard className="with-background">
                                    <Grid>
                                        <Row>
                                            <Col col={12}>
                                                <Row>
                                                    <Col col={8}>
                                                        <Card height="100%" display="flex" alignItems="center">
                                                            <Heading el="h2" fontSize="large">
                                                                Your current investments align to a <Text fontWeight="semibold" className="branded-colour-dark">{getProfileTypeName(investmentProfileType.type!)}</Text> risk profile.
                                                            </Heading>
                                                        </Card>
                                                    </Col>
                                                    <Col col={4}>
                                                        <Card paddingTop="10px" paddingBottom="10px" display="flex" alignItems="center" justifyContent="center" height="100%">
                                                            <img alt="" width="100px" src={content.graph} />
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Grid>
                                </ExtendedCard>
                                <Row>
                                    <Col md={9} col={12}>
                                        <Card marginTop="20px">
                                            <Paragraph>
                                                It's important you invest your super in a way that makes you feel comfortable and helps you save for the retirement you want.
                                            </Paragraph>
                                            <Paragraph>
                                                Answer 5 questions to find out which risk profile is right for you.
                                            </Paragraph>
                                        </Card>
                                    </Col>
                                </Row>
                                <MobileDeviceQ
                                    content={
                                        <Grid>
                                            <ButtonRow>
                                                <SecondaryButton
                                                    type="button"
                                                    onClick={() => {
                                                        logAction("BACK_4")
                                                        props.handlePreviousStep();
                                                    }}
                                                    id="back-button"
                                                    data-id={calculatorStepsTestIds.calculatorTabPrevious}
                                                >
                                                    Back
                                                </SecondaryButton>
                                                <SecondaryButton
                                                    type="button"
                                                    onClick={() => {
                                                        logAction("SKIP_RP_QUIZ");
                                                        logAction("NEXT_4");
                                                        props.handleNextStep();
                                                    }}
                                                    id="next-button"
                                                    data-id={calculatorStepsTestIds.nextStepButton}
                                                >
                                                    Skip risk profile
                                                </SecondaryButton>
                                                <ProfilerBtn
                                                    type="button"
                                                    onClick={() => {
                                                        logAction("RP_QUIZ");
                                                        gaEvent('link', 'risk profile', getPageName());
                                                        setIsQuestionnaireOpen(true);
                                                    }}
                                                    data-id={riskProfileTestIds.exploreRiskProfile}
                                                >
                                                    Find out your risk profile
                                                </ProfilerBtn>
                                            </ButtonRow>

                                        </Grid>
                                    }
                                    mobileContent={
                                        <NavButtonGroup className="riskprofile">
                                            <ProfilerBtn
                                                type="button"
                                                onClick={() => {
                                                    logAction("RP_QUIZ");
                                                    gaEvent('link', 'risk profile', getPageName());
                                                    setIsQuestionnaireOpen(true);
                                                }}
                                                data-id={riskProfileTestIds.exploreRiskProfile}
                                            >
                                                Find out your risk profile
                                            </ProfilerBtn>
                                            <SecondaryButton
                                                type="button"
                                                onClick={() => {
                                                    logAction("BACK_4")
                                                    props.handlePreviousStep();
                                                }}
                                                id="back-button"
                                                data-id={calculatorStepsTestIds.calculatorTabPrevious}
                                            >
                                                Back
                                            </SecondaryButton>
                                            <SecondaryButton
                                                className="alignButton"
                                                type="button"
                                                onClick={() => {
                                                    logAction("SKIP_RP_QUIZ");
                                                    logAction("NEXT_4");
                                                    props.handleNextStep();
                                                }}
                                                id="next-button"
                                                data-id={calculatorStepsTestIds.nextStepButton}
                                            >
                                                Skip risk profile
                                            </SecondaryButton>
                                        </NavButtonGroup>
                                    } />
                            </>
                        ) : (
                            <>
                                <ExtendedCard className="with-background">
                                    <Grid>
                                        <MobileDeviceQ
                                            content={
                                                <Row>
                                                    <Col col={12}>
                                                        <Row>
                                                            <Col col={8}>
                                                                <Card paddingTop="25px" paddingBottom="25px">
                                                                    <Heading paddingBottom="5px" el="h2" fontSize="large">
                                                                        Which risk profile do you want for your investment strategy?
                                                                    </Heading>
                                                                    <Text display="block">
                                                                        Your current investments align to a <Text fontWeight="semibold">{getProfileTypeName(investmentProfileType.type!)}</Text> risk profile.
                                                                    </Text>
                                                                    <Text>Compare this to your new risk profile <Text data-id={riskProfileTestIds.suggestedProfileType} fontWeight="semibold" className="branded-colour-dark">{getProfileTypeName(inToolCustomMix.type)} {suggestedProfileType === inToolCustomMix.type ? '(recommended)' : '(selected)'}.</Text></Text>
                                                                </Card>
                                                            </Col>
                                                            <Col col={4}>
                                                                <Card display="flex" alignItems="center" justifyContent="center" height="100%">
                                                                    <img alt="" width="100px" src={content.graph} />
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            }
                                            mobileContent={
                                                <RiskProfileMobileHeading>
                                                    <RiskProfileMobileHeadingContent>
                                                        <Heading paddingBottom="5px" el="h2" fontSize="large">
                                                            Which risk profile do you want for your investment strategy?
                                                        </Heading>
                                                        <img alt="" src={content.graph} />
                                                    </RiskProfileMobileHeadingContent>
                                                    <Text display="block">
                                                        Your current investments align to a <Text fontWeight="semibold">{getProfileTypeName(investmentProfileType.type!)}</Text> risk profile.
                                                    </Text>
                                                    <Text>Compare this to your new risk profile <Text data-id={riskProfileTestIds.suggestedProfileType} fontWeight="semibold" className="branded-colour-dark">{getProfileTypeName(inToolCustomMix.type)} {suggestedProfileType === inToolCustomMix.type ? '(recommended)' : '(selected)'}.</Text></Text>
                                                </RiskProfileMobileHeading>
                                            }
                                        />
                                    </Grid>
                                </ExtendedCard>
                                <RiskProfileGraphContainer setFieldValue={setFieldValue} investmentProfileType={investmentProfileType} selectedProfileType={selectedProfileType} inToolCustomMix={inToolCustomMix} hasAlternateAssetAllocation={!isNabGsfMember && selectedMixHasMySuper} values={values} maintainInvestments={maintainInvestments} setUpdatingMember={props.setUpdatingMember} />
                                <br />
                                <ExtendedCard>
                                    <Grid>
                                        <Row>
                                            <Col col={12}>
                                                <Card>
                                                    <Text fontWeight='semibold'>Your investment preference</Text>
                                                    <br /><br />
                                                    <Text>There are different approaches to how your super is invested.<InfoPopup label="information about investment preference" content={content.managementTooltipContent} dataId={riskProfileTestIds.investmentPreferenceTooltip} log="INVESTMENT_PREFERENCE_TT" /></Text>
                                                    <br />
                                                    <Text>Where there is a choice for your risk profile, how would you prefer to have your money managed?</Text>
                                                    <InvestmentPreferenceSection>
                                                        <InvestmentPreference data-id={riskProfileTestIds.activeManagementContent} className={activeInvestment === true ? "selected" : ""}>
                                                            <InvestmentPreferencesContent>
                                                                <Button type="button" className="selectionButton"
                                                                    aria-describedby={riskProfileTestIds.investmentPreferenceError}
                                                                    aria-invalid={investmentPreferenceError}
                                                                    onClick={() => {
                                                                        if (activeInvestment !== true) {
                                                                            logAction("ACTIVE_MANAGEMENT_SELECT");
                                                                            setInvestmentPreferenceError(false);
                                                                            setFieldValue("activeInvestment", true);
                                                                            props.setUpdatingMember();
                                                                        }
                                                                    }}
                                                                    data-id={riskProfileTestIds.activeManagementButton}>
                                                                    <FinishedRPButton className="selectionButton">
                                                                        <FontAwesomeIcon size="2x" icon={activeInvestment ? faCheckCircle : faCircle} />
                                                                        <Text fontWeight="semibold">Active management</Text>
                                                                    </FinishedRPButton>
                                                                </Button>
                                                            </InvestmentPreferencesContent>
                                                            <InvestmentPreferencesContent className="image">
                                                                <img width="auto" height="70px" alt="" src={content.active} />
                                                            </InvestmentPreferencesContent>
                                                            <InvestmentPreferencesContent>
                                                                <Text textAlign="center" className="content">You want to try to earn higher returns than the market over the long term and you're OK if this costs more.</Text>
                                                            </InvestmentPreferencesContent>
                                                        </InvestmentPreference>
                                                        <InvestmentPreference data-id={riskProfileTestIds.passiveManagementContent} className={activeInvestment === false ? "selected" : ""}>
                                                            <InvestmentPreferencesContent>
                                                                <Button type="button" className="selectionButton"
                                                                    aria-describedby={riskProfileTestIds.investmentPreferenceError}
                                                                    aria-invalid={investmentPreferenceError}
                                                                    onClick={() => {
                                                                        if (activeInvestment !== false) {
                                                                            logAction("PASSIVE_MANAGEMENT_SELECT");
                                                                            setInvestmentPreferenceError(false);
                                                                            setFieldValue("activeInvestment", false);
                                                                            props.setUpdatingMember();
                                                                        }
                                                                    }}
                                                                    data-id={riskProfileTestIds.passiveManagementButton}>
                                                                    <FinishedRPButton className="selectionButton">
                                                                        <FontAwesomeIcon size="2x" icon={activeInvestment === false ? faCheckCircle : faCircle} />
                                                                        <Text fontWeight="semibold">Passive management (Lower cost)</Text>
                                                                    </FinishedRPButton>
                                                                </Button>
                                                            </InvestmentPreferencesContent>
                                                            <InvestmentPreferencesContent className="image">
                                                                <img width="auto" height="70px" alt="" src={content.passive} />
                                                            </InvestmentPreferencesContent>
                                                            <InvestmentPreferencesContent>
                                                                <Text textAlign="center" className="content">You want to keep your investment costs down and accept achieving similar returns to the market.</Text>
                                                            </InvestmentPreferencesContent>
                                                        </InvestmentPreference>
                                                    </InvestmentPreferenceSection>
                                                    {investmentPreferenceError && (
                                                        <HelpText marginTop={1} id={riskProfileTestIds.investmentPreferenceError} color={palette.mlcError}>
                                                            <Svg symbol={exclamationTriangle} /> Please select between passive or active investment management.
                                                        </HelpText>
                                                    )}
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Grid>
                                </ExtendedCard>
                                <MobileDeviceQ
                                    content={
                                        <ButtonRow>
                                            <SecondaryButton
                                                type="button"
                                                onClick={() => {
                                                    logAction("BACK_4")
                                                    props.handlePreviousStep();
                                                }}
                                                id="back-button"
                                                data-id={calculatorStepsTestIds.calculatorTabPrevious}
                                            >
                                                Back
                                            </SecondaryButton>
                                            <SecondaryButton
                                                type="button"
                                                onClick={() => {
                                                    logAction("RP_QUIZ_REDO");
                                                    gaEvent('link', 'redo risk profile', getPageName());
                                                    setIsQuestionnaireOpen(true);
                                                }}
                                                data-id={riskProfileTestIds.redoQuestionnaire}
                                            >
                                                Re-do risk profiler
                                            </SecondaryButton>
                                            <PrimaryButton
                                                type="button"
                                                onClick={() => {
                                                    if (activeInvestment === null && suggestedProfileType != null) {
                                                        setInvestmentPreferenceError(true);
                                                    } else {
                                                        logAction("NEXT_4");
                                                        props.handleNextStep();
                                                    }
                                                }}
                                                id="next-button"
                                                data-id={calculatorStepsTestIds.nextStepButton}
                                            >
                                                Next
                                            </PrimaryButton>
                                        </ButtonRow>
                                    }
                                    mobileContent={
                                        <NavButtonGroup className="riskprofile">
                                            <FinishedProfilerBtn
                                                type="button"
                                                onClick={() => {
                                                    logAction("RP_QUIZ_REDO");
                                                    gaEvent('link', 'redo risk profile', getPageName());
                                                    setIsQuestionnaireOpen(true);
                                                }}
                                                data-id={riskProfileTestIds.redoQuestionnaire}
                                            >
                                                Re-do risk profiler
                                            </FinishedProfilerBtn>
                                            <SecondaryButton
                                                type="button"
                                                onClick={() => {
                                                    logAction("BACK_4")
                                                    props.handlePreviousStep();
                                                }}
                                                id="back-button"
                                                data-id={calculatorStepsTestIds.calculatorTabPrevious}
                                            >
                                                Back
                                            </SecondaryButton>
                                            <PrimaryButton
                                                className="alignButton"
                                                type="button"
                                                onClick={() => {
                                                    if (activeInvestment === null && suggestedProfileType != null) {
                                                        setInvestmentPreferenceError(true);
                                                    } else {
                                                        logAction("NEXT_4");
                                                        props.handleNextStep();
                                                    }
                                                }}
                                                id="next-button"
                                                data-id={calculatorStepsTestIds.nextStepButton}
                                            >
                                                Next
                                            </PrimaryButton>
                                        </NavButtonGroup>
                                    } />
                            </>
                        )
                        }
                    </Grid>
                </RpSectionContainer>
            </IndentedWithIcon >
            <RiskProfileQuestionnaire
                isOpen={isQuestionnaireOpen}
                setOpenFunction={setIsQuestionnaireOpen}
                formikProps={props.formikProps}
                setUpdatingMember={props.setUpdatingMember}
            />
        </>
    );
};

export default RiskProfile;
