enum pdfViewerTestIds {
    pdfViewer = 'pdf-viewer',
    pdfViewerSideBar = 'pdf-viewer-side-bar',
    pdfViewerSideNext = 'pdf-viewer-side-next',
    pdfViewerSideBack = 'pdf-viewer-side-back',
    pdfViewerControlsNext = 'pdf-viewer-controls-next',
    pdfViewerControlsEnd = 'pdf-viewer-controls-end',
    pdfViewerControlsBack = 'pdf-viewer-controls-back',
    pdfViewerControlsStart = 'pdf-viewer-controls-start',
    pdfViewerControlsToc = 'pdf-viewer-controls-toc',
    pdfViewerControlsGrid = 'pdf-viewer-controls-grid',
    pdfViewerControlsFullscreen = 'pdf-viewer-controls-fullscreen',
    pdfViewerControlsZoomIn = 'pdf-viewer-controls-zoom-in',
    pdfViewerControlsZoomOut = 'pdf-viewer-controls-zoom-out',
    pdfViewerControlsDownload = 'pdf-viewer-controls-download',
}

export default pdfViewerTestIds;