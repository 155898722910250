import { csrfToken } from '../../context/AppThemeContext';
import { errorInResponse, redirectToError } from './util';

const CONTENT_TYPE_NAME = 'content-type';
const CONTENT_TYPE_VALUE = 'application/json';
const PLAINTEXT_CONTENT_TYPE = 'text/html';
export const SKIN = 'skin';

export type ErrorResponse = {
	ok: boolean;
	status: number;
	message?: string;
	errorId?: string;
};

export type Options = {
	isPlainText?: boolean;
	noErrorRedirect?: boolean;
	isBlob?: boolean;
	saveXsrf?: boolean;
	keepAlive?: boolean;
}

export const get = async (url: string, options?: Options) => {
	return await coreFetch(url, options);
};

export const post = async (url: string, payload: any, options?: Options) => {
	return await coreFetch(url, options, 'post', payload);
};

export const put = async (url: string, payload: any, options?: Options) => {
	return await coreFetch(url, options, 'put', payload);
};

const getDownloadedFilename = (header: string | null) => {
	return header ? header.split('filename=')[1].split(" ")[0] : '';
}

const coreFetch = async (
	url: string,
	options?: Options,
	method: string = 'get',
	body?: any,
) => {
	const headers = new Headers();
	const isPlainText = options && options.isPlainText;

	headers.append(CONTENT_TYPE_NAME, isPlainText ? PLAINTEXT_CONTENT_TYPE : CONTENT_TYPE_VALUE);

	const toReturn = new Promise(async (resolve, reject) => {
		let init: {
			headers: any;
			method: string;
			body?: any;
			credentials?: RequestCredentials;
			keepalive?: boolean;
		} = {
			headers,
			method: method,
			credentials: 'include',
			keepalive: options?.keepAlive || false
		};
		if (method === 'post' || method === 'put') {
			init.headers.append('X-XSRF-TOKEN', csrfToken.value);
			init = { ...init, body: JSON.stringify(body)};
		}

		try {
			let response = await fetch(url, init);
			if (response.ok) {
				try {
					if (isPlainText) {
						resolve(await response.text());
					} else if (options && options.isBlob) {
						response
							.blob()
							.then((blob: Blob) => {
								const packet = {
									filename: getDownloadedFilename(response.headers.get('Content-Disposition')),
									content: blob,
								};
								resolve(packet);
							})
							//@ts-ignore
							.catch(() => resolve());
					} else {
						const json = await response.json();
						if (errorInResponse(json) && json.errorCode === 401) {
							redirectToError(401);
						} else {
							if (options && options.saveXsrf) {
								if (response.headers.has('xsrf-token')) {
									json.xsrf = response.headers.get('xsrf-token');
								}
							}
							resolve(json);
						}
					}
				} catch (err) {
					// There may not be a response body
					//@ts-ignore
					resolve();
				}
			} else {
				if (!options || (options && !options.noErrorRedirect)) {
					[100, 400, 401, 403, 404, 500].indexOf(response.status) >= 0 ? redirectToError(response.status) : redirectToError(500);
				} else {
					reject('');
				}
			}
		} catch (error) {
			if (!options || (options && !options.noErrorRedirect)) {
				redirectToError(500);
			} else {
				reject('');
			}
		}
	});

	return toReturn;
};
