import { plus } from '@mlc/symbols';
import {
    Card,
    Col,
    DatePicker,
    Divider,
    FieldWrapper,
    Grid,
    Heading,
    Label,
    LoadingIndicator,
    NumberInput,
    Paragraph,
    Row,
    ScreenReaderText,
    Text,
    TextLink, ToggleButton,
    ToggleButtonGroup
} from '@mlc/web-ui-toolkit';
import _ from 'lodash';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import useForceUpdate from 'use-force-update';
import { IncomeOwnerType } from '../../../../../enums';
import { Calculator } from '../../../../../logic/domains/calculator.domain';
import { asCurrency } from '../../../../../logic/functions/formatter';
import { logAction } from '../../../../../logic/functions/logging.function';
import { getAgePensionEstimate } from '../../../../../logic/functions/memberData.function';
import { convertAgePensionValues, convertToNumber, DATE_FORMAT, focusFirstError, isValidPartnerDob } from '../../../../../logic/functions/util';
import { commonClasses } from '../../../Common/Common.elements';
import { WiderModal } from '../../../Common/Common.style';
import FieldWithTooltip from '../../../Common/FieldWithTooltip';
import InfoPopup from '../../../Common/InfoPopup';
import PageIndicator from '../../../Common/PageIndicator';
import Select from '../../../Common/Select';
import { AddOtherIncomeHeading, AddOtherIncomeSymbol, CalculatedAgePension, FlexSegment } from '../CalculatorSteps.style';
import retirementGoalsTestIds from './RetirementGoals.elements';

type Props = {
    isOpen: any;
    onCancel: () => void;
    setOpenFunction: (isOpen: boolean) => void;
    formikProps: any;
    setUpdatingMember: () => void;
    setForceUpdating: () => void;
}

const AgePensionModal = ({ isOpen, onCancel, setOpenFunction, setUpdatingMember, setForceUpdating, formikProps }: Props) => {
    const { setFieldValue } = formikProps;
    const values: Calculator = formikProps.values;
    const {
        isHomeowner,
        hasPartner,
        financialInvestments,
        nonFinancialAssets,
        agePensionOtherIncome,
        agePensionOtherIncomeCombine,
        partnerSuperBalance,
        partnerDob,
        partnerIncomeAgeFrom,
        partnerIncomeAgeTo,
        partnerIncome,
        agePension
    } = values;

    const [formValues, setFormValues] = useState({
        isHomeowner: isHomeowner,
        hasPartner: hasPartner,
        partnerIncomeAgeFrom: partnerIncomeAgeFrom,
        partnerIncomeAgeTo: partnerIncomeAgeTo,
        partnerDob: partnerDob,
        financialInvestments: financialInvestments,
        nonFinancialAssets: nonFinancialAssets,
        partnerSuperBalance: partnerSuperBalance,
        partnerIncome: partnerIncome,
        agePensionOtherIncomeCombine: agePensionOtherIncomeCombine,
        agePensionOtherIncome: agePensionOtherIncome
    });

    const forceUpdate = useForceUpdate();
    const [pageNo, setPageNo] = useState(0);
    const [shouldShowErrors, setShouldShowErrors] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [loading, setLoading] = useState(false);
    let page = pageNo + 1;

    useEffect(() => {
        // only sets calculator form values after finishing the modal otherwise the values are only local to the modal
        const getAgePension = async () => {
            setLoading(true);
            setFieldValue("isHomeowner", formValues.isHomeowner)
            setFieldValue("hasPartner", formValues.hasPartner);
            setFieldValue("partnerIncomeAgeFrom", formValues.partnerIncomeAgeFrom);
            setFieldValue("partnerIncomeAgeTo", formValues.partnerIncomeAgeTo);
            setFieldValue("partnerDob", formValues.partnerDob);
            setFieldValue("financialInvestments", formValues.financialInvestments);
            setFieldValue("nonFinancialAssets", formValues.nonFinancialAssets);
            setFieldValue("partnerSuperBalance", formValues.partnerSuperBalance);
            setFieldValue("partnerIncome", formValues.partnerIncome);
            setFieldValue("agePensionOtherIncomeCombine", formValues.agePensionOtherIncomeCombine);
            setFieldValue("agePensionOtherIncome", formValues.agePensionOtherIncome);
            const data = await getAgePensionEstimate(convertAgePensionValues(formValues));
            setFieldValue("agePension", data.monthlyAgePension);
            setLoading(false);
        }

        if (pageNo === 1) {
            let partnerAge = moment().diff(moment(formValues.partnerDob, DATE_FORMAT), "years");
            let ageTo;
            //only update retirement age if partner age is changing
            if (formValues.partnerIncomeAgeFrom !== partnerAge) {
                if (partnerAge <= 66) {
                    ageTo = 67;
                } else {
                    ageTo = partnerAge + 1;
                }
            } else {
                ageTo = formValues.partnerIncomeAgeTo;
            }
            setFormValues({
                ...formValues,
                partnerIncomeAgeFrom: partnerAge,
                partnerIncomeAgeTo: ageTo
            });
        }

        if (pageNo === 3) {
            getAgePension();
        }

        const header = document.querySelector(`[data-id=${retirementGoalsTestIds.agePensionModal}]`)?.querySelector('h1');
        if (header) {
            setTimeout(() => {
                header.focus();
            }, 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNo]);

    useEffect(() => {
        const validate = () => {
            let newErrors: any = {};

            if (pageNo === 1) {
                if (isNaN(formValues.financialInvestments) || formValues.financialInvestments < 0 || formValues.financialInvestments > 9999999) {
                    newErrors.financialInvestments = true;
                }

                if (isNaN(formValues.nonFinancialAssets) || formValues.nonFinancialAssets < 0 || formValues.nonFinancialAssets > 9999999) {
                    newErrors.nonFinancialAssets = true;
                }

                if (formValues.hasPartner === 'y') {
                    if (isNaN(formValues.partnerSuperBalance) || formValues.partnerSuperBalance < 0 || formValues.partnerSuperBalance > 9999999) {
                        newErrors.partnerSuperBalance = true;
                    }

                    if (isNaN(formValues.partnerIncome) || formValues.partnerIncome < 0 || formValues.partnerIncome > 9999999) {
                        newErrors.partnerIncome = true;
                    }

                    if (isNaN(formValues.partnerIncomeAgeTo) || formValues.partnerIncomeAgeTo <= formValues.partnerIncomeAgeFrom || formValues.partnerIncomeAgeTo > 105) {
                        newErrors.partnerIncomeAgeTo = true;
                    }
                }
            }

            if (pageNo === 2) {
                if (formValues.hasPartner === 'n') {
                    let agePensionOtherIncomeErrors: any = [];
                    formValues.agePensionOtherIncome.forEach((income: any) => {
                        agePensionOtherIncomeErrors.push(isNaN(income.incomeAmount) || income.incomeAmount < 0 || income.incomeAmount > 9999999);
                    })
                    if (agePensionOtherIncomeErrors.some((error: any) => error)) newErrors.agePensionOtherIncome = agePensionOtherIncomeErrors;
                } else {
                    let agePensionOtherIncomeCombineErrors: any = [];
                    formValues.agePensionOtherIncomeCombine.forEach((income: any) => {
                        agePensionOtherIncomeCombineErrors.push(isNaN(income.incomeAmount) || income.incomeAmount < 0 || income.incomeAmount > 9999999);
                    })
                    if (agePensionOtherIncomeCombineErrors.some((error: any) => error)) newErrors.agePensionOtherIncomeCombine = agePensionOtherIncomeCombineErrors;
                }
            }

            setErrors(newErrors);
        };

        validate();
    }, [formValues, pageNo]);

    const cancelModal = () => {
        resetValues();
        onCancel();
        setUpdatingMember();
        setPageNo(0);
    }

    const backBtn = {
        content: "Back",
        onClick: () => {
            logAction(`AGE_PENSION_BACK_${pageNo + 1}`);
            setPageNo(pageNo - 1);
        },
        buttonType: "tertiary",
    };

    const nextBtn = {
        content: "Next",
        onClick: (e: any) => {
            logAction(`AGE_PENSION_NEXT_${pageNo + 1}`);
            if ((pageNo === 0 && (!formValues.isHomeowner || !formValues.hasPartner || (formValues.hasPartner === 'y' && !isValidPartnerDob(formValues.partnerDob)))) || !_.isEmpty(errors)) {
                setShouldShowErrors(true);
                focusFirstError();
            } else {
                setPageNo(pageNo + 1);
            }
        },
        buttonType: "primary",
    };

    const resetValues = () => setFormValues({
        isHomeowner: isHomeowner,
        hasPartner: hasPartner,
        partnerIncomeAgeFrom: partnerIncomeAgeFrom,
        partnerIncomeAgeTo: partnerIncomeAgeTo,
        partnerDob: partnerDob,
        financialInvestments: financialInvestments,
        nonFinancialAssets: nonFinancialAssets,
        partnerSuperBalance: partnerSuperBalance,
        partnerIncome: partnerIncome,
        agePensionOtherIncomeCombine: agePensionOtherIncomeCombine,
        agePensionOtherIncome: agePensionOtherIncome
    });

    const ageModalButtons = [
        [
            nextBtn,
            {
                content: "Cancel",
                onClick: () => {
                    logAction(`AGE_PENSION_BACK_1`);
                    cancelModal();
                },
                buttonType: "tertiary",
            },
        ],
        [nextBtn, backBtn],
        [nextBtn, backBtn],
        [
            {
                content: !loading ? "Continue" : "Loading...",
                onClick: () => {
                    logAction(`AGE_PENSION_NEXT_4`);
                    setOpenFunction(false);
                    setFieldValue("includeAgePension", "y");
                    setFieldValue("agePensionInfoComplete", true);
                    setForceUpdating();
                    setUpdatingMember();
                    setPageNo(0);
                },
                buttonType: "primary",
                isLoading: loading
            },
            backBtn
        ]
    ];

    const incomeOptions = [
        { value: [IncomeOwnerType.Member], label: "Other income (yours)" },
        { value: [IncomeOwnerType.Partner], label: "Other income (partner)", needsPartner: 'y' },
        { value: [IncomeOwnerType.Combined], label: "Other income (combined)", needsPartner: 'y' }
    ];

    let content;
    if (pageNo === 0) {
        content = (
            <Card>
                <Paragraph>
                    Please fill in your details so we can help you estimate your Age Pension at retirement. The estimate is based on the details you provide. The estimate may not benefit you if you don't include accurate details for you and your partner (if applicable). Amounts included here will only be used for the Age Pension estimate. These values won't be included in the Personal Super Calculator. Please contact us if you need help.
                </Paragraph>
                <FieldWrapper
                    label="Homeowner at Age Pension age?"
                    error="Please choose an option."
                    isInvalid={shouldShowErrors && !formValues.isHomeowner}
                >
                    <ToggleButtonGroup
                        name="homeowner"
                        value={formValues.isHomeowner}
                        onChange={(e: any) => setFormValues({ ...formValues, isHomeowner: e.target.value })}
                        data-id={retirementGoalsTestIds.homeownerGroup}
                    >
                        <ToggleButton
                            value="y"
                            data-id={retirementGoalsTestIds.homeownerYes}
                            className={shouldShowErrors && !formValues.isHomeowner ? commonClasses.validationError : null}
                        >
                            Yes
                        </ToggleButton>
                        <ToggleButton
                            value="n"
                            data-id={retirementGoalsTestIds.homeownerNo}
                        >
                            No
                        </ToggleButton>
                    </ToggleButtonGroup>
                </FieldWrapper>
                <Card marginBottom={6}>
                    <FieldWrapper
                        label={
                            <>
                                <Label id="partner-label">Do you have a partner?</Label>
                                <InfoPopup
                                    content="A single pensioner includes an unmarried, separated, divorced or widowed person. A pensioner couple includes married and de facto couples. A pensioner couple can arise where both members of a couple are eligible to apply for the Age Pension or where only one member of the couple is eligible to apply for the Age Pension. In the latter situation, the Age Pension is determined by applying the Income and Assets tests using combined income and assets for the couple and halving the combined rate of payment."
                                    dataId={retirementGoalsTestIds.partnerInfo}
                                    log="PARTNER_QUESTION_TT"
                                />
                            </>
                        }
                        error="Please choose an option."
                        isInvalid={shouldShowErrors && !formValues.hasPartner}
                    >
                        <ToggleButtonGroup
                            name="partner"
                            value={formValues.hasPartner}
                            onChange={(e: any) => setFormValues({ ...formValues, hasPartner: e.target.value })}
                            data-id={retirementGoalsTestIds.partnerGroup}
                            isInvalid={shouldShowErrors && !formValues.hasPartner}
                            aria-labelledby="partner-label"
                        >
                            <ToggleButton
                                value="y"
                                data-id={retirementGoalsTestIds.partnerYes}
                                className={shouldShowErrors && !formValues.hasPartner ? commonClasses.validationError : null}
                            >
                                Yes
                            </ToggleButton>
                            <ToggleButton
                                value="n"
                                data-id={retirementGoalsTestIds.partnerNo}
                            >
                                No
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </FieldWrapper>
                </Card>
                <Row>
                    <Col md={7} col={12}>
                        {formValues.hasPartner === "y" && (
                            <FieldWrapper
                                label="What is your partner's date of birth?"
                                subLabel="(DD/MM/YYYY)"
                                isInvalid={shouldShowErrors && !isValidPartnerDob(formValues.partnerDob)}
                                error="Please enter a valid date of birth for your partner (age 18 - 100)."
                                id={retirementGoalsTestIds.partnerDob}
                            >
                                <DatePicker
                                    value={moment(formValues.partnerDob, DATE_FORMAT).format(DATE_FORMAT)}
                                    onBlur={(event: any) => {
                                        let dob = moment(event.target.value, DATE_FORMAT).format(DATE_FORMAT);
                                        setFormValues({ ...formValues, partnerDob: dob });
                                    }}
                                    data-id={retirementGoalsTestIds.partnerDob}
                                    isInvalid={shouldShowErrors && !isValidPartnerDob(formValues.partnerDob)}
                                    className={(shouldShowErrors && !isValidPartnerDob(formValues.partnerDob)) ? commonClasses.validationError : null}
                                />
                            </FieldWrapper>
                        )}
                    </Col>
                </Row>
            </Card>
        )
    }

    if (pageNo === 1) {
        content = (
            <Card>
                <Heading el="h2" fontSize="tiny">Your {formValues.hasPartner === "y" && "and your partner's"} assets outside super</Heading>
                <Divider />
                <Row>
                    <Col md={7} col={12}>
                        <FieldWithTooltip
                            label="Financial investments (current net value)"
                            tooltipContent={
                                <>
                                    <>Financial investments include cash, bank accounts, term deposits, bonds, investment funds, shares etc. If you have chosen to include your partner, please include their financial investments here too. The deemed income from your financial investments are used for Age Pension calculations.</><br /><br />
                                    <>Please note that superannuation after Age Pension age is also a financial asset. We have already included your superannuation for Age Pension calculations.</>
                                </>
                            }
                            tooltipId={retirementGoalsTestIds.financialInvestmentsInfo}
                            tooltipLabel="Information about financial investments"
                            tooltipLog="FIN_INVESTMENTS_TT"
                            id={retirementGoalsTestIds.financialInvestments}
                            error="Please enter an amount between $0 and $9,999,999."
                            isInvalid={errors.financialInvestments}
                        >
                            <NumberInput
                                value={formValues.financialInvestments.toString()}
                                prefix="$"
                                onBlur={(e: any) => setFormValues({ ...formValues, financialInvestments: convertToNumber(e.target.value) })}
                                id="financial-investments-input"
                                allowDecimal={false}
                                allowLeadingZeroes={true}
                                integerLimit={9}
                                className={errors.financialInvestments ? commonClasses.validationError : ''}
                                data-analytics-capture-value
                            />
                        </FieldWithTooltip>
                    </Col>
                </Row>
                <Row>
                    <Col md={7} col={12}>
                        <FieldWithTooltip
                            label="Non-financial assets (current net value)"
                            tooltipContent="Non-financial assets includes real estate (apart from your principal home), cars, jewellery, antiques, home contents, etc. If you have chosen to include your partner, please include their non-financial assets here too."
                            tooltipId={retirementGoalsTestIds.nonFinancialAssetsInfo}
                            tooltipLabel="Information about non-financial assets"
                            tooltipLog="NON_FIN_ASSETS_TT"
                            id={retirementGoalsTestIds.nonFinancialAssets}
                            error="Please enter an amount between $0 and $9,999,999."
                            isInvalid={errors.nonFinancialAssets}
                        >
                            <NumberInput
                                value={formValues.nonFinancialAssets.toString()}
                                prefix="$"
                                onBlur={(e: any) => setFormValues({ ...formValues, nonFinancialAssets: convertToNumber(e.target.value) })}
                                id="non-financial-assets-input"
                                allowDecimal={false}
                                allowLeadingZeroes={true}
                                integerLimit={9}
                                className={errors.nonFinancialAssets ? commonClasses.validationError : ''}
                                data-analytics-capture-value
                            />
                        </FieldWithTooltip>
                    </Col>
                </Row>
                {formValues.hasPartner === "y" && (
                    <>
                        <Divider />
                        <Row>
                            <Col md={7} col={12}>
                                <FieldWrapper
                                    label="Your partner's super balance"
                                    error="Please enter an amount between $0 and $9,999,999."
                                    isInvalid={errors.partnerSuperBalance}
                                >
                                    <NumberInput
                                        value={formValues.partnerSuperBalance.toString()}
                                        prefix="$"
                                        onBlur={(e: any) => setFormValues({ ...formValues, partnerSuperBalance: convertToNumber(e.target.value) })}
                                        data-id={retirementGoalsTestIds.partnerSuper}
                                        allowDecimal={false}
                                        allowLeadingZeroes={true}
                                        integerLimit={9}
                                        className={errors.partnerSuperBalance ? commonClasses.validationError : ''}
                                        data-analytics-capture-value
                                    />
                                </FieldWrapper>
                            </Col>
                        </Row>
                        <Heading el="h2" fontSize="tiny">Your partner's employment income</Heading>
                        <Divider />
                        <Row>
                            <Col col={12} md={6}>
                                <Card display="flex">
                                    <FlexSegment>
                                        <Label>Age from</Label>
                                        <Text
                                            display="block"
                                            data-id={retirementGoalsTestIds.partnerAgeFrom}
                                        >
                                            {formValues.partnerIncomeAgeFrom}
                                        </Text>
                                    </FlexSegment>
                                    <FlexSegment marginLeft="-30px">
                                        <FieldWrapper
                                            label="Age to"
                                            error={`Please enter an age between ${formValues.partnerIncomeAgeFrom + 1} and 105.`}
                                            isInvalid={errors.partnerIncomeAgeTo}
                                        >
                                            <NumberInput
                                                value={formValues.partnerIncomeAgeTo.toString()}
                                                onBlur={(e: any) => setFormValues({ ...formValues, partnerIncomeAgeTo: convertToNumber(e.target.value) })}
                                                data-id={retirementGoalsTestIds.partnerAgeTo}
                                                allowDecimal={false}
                                                allowLeadingZeroes={true}
                                                integerLimit={3}
                                                className={errors.partnerIncomeAgeTo ? commonClasses.validationError : ''}
                                            />
                                        </FieldWrapper>
                                    </FlexSegment>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={7} col={12}>
                                <FieldWithTooltip
                                    label="Partner employment income (per annum)"
                                    id={retirementGoalsTestIds.partnerEmploymentIncome}
                                    tooltipContent={
                                        <>
                                            <>Employment income is the total remuneration including salary, wages, commissions, salary sacrificed amounts and reportable fringe benefits. Additional information is available on the Department of Human Services website.</><br /><br />
                                            <>If you or your partner continue working once reaching age pension age, the Work Bonus will apply. Work Bonus rules applies to pensioners over Age Pension age who receive employment income and are not on the transitional rules. The first $11,800 per annum of employment income per person will not be counted under the income test. It is assumed that the person does not have any existing amount in the income bank. It is also assumed that the income is evenly distributed throughout the year.</>
                                        </>
                                    }
                                    tooltipId={retirementGoalsTestIds.partnerEmploymentIncomeInfo}
                                    tooltipLabel="Information about partner employment income"
                                    tooltipLog="PARTNER_INCOME_TT"
                                    error="Please enter an amount between $0 and $9,999,999."
                                    isInvalid={errors.partnerIncome}
                                >
                                    <NumberInput
                                        value={formValues.partnerIncome.toString()}
                                        prefix="$"
                                        onBlur={(e: any) => setFormValues({ ...formValues, partnerIncome: convertToNumber(e.target.value) })}
                                        allowDecimal={false}
                                        allowLeadingZeroes={true}
                                        integerLimit={9}
                                        className={errors.partnerIncome ? commonClasses.validationError : ''}
                                        data-analytics-capture-value
                                    />
                                </FieldWithTooltip>
                            </Col>
                        </Row>
                    </>
                )}
            </Card >
        )
    }

    if (pageNo === 2) {
        const otherIncome = formValues.hasPartner === "y" ? formValues.agePensionOtherIncomeCombine : formValues.agePensionOtherIncome;
        const otherIncomeName = formValues.hasPartner === "y" ? "agePensionOtherIncomeCombine" : "agePensionOtherIncome";

        content = (
            <>
                <Heading el="h2" fontSize="tiny" display="inline">Other income in retirement (per annum)</Heading>
                <InfoPopup content="Other income includes income derived from private company shares, family trusts, net rental income, businesses and partnerships. It excludes employment income. Information on how the Government determines this income for Centrelink purposes is available on the Department of Human Services website." label="Information about other income" dataId={retirementGoalsTestIds.agePensionOtherIncomeInfo} log="AGE_PENSION_OTHER_INCOME_TT"/>
                <Divider />
                <Paragraph marginBottom={6} id={retirementGoalsTestIds.agePensionOtherIncomeHelp}>Other income includes income you expect to receive in your retirement from property and businesses. Do not include any investment income as income from your investments is deemed by Centrelink based on the value of your financial investments. This "Other income" is only used for the Age Pension estimate and won't be included in the calculation of your monthly retirement income.</Paragraph>
                {_.map(otherIncome, (income: any, i) => (
                    <Fragment key={i}>
                        <Row>
                            <Col md={7} col={12}>
                                <FieldWrapper label="Type">
                                    <Select
                                        value={income.incomeType}
                                        options={_.filter(incomeOptions, options => !options.needsPartner || formValues.hasPartner === options.needsPartner)}
                                        onChange={(e: any) => setFormValues({
                                            ...formValues,
                                            [otherIncomeName]: formValues[otherIncomeName].map((income, index) => {
                                                if (index === i) {
                                                    return {
                                                        ...income,
                                                        incomeType: e.target.value
                                                    }
                                                } else {
                                                    return income;
                                                }
                                            })
                                        })}
                                        data-id={retirementGoalsTestIds.otherIncomeSelect}
                                        id={`other-income-type-${i}`}
                                    />
                                </FieldWrapper>
                                <FieldWrapper
                                    label="Amount"
                                    error="Please enter an amount between $0 and $9,999,999."
                                    isInvalid={errors[otherIncomeName] ? errors[otherIncomeName][i] : false}
                                >
                                    <NumberInput
                                        value={income.incomeAmount.toString()}
                                        prefix="$"
                                        onBlur={(e: any) => setFormValues({
                                            ...formValues,
                                            [otherIncomeName]: formValues[otherIncomeName].map((income, index) => {
                                                if (index === i) {
                                                    return {
                                                        ...income,
                                                        incomeAmount: convertToNumber(e.target.value)
                                                    }
                                                } else {
                                                    return income;
                                                }
                                            })
                                        }
                                        )}
                                        data-id={retirementGoalsTestIds.otherIncomeInput}
                                        allowDecimal={false}
                                        allowLeadingZeroes={true}
                                        integerLimit={9}
                                        className={errors[otherIncomeName] ? (errors[otherIncomeName][i] ? commonClasses.validationError : '') : ''}
                                        aria-describedby={retirementGoalsTestIds.agePensionOtherIncomeHelp}
                                        data-analytics-capture-value
                                    />
                                </FieldWrapper>
                            </Col>
                        </Row>
                    </Fragment>
                ))}
                {
                    otherIncome.length < 3 && (
                        <TextLink
                            el="button"
                            type="button"
                            onClick={() => {
                                otherIncome.push({ incomeType: IncomeOwnerType.Member, incomeAmount: 0 });
                                forceUpdate();
                                setTimeout(() => document.getElementById(`other-income-type-${otherIncome.length - 1}`)?.focus(), 100);
                            }}
                            data-id={retirementGoalsTestIds.addOtherIncome}
                        >
                            <AddOtherIncomeHeading el="span" fontSize="tiny">
                                <AddOtherIncomeSymbol symbol={plus} /> Add other income
                            </AddOtherIncomeHeading>
                        </TextLink>
                    )
                }
            </>
        )
    }

    if (pageNo === 3) {
        content = (
            <>
                <Heading el="span" fontSize="tiny" role="text">
                    Your estimated Age Pension at retirement per month is{' '}
                    {!loading ? <CalculatedAgePension el="span" fontSize="tiny" fontWeight="medium" data-id={retirementGoalsTestIds.estimatedAgePension}>{asCurrency(agePension)}</CalculatedAgePension> : <LoadingIndicator />}
                </Heading>
                <Card marginTop={2}>Changes you make in the calculator may reduce or increase your Age Pension estimate, including voluntary super contributions and changes to your risk profile. Please contact Centrelink on 132 300 to determine your eligibility and exact entitlement. Please contact us if you need help.</Card>
            </>
        )
    }

    return (
        <WiderModal
            isOpen={isOpen}
            align="left"
            title={<>Age Pension estimator<ScreenReaderText>, step {page} of 4</ScreenReaderText></>}
            aria={{ label: `Age pension estimator, step ${page} of 4` }}
            onRequestClose={cancelModal}
            footerButtons={ageModalButtons[pageNo]}
            data-id={retirementGoalsTestIds.agePensionModal}
            shouldCloseOnOverlayClick={false}
        >
            <Grid>
                <Card display="flex" justifyContent="flex-end">
                    <PageIndicator pageType="Step" currentPage={page} length={4} />
                </Card>
                <Row>
                    <Col md={10} col={12} noGutter={true}>
                        {content}
                    </Col>
                </Row>
            </Grid>
        </WiderModal>
    )
}

export default AgePensionModal;