import numeral from 'numeral';
import _ from 'lodash';

export const unicodeMinus = '\u2212';

export const checkNaN = (value: any) => {
    return (
        (typeof value === 'number' && isNaN(value)) || 
        (typeof value === 'string' && value.indexOf('NaN') > -1)
    );
}

const toCurrency: any = (value: number, rounded = false, asDecimal = false, abs = true) => {
    // rounded = true = rounded by numeral lib, rounded = false = remove decimals (no specific rounding up or down)
    const newVal = rounded ? value : parseInt(value.toString());
    const formatted = numeral(abs ? Math.abs(newVal) : newVal)
        .format(asDecimal ? '$0,0.00' : '$0,0')
        .replace('-', unicodeMinus);

    return checkNaN(formatted) ? toCurrency(0, rounded, asDecimal, abs) : formatted;
}

// rounded to 2dp
export const asDecimal: any = (value: number, abs = true) => {
    const formatted = numeral(abs ? Math.abs(value) : value)
        .format('0,0.00')
        .replace('-', unicodeMinus)

    return checkNaN(formatted) ? asDecimal(0, abs) : formatted;
}

// returns positive value without decimals
export const asCurrency = (value: number | null | undefined, defaultValue: string | undefined = undefined) => {
    return _.isNil(value) || (value === 0 && typeof defaultValue === 'string')
        ? typeof defaultValue === 'string'
            ? defaultValue
            : toCurrency(0)
        : toCurrency(value);
};

// returns negative value without decimals (rounded)
export const asDeductible = (value: number | null | undefined, defaultValue: string | undefined = undefined) => {
    return _.isNil(value) || (value === 0 && typeof defaultValue === 'string')
        ? typeof defaultValue === 'string'
            ? defaultValue
            : `${unicodeMinus}${toCurrency(0)}`
        : `${unicodeMinus}${toCurrency(value, true)}`;
}

// rounded to the nearest cent, can pass abs param for absolute value
export const asDecimalCurrency = (value: number | null | undefined, defaultValue: string | undefined = undefined, abs = true) => {
    return _.isNil(value) || (value === 0 && typeof defaultValue === 'string')
        ? typeof defaultValue === 'string'
            ? defaultValue
            : toCurrency(0, true, true, abs)
        : toCurrency(value, true, true, abs);
};

// rounded to the nearest whole number
export const asRoundedCurrency = (value: number | null | undefined, defaultValue: string | undefined = undefined) => {
    return _.isNil(value) || (value === 0 && typeof defaultValue === 'string')
        ? typeof defaultValue === 'string'
            ? defaultValue
            : toCurrency(0)
        : toCurrency(value, true);
};

// round to 1dp and then remove trailing decimal if it's 0
export const asAssetAllocation = (value: number) => {
    const formatted = parseFloat(numeral(value).format('0.0'));

    return checkNaN(formatted) ? '0' : formatted;
}

export const formatCustomerName = (name: string) => {
    // first letter uppercase and the rest lowercase.
    return name != null && name.length > 0 ? name.charAt(0).toUpperCase() + name.slice(1).toLowerCase() : '';
}

export const currencyToInt = (val: string) => {
    return parseInt(val.replace(/[$,]/g, ''));
}