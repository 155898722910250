enum npsTestIds {
    npsModal = 'nps-modal',
    npsRating = 'nps-rating',
    npsFeedback = 'nps-feedback',
    contactType = 'contact-type',
    contactEmail = 'contact-email',
    contactNone = 'contact-none',
    npsEmail = 'nps-email',
    npsFooter = 'nps-footer'
}

export default npsTestIds;